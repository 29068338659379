import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import {
  ROLE_ADMIN,
  ROLE_PROGRAM_M,
  ROLE_PROJECT_M,
  ROLE_FINANCE,
} from "utils/roles";
import { Card, Input, Grid, Button, Avatar } from "@nextui-org/react";
import { FiCamera } from "react-icons/fi";

function CreateUser() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const phoneRegExp = /^(0)8[1-9][0-9]{7,10}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama tidak boleh kosong"),
    email: Yup.string()
      .email("Format email salah")
      .required("Email tidak boleh kosong"),
    phone_number: Yup.string()
      .matches(
        phoneRegExp,
        "Berawalan angka 08, minimal 10 digit dan maksimal 13 digit."
      )
      .required("Nomor telepon tidak boleh kosong"),
    password: Yup.string()
      .concat(
        isCreateMode
          ? Yup.string().required("Password tidak boleh kosong")
          : null
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
        "Wajib huruf besar, kecil, angka dan simbol. Minimal 6 karakter."
      ),
    confirmPassword: Yup.string()
      .concat(
        isCreateMode
          ? Yup.string().required("Konfirmasi password tidak boleh kosong")
          : null
      )
      .oneOf([Yup.ref("password"), null], "Konfirmasi password harus sama"),
    type: Yup.string().required("Role tidak boleh kosong"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      password: "",
      confirmPassword: "",
      type: "",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isCreateMode) {
      UserService.getAdminDetail(id).then(
        (response) => {
          let res = response.data.data;
          setImage(response.data.data.photo);
          let defaults = {
            name: res?.name,
            email: res?.email,
            phone_number: res?.phone_number,
            type: res?.role,
          };
          reset(defaults);
        },
        (error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        }
      );
    }
  }, [id, isCreateMode, reset]);

  const onSubmit = (data) => {
    return isCreateMode ? createUser(data) : editUser(id, data);
  };

  // Handle image preview
  const fileInput = useRef();
  const [image, setImage] = useState("");
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Create user
  const createUser = (data) => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Menambahkan pengguna baru...",
          });
          Alert.showLoading();
          return UserService.createUserAdmin(
            data.name,
            data.email,
            data.password,
            data.phone_number,
            data.type,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Pengguna berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Menambahkan pengguna baru...",
      });
      Alert.showLoading();
      return UserService.createUserAdmin(
        data.name,
        data.email,
        data.password,
        data.phone_number,
        data.type,
        image
      )
        .then(() => {
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Pengguna berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  // Edit user
  const editUser = (id, data) => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          Alert.fire({
            title: "Mengubah informasi pengguna...",
          });
          Alert.showLoading();
          const imageUri = res.data.data.url;
          return UserService.updateUserAdmin(
            id,
            data.name,
            data.phone_number,
            data.type,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit pengguna berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Mengubah informasi pengguna...",
      });
      Alert.showLoading();
      return UserService.updateUserAdmin(
        id,
        data.name,
        data.phone_number,
        data.type,
        image
      )
        .then(() => {
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit pengguna berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body css={{ py: "$12" }}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 pb-4 flex flex-row justify-between">
                  <label htmlFor="photo" className="rounded-full w-28 h-28">
                    {image && <Avatar src={image} css={{ size: "$28" }} />}
                    {!image && (
                      <Avatar
                        css={{ size: "$28" }}
                        icon={<FiCamera size={20} />}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    id="photo"
                    name="photo"
                    className="hidden"
                    accept="image/jpg, image/png"
                    ref={fileInput}
                    onChange={handleImage}
                  />
                  <Button
                    type="submit"
                    size="md"
                    auto
                    css={{ "background-color": "#FF9D25", color: "$black" }}
                  >
                    Submit
                  </Button>
                </div>
                <Grid.Container gap={2} justify="center">
                  <Grid xs={12} md={4}>
                    <Input
                      name="name"
                      {...register("name")}
                      bordered
                      fullWidth
                      label="Nama Lengkap"
                      placeholder="Contoh: Admin Penakita"
                      status={errors.name ? "error" : "default"}
                      helperColor={errors.name ? "error" : "default"}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  {isCreateMode && (
                    <Grid xs={12} md={4}>
                      <Input.Password
                        name="password"
                        {...register("password")}
                        bordered
                        fullWidth
                        label="Password"
                        placeholder="Masukan password"
                        status={errors.password ? "error" : "default"}
                        helperColor={errors.password ? "error" : "default"}
                        helperText={errors.password?.message}
                      />
                    </Grid>
                  )}
                  {isCreateMode && (
                    <Grid xs={12} md={4}>
                      <Input.Password
                        name="confirmPassword"
                        {...register("confirmPassword")}
                        bordered
                        fullWidth
                        label="Konfirmasi Password"
                        placeholder="Masukan konfirmasi password"
                        status={errors.confirmPassword ? "error" : "default"}
                        helperColor={
                          errors.confirmPassword ? "error" : "default"
                        }
                        helperText={errors.confirmPassword?.message}
                      />
                    </Grid>
                  )}
                  <Grid xs={12} md={4}>
                    <Input
                      name="email"
                      {...register("email")}
                      bordered
                      fullWidth
                      label="Email"
                      placeholder="Contoh: admin@penakita.id"
                      status={errors.email ? "error" : "default"}
                      helperColor={errors.email ? "error" : "default"}
                      helperText={errors.email?.message}
                      disabled={!isCreateMode}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Input
                      name="phone_number"
                      {...register("phone_number")}
                      bordered
                      fullWidth
                      label="Nomor Telepon"
                      placeholder="Contoh: 0812XXXXXXXX"
                      status={errors.phone_number ? "error" : "default"}
                      helperColor={errors.phone_number ? "error" : "default"}
                      helperText={errors.phone_number?.message}
                    />
                  </Grid>

                  {isCreateMode && (
                    <Grid xs={12} md={4}>
                      <div className="w-full">
                        <label
                          className={`text-sm pl-1 ${
                            errors.type ? "text-pk-red" : ""
                          }`}
                        >
                          Role
                        </label>
                        <select
                          className="w-full h-11 rounded-xl border-2 border-pk-border py-[6px] px-4 mt-1 text-sm"
                          name="type"
                          {...register("type")}
                        >
                          <option value="">Pilih role pengguna</option>
                          <option value={ROLE_ADMIN}>Admin</option>
                          <option value={ROLE_FINANCE}>Finance</option>
                          <option value={ROLE_PROJECT_M}>
                            Project Manager
                          </option>
                          <option value={ROLE_PROGRAM_M}>
                            Program Manager
                          </option>
                        </select>
                        <span className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.type?.message}
                        </span>
                      </div>
                    </Grid>
                  )}
                </Grid.Container>
              </form>
            </FormProvider>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default CreateUser;
