import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { Currency, ShortDateTimeFormat } from "utils/helper";
import { WithdrawStatusMap } from "constants";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  navigate,
  pathname,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Status = (row) => {
    if (row.withdraw_request !== null) {
      return WithdrawStatusMap[row.withdraw_request.status];
    } else {
      return "-";
    }
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.insentive_id}`)}
    >
      Detail
    </Button>
  );

  const columns = [
    {
      name: "Name",
      selector: (row) =>
        row.blogger ? row.blogger.name : row.media.company_name,
      grow: 2,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) =>
        row.blogger ? row.blogger.email : row.media.company_email,
      grow: 2,
      wrap: true,
    },
    {
      name: "Balance",
      selector: (row) => Currency(row.balance),
      grow: 1.5,
      wrap: true,
      right: true,
    },
    {
      name: "Credit",
      selector: (row) => Currency(row.credit),
      grow: 1.5,
      wrap: true,
      right: true,
    },
    {
      name: "Debit",
      selector: (row) => Currency(row.debit),
      grow: 1.5,
      wrap: true,
      right: true,
    },
    {
      name: "Status",
      selector: (row) => row.withdraw_request.status,
      grow: 1.5,
      wrap: true,
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      width: "150px",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.detail,
      cell: (row) => <Detail {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
