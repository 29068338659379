import { format, parseISO } from "date-fns";
import langId from "date-fns/locale/id";

export const FullDateTimeFormat = (data) => {
  return format(parseISO(data), "d MMMM y HH:mm", { locale: langId });
};

export const ShortDateTimeFormat = (data) => {
  return format(parseISO(data), "d MMM y HH:mm", { locale: langId });
};

export const DateFormat = (data) => {
  return format(parseISO(data), "d MMMM y", { locale: langId });
};

export const ShortDateFormat = (data) => {
  return format(parseISO(data), "d MMM y", { locale: langId });
};

export const Currency = (data) => {
  return data
    ? new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(data)
    : null;
};

export const useRole = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.role;
};

export const useHasPermissions = (permissions, role) => {
  if (permissions[0] === role) return true;
  else if (permissions[1] === role) return true;
  else if (permissions[2] === role) return true;
  else if (permissions[3] === role) return true;
  else if (permissions[4] === role) return true;
  else return false;
};

// untuk restrict component by role
export const Restrict = ({ permissions, children }) => {
  const role = useRole();
  const hasPermission = useHasPermissions(permissions, role);
  return hasPermission ? <>{children}</> : null;
};
