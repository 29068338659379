import { createTheme, styled } from "@nextui-org/react"

export const nextUiTheme = createTheme({
  type: "light",
  theme: {
    fonts: {
      sans: `Mulish, sans-serif;`
    },
    colors: {
      mainColor: "#FF9D25",
    }
    
  }
})

export { styled }
