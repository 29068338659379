import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import { Alert, ButtonAdd, SearchBar } from "components";
import Table from "./components/Table";
import StatusDropdown from "./components/StatusDropdown";
import CategoryDropdown from "./components/CategoryDropdown";

function Project() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const goAdd = () => {
    localStorage.removeItem("projectPrivate");
    navigate("/create-project");
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [status, setStatus] = useState(new Set(["ALL"]));
  const statusValue = useMemo(() => Array.from(status), [status]);
  const [category, setCategory] = useState(new Set(["ALL"]));
  const categoryValue = useMemo(() => Array.from(category), [category]);

  const fetchProject = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getProjectMaster(
        page,
        perPage,
        encodeURIComponent(keyword),
        statusValue,
        categoryValue
      );
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchProject(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getProjectMaster(
        page,
        newPerPage,
        encodeURIComponent(keyword),
        statusValue,
        categoryValue
      );
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchProject(1);
  }, [keyword, refresh, statusValue, categoryValue]);

  // Delete project
  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan project yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteProjectMaster(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Project telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Project List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex flex-col md:flex-row items-center justify-between mb-2 space-y-2 md:space-y-0">
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-2 space-y-2 md:space-y-0">
              <SearchBar setKeyword={setKeyword} placeholder="Cari Project" />
              <StatusDropdown
                statusValue={statusValue}
                status={status}
                setStatus={setStatus}
              />
              <CategoryDropdown
                categoryValue={categoryValue}
                category={category}
                setCategory={setCategory}
              />
            </div>
            <ButtonAdd action={goAdd} text="Add New Project" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            refresh={refresh}
            setRefresh={setRefresh}
            handleDelete={handleDelete}
            navigate={navigate}
            pathname={pathname}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Project;
