import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading, Avatar, Link } from "@nextui-org/react";
import { logo } from "assets";
import { DateFormat } from "utils/helper";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";

function UserKonsultanDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getClientDetail(id);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[484px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[484px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <>
              <Card.Header>
                <Avatar
                  src={data?.profile_picture ? data.profile_picture : logo}
                  css={{ size: "$20", mt: 16, ml: 20 }}
                  color="gradient"
                  bordered
                />
              </Card.Header>
              <Card.Body>
                <div className="lg:px-5 lg:pb-5 flex flex-col lg:flex-row">
                  <ul className="w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Id Konsultan</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.id}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nama</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.company_name}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-semibold">Email</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.company_email}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Telepon</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.company_phone_number
                              ? data?.company_phone_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Email PIC</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.pic_email ? data?.pic_email : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kategori</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">-</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Login terakhir pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.last_login_at
                              ? DateFormat(data?.last_login_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Status Verifikasi</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.is_verified ? (
                              <div className="text-green-500 flex items-center space-x-1">
                                <span>Sudah terverifikasi</span>
                                <HiCheckCircle size={20} />
                              </div>
                            ) : (
                              <div className="text-red-500 flex items-center space-x-1">
                                <span>Belum terverifikasi</span>
                                <HiXCircle size={20} />
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Alamat</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.address ? data?.address : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-semibold">Kota</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.city?.name ? data?.city?.name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Provinsi</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.province?.name ? data?.province?.name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Dibuat pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.created_at
                              ? DateFormat(data?.created_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Diperbarui pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_at
                              ? DateFormat(data?.updated_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Diperbarui oleh</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_by?.name
                              ? data?.updated_by?.name
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export default UserKonsultanDetail;
