import DataTable from "react-data-table-component";
import { Link } from "@nextui-org/react";
import { ShortDateTimeFormat } from "utils/helper";

function Table({ data, loading }) {
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Interest = (row) => (
    <div className="flex flex-col">
      {row.interest.map((data, id) => (
        <span key={id}>{data}</span>
      ))}
    </div>
  );

  const WebLink = (row) => (
    <Link href={row.link} isExternal target="_blank" rel="noopener noreferrer">
      {row.link}
    </Link>
  );

  const columns = [
    {
      name: "Tanggal",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      wrap: true,
      grow: 0.7,
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Link",
      selector: (row) => row.link,
      wrap: true,
      grow: 1.3,
      cell: (row) => <WebLink {...row} />,
    },
    {
      name: "Tipe",
      selector: (row) => row.type,
      wrap: true,
      grow: 0.8,
    },
    {
      name: "Interest",
      selector: (row) => row.interest,
      wrap: true,
      grow: 0.5,
      cell: (row) => <Interest {...row} />,
    },
    {
      name: "Followers",
      selector: (row) => (row.follower ? row.follower : "-"),
      wrap: true,
      grow: 0.5,
    },
    {
      name: "Viewer",
      selector: (row) => (row.viewer ? row.viewer : "-"),
      wrap: true,
      grow: 0.5,
    },
    {
      name: "Visitor",
      selector: (row) => (row.visitor ? row.visitor : "-"),
      wrap: true,
      grow: 0.5,
    },
    {
      name: "Konten",
      selector: (row) => (row.total_content ? row.total_content : "-"),
      wrap: true,
      grow: 0.5,
    },
  ];

  return (
    <div className="rounded-xl px-2 lg:px-8 pb-8">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
