import {
  HiUser,
  HiPencilAlt,
  HiSwitchHorizontal,
  HiCash,
  HiCurrencyDollar,
  HiAdjustments,
  HiSpeakerphone,
  HiCalendar,
  HiAcademicCap,
  HiSearchCircle,
  HiStar,
  HiChatAlt2,
  HiChartBar,
} from "react-icons/hi";

export const menus = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <HiChartBar />,
    permissions: ["SUPERADMIN", "ADMIN", "FINANCE", "PROGRAM_MANAGER"],
  },
  {
    title: "User",
    path: "",
    icon: <HiUser />,
    permissions: ["SUPERADMIN", "ADMIN", "PROJECT_MANAGER"],
    children: [
      {
        title: "User Internal",
        path: "/internal",
        permissions: ["SUPERADMIN"],
      },
      {
        title: "User Blogger",
        path: "/blogger",
        permissions: ["SUPERADMIN", "ADMIN", "PROJECT_MANAGER"],
      },
      {
        title: "User Media",
        path: "/media",
        permissions: ["SUPERADMIN", "ADMIN", "PROJECT_MANAGER"],
      },
      {
        title: "User Client",
        subTitle: "Mitme",
        path: "/client",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
      {
        title: "User Konsultan",
        subTitle: "Mitme",
        path: "/konsultan",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
    ],
  },
  {
    title: "Project",
    path: "/project",
    icon: <HiPencilAlt />,
    permissions: ["SUPERADMIN", "ADMIN", "PROJECT_MANAGER"],
  },
  {
    title: "Activity",
    path: "/activity",
    icon: <HiSwitchHorizontal />,
    permissions: ["SUPERADMIN", "PROJECT_MANAGER"],
  },
  {
    title: "Transaction",
    path: "/client-transaction",
    icon: <HiCash />,
    permissions: ["SUPERADMIN", "FINANCE"],
  },
  {
    title: "Finance",
    path: "",
    icon: <HiCurrencyDollar />,
    permissions: ["SUPERADMIN", "FINANCE"],
    children: [
      {
        title: "Withdraw Request",
        path: "/withdraw-request",
        permissions: ["SUPERADMIN", "FINANCE"],
      },
      {
        title: "Balance History",
        path: "/balance-history",
        permissions: ["SUPERADMIN", "FINANCE"],
      },
    ],
  },
  {
    title: "CMS Settings",
    path: "",
    icon: <HiAdjustments />,
    permissions: ["SUPERADMIN", "ADMIN"],
    children: [
      {
        title: "Tier List",
        path: "/tier",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
      {
        title: "Articles List",
        path: "/articles",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
      {
        title: "FAQ List",
        path: "/faq",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
      {
        title: "Voucher",
        subTitle: "Mitme",
        path: "/voucher",
        permissions: ["SUPERADMIN", "ADMIN"],
      },
    ],
  },
  {
    title: "Announcement",
    path: "/announcement",
    icon: <HiSpeakerphone />,
    permissions: ["SUPERADMIN", "ADMIN"],
  },
  {
    title: "Schedule",
    path: "/pdp/schedule",
    icon: <HiCalendar />,
    permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
  },
  {
    title: "Course",
    path: "",
    icon: <HiAcademicCap />,
    permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
    children: [
      {
        title: "Course List",
        path: "/pdp/course",
        permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
      },
      {
        title: "Course Category",
        path: "/pdp/category",
        permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
      },
    ],
  },
  {
    title: "Survey",
    path: "",
    icon: <HiSearchCircle />,
    permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
    children: [
      {
        title: "Survey Goals",
        path: "/pdp/survey/goal",
        permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
      },
      {
        title: "Survey Question",
        path: "/pdp/survey/question",
        permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
      },
    ],
  },
  {
    title: "Launchpad",
    path: "/pdp/launchpad",
    icon: <HiStar />,
    permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
  },
  {
    title: "Forum",
    path: "/pdp/forum",
    icon: <HiChatAlt2 />,
    permissions: ["SUPERADMIN", "PROGRAM_MANAGER"],
  },
];
