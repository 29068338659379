import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import {
  Card,
  Input,
  Spacer,
  Button,
  Textarea,
  Container,
  Row,
  Col,
  Image,
} from "@nextui-org/react";
import { NumericFormat } from "react-number-format";
import { HiXCircle } from "react-icons/hi";
import { imageicon } from "assets";

function CreateVoucher() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();
  const [isCashback, setIsCashback] = useState("");

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Judul tidak boleh kosong"),
    quota: Yup.number()
      .required("Kuota tidak boleh kosong")
      .positive("Format kuota salah")
      .integer("Format kuota salah")
      .typeError("Kuota tidak boleh kosong"),
    discount_percentage: Yup.number()
      .required("Persentase promo tidak boleh kosong")
      .positive("Format persentase promo salah")
      .integer("Format persentase promo salah")
      .typeError("Persentase promo tidak boleh kosong"),
    is_cashback: Yup.string().required("Wajib pilih salah satu"),
    max_discount_amount: Yup.string().when("is_cashback", {
      is: "0",
      then: Yup.string().required("Jumlah maksimal diskon tidak boleh kosong"),
    }),
    max_cashback_amount: Yup.string().when("is_cashback", {
      is: "1",
      then: Yup.string().required(
        "Jumlah maksimal cashback tidak boleh kosong"
      ),
    }),
    start_period: Yup.string().required("Periode mulai tidak boleh kosong"),
    end_period: Yup.string().required("Periode berakhir tidak boleh kosong"),
    description: Yup.string().required("Deskripsi tidak boleh kosong"),
    type: Yup.string().required("Tipe voucher tidak boleh kosong"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      quota: null,
      discount_percentage: null,
      max_discount_amount: null,
      max_cashback_amount: null,
      is_cashback: "",
      start_period: "",
      end_period: "",
      description: "",
      type: "",
    },
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isCreateMode) {
      UserService.getVoucherDetail(id).then(
        (response) => {
          let res = response.data.data;
          setImagePreview(res?.image);
          let defaults = {
            title: res?.title,
            quota: res?.quota,
            discount_percentage: res?.discount_percentage,
            max_discount_amount: res?.max_discount_amount,
            is_cashback: res?.is_cashback,
            max_cashback_amount: res?.max_cashback_amount,
            start_period: res?.start_period.substring(0, 10),
            end_period: res?.end_period.substring(0, 10),
            description: res?.description,
          };
          reset(defaults);
        },
        (error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        }
      );
    }
  }, [id, isCreateMode, reset]);

  const onPromoType = (promoType) => {
    setIsCashback(promoType);
    if (promoType === "0") {
      setValue("max_cashback_amount", "");
    } else if (promoType === "1") {
      setValue("max_discount_amount", "");
    }
  };

  const onSubmit = (data) => {
    return isCreateMode ? createVoucher(data) : editVoucher(id, data);
  };

  // Handle image preview
  const fileInput = useRef();
  const [imagePreview, setImagePreview] = useState("");

  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImagePreview(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  const handleRemove = () => {
    setImagePreview("");
    fileInput.current.value = "";
  };

  const createVoucher = ({
    title,
    quota,
    discount_percentage,
    is_cashback,
    max_discount_amount,
    max_cashback_amount,
    start_period,
    end_period,
    description,
    type,
    image,
  }) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);
      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Membuat voucher...",
          });
          Alert.showLoading();
          return UserService.createVoucher(
            title,
            quota,
            discount_percentage,
            parseInt(is_cashback),
            max_discount_amount === "" ? null : max_discount_amount,
            max_cashback_amount === "" ? null : max_cashback_amount,
            start_period,
            end_period,
            description,
            type,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Voucher berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.createVoucher(
        title,
        quota,
        discount_percentage,
        parseInt(is_cashback),
        max_discount_amount === "" ? null : max_discount_amount,
        max_cashback_amount === "" ? null : max_cashback_amount,
        start_period,
        end_period,
        description,
        type,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Voucher berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  // Edit voucher
  const editVoucher = (id, data) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();

          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Mengubah voucher...",
          });
          Alert.showLoading();
          return UserService.updateVoucher(
            id,
            data.title,
            data.quota,
            data.discount_percentage,
            data.is_cashback,
            data.max_discount_amount,
            data.max_cashback_amount,
            data.start_period,
            data.end_period,
            data.description,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit voucher berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.updateVoucher(
        id,
        data.title,
        data.quota,
        data.discount_percentage,
        data.is_cashback,
        data.max_discount_amount,
        data.max_cashback_amount,
        data.start_period,
        data.end_period,
        data.description,
        data.image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit voucher berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body css={{ p: "$10" }}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Container xs>
                  <Row justify="center">
                    <Col>
                      <div className="flex flex-col">
                        <p className="mb-2 text-sm">Voucher Banner</p>
                        <div className="relative">
                          <label htmlFor="image">
                            {imagePreview ? (
                              <Image
                                src={imagePreview}
                                alt="Default Image"
                                objectFit="cover"
                                autoResize
                                height={176}
                                css={{ borderRadius: "$md" }}
                              />
                            ) : (
                              <div className="flex flex-col items-center justify-center h-44 rounded-xl border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                                <img src={imageicon} alt="icon" />
                              </div>
                            )}
                          </label>

                          {imagePreview ? (
                            <div className="absolute -right-2 -top-2 z-[999999]">
                              <button
                                onClick={handleRemove}
                                className="bg-white border-none text-red-500 cursor-pointer rounded-full"
                              >
                                <HiXCircle size={24} />
                              </button>
                            </div>
                          ) : null}
                          <input
                            type="file"
                            id="image"
                            name="image"
                            className="hidden"
                            accept=".jpg, .jpeg, .png"
                            ref={fileInput}
                            onChange={handleImage}
                          />
                        </div>
                      </div>

                      <Spacer y={1} />

                      <Input
                        name="title"
                        type="text"
                        {...register("title")}
                        bordered
                        fullWidth
                        label={
                          <p>
                            Judul <span className="text-red-600">*</span>
                          </p>
                        }
                        placeholder="Contoh: Promo Lebaran"
                        status={errors.title ? "error" : "default"}
                        helperColor={errors.title ? "error" : "default"}
                        helperText={errors.title?.message}
                      />

                      <Spacer y={1.5} />

                      <div className={errors.type ? "" : "mb-6"}>
                        <p
                          className={`ml-1 text-sm ${
                            errors.type ? "text-pk-red" : ""
                          }`}
                        >
                          Tipe Voucher <span className="text-red-600">*</span>
                        </p>
                        <select
                          className="w-full h-11 rounded-xl border-2 border-pk-border py-[6px] px-2 mt-1 text-sm"
                          name="type"
                          {...register("type")}
                        >
                          <option disabled value="">
                            Silahkan pilih
                          </option>
                          <option value="PUBLIC">Terbuka (PUBLIC)</option>
                          <option value="PRIVATE">Tertutup (PRIVATE)</option>
                        </select>
                        <span className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.type?.message}
                        </span>
                      </div>

                      <Input
                        name="quota"
                        type="number"
                        min={1}
                        {...register("quota")}
                        bordered
                        fullWidth
                        label={
                          <p>
                            Kuota <span className="text-red-600">*</span>
                          </p>
                        }
                        placeholder="Contoh: 10"
                        status={errors.quota ? "error" : "default"}
                        helperColor={errors.quota ? "error" : "default"}
                        helperText={errors.quota?.message}
                      />

                      <Spacer y={1.5} />

                      <div
                        className={errors.discount_percentage ? "mb-3" : "mb-6"}
                      >
                        <p
                          className={`mb-1 ml-1 text-sm ${
                            errors.discount_percentage ? "text-pk-red" : ""
                          }`}
                        >
                          Persentase Promosi{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Controller
                          name="discount_percentage"
                          control={control}
                          defaultValue={null}
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <>
                              <NumericFormat
                                placeholder="Contoh: 50%"
                                className={`form-control-db py-5 text-sm rounded-xl h-10 border-2 border-zinc-300 ${
                                  errors.discount_percentage ? "is-invalid" : ""
                                }`}
                                allowNegative={false}
                                onValueChange={(values) =>
                                  onChange(values.floatValue)
                                }
                                isAllowed={(values) => {
                                  const { value } = values;
                                  return value <= 100;
                                }}
                                suffix="%"
                                name={name}
                                value={value}
                                onBlur={onBlur}
                              />
                            </>
                          )}
                        />
                        <div className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.discount_percentage
                            ? "Nominal tidak sesuai"
                            : ""}
                        </div>
                      </div>

                      <div className={errors.is_cashback ? "mb-3" : "mb-6"}>
                        <p
                          className={`ml-1 text-sm ${
                            errors.is_cashback ? "text-pk-red" : ""
                          }`}
                        >
                          Tipe Promosi <span className="text-red-600">*</span>
                        </p>
                        <select
                          className="w-full h-11 rounded-xl border-2 border-pk-border py-[6px] px-2 mt-1 text-sm"
                          name="is_cashback"
                          {...register("is_cashback")}
                          onChange={(e) => onPromoType(e.target.value)}
                        >
                          <option value="">Silahkan pilih</option>
                          <option value="0" data-value="0">
                            Diskon (Potongan Harga)
                          </option>
                          <option value="1" data-value="1">
                            Cashback
                          </option>
                        </select>
                        <span className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.is_cashback?.message}
                        </span>
                      </div>

                      <div
                        className={errors.max_discount_amount ? "mb-3" : "mb-6"}
                      >
                        <p
                          className={`mb-1 ml-1 text-sm ${
                            errors.max_discount_amount ? "text-pk-red" : ""
                          }`}
                        >
                          Jumlah Maksimal Diskon{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Controller
                          name="max_discount_amount"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              placeholder="Contoh: Rp 25.000"
                              className={`form-control-db text-sm rounded-xl h-10 border-2 border-zinc-300 ${
                                errors.max_discount_amount ? "is-invalid" : ""
                              }`}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              allowNegative={false}
                              onValueChange={(values) =>
                                onChange(values.floatValue)
                              }
                              prefix="Rp "
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              disabled={isCashback === "1"}
                            />
                          )}
                        />
                        <div className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.max_discount_amount?.message}
                        </div>
                      </div>

                      <div
                        className={errors.max_cashback_amount ? "mb-3" : "mb-6"}
                      >
                        <p
                          className={`mb-1 ml-1 text-sm ${
                            errors.max_cashback_amount ? "text-pk-red" : ""
                          }`}
                        >
                          Jumlah Maksimal Cashback{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Controller
                          name="max_cashback_amount"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              placeholder="Contoh: Rp 25.000"
                              className={`form-control-db text-sm rounded-xl h-10 border-2 border-zinc-300 ${
                                errors.max_cashback_amount ? "is-invalid" : ""
                              }`}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              allowNegative={false}
                              onValueChange={(values) =>
                                onChange(values.floatValue)
                              }
                              prefix="Rp "
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              disabled={isCashback === "0"}
                            />
                          )}
                        />
                        <div className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                          {errors.max_cashback_amount?.message}
                        </div>
                      </div>

                      <Input
                        name="start_period"
                        type="date"
                        min={new Date().toISOString().substring(0, 10)}
                        {...register("start_period")}
                        bordered
                        fullWidth
                        label={
                          <p>
                            Periode Mulai{" "}
                            <span className="text-red-600">*</span>
                          </p>
                        }
                        status={errors.start_period ? "error" : "default"}
                        helperColor={errors.start_period ? "error" : "default"}
                        helperText={errors.start_period?.message}
                      />

                      <Spacer y={1.5} />

                      <Input
                        name="end_period"
                        type="date"
                        min={new Date().toISOString().substring(0, 10)}
                        {...register("end_period")}
                        bordered
                        fullWidth
                        label={
                          <p>
                            Periode Berakhir{" "}
                            <span className="text-red-600">*</span>
                          </p>
                        }
                        status={errors.end_period ? "error" : "default"}
                        helperColor={errors.end_period ? "error" : "default"}
                        helperText={errors.end_period?.message}
                      />

                      <Spacer y={1.5} />

                      <Textarea
                        name="description"
                        type="text"
                        {...register("description")}
                        bordered
                        fullWidth
                        label={
                          <p>
                            Deskripsi <span className="text-red-600">*</span>
                          </p>
                        }
                        placeholder="Contoh: Ini adalah promo lebaran segera lakukan transaksi pada aplikasi mitme."
                        status={errors.description ? "error" : "default"}
                        helperColor={errors.description ? "error" : "default"}
                        helperText={errors.description?.message}
                      />

                      <Spacer y={1.5} />

                      <Button
                        type="submit"
                        size="md"
                        auto
                        css={{
                          "background-color": "#FF9D25",
                          color: "$black",
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </form>
            </FormProvider>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default CreateVoucher;
