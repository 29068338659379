import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  handleEdit,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Action = (row) => (
    <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
      <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
        Edit
      </Button>
      <Button
        size="xs"
        color="error"
        auto
        onClick={() => handleDelete(row.scheduleId)}
      >
        Delete
      </Button>
    </div>
  );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.scheduleId,
      wrap: true,
      width: "70px",
    },
    {
      name: "Judul",
      selector: (row) => row.title,
      wrap: true,
      grow: 4.5,
    },
    {
      name: "Tgl Berakhir",
      selector: (row) => row.dueDate,
      wrap: true,
      center: true,
      width: "120px",
    },
    {
      name: "Lokasi",
      selector: (row) => row.location,
      wrap: true,
      grow: 2,
    },
    {
      name: "Waktu",
      selector: (row) => row.time,
      wrap: true,
      center: true,
      width: "80px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
