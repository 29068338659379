import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, ButtonBack, SearchBar } from "components";
import { imageicon } from "assets";
import { Link } from "@nextui-org/react";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";

function InviteBlogger() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/project");
  };
  let dataSubmit = JSON.parse(localStorage.getItem("projectPrivate"));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const perPage = 500;
  const [mediaType, setMediaType] = useState("");
  const [keyword, setKeyword] = useState("");

  function media() {
    switch (dataSubmit.media_type) {
      case "BLOGGER":
        return setMediaType("blog");
      case "INSTAGRAM":
        return setMediaType("instagram");
      case "TWITTER":
        return setMediaType("twitter");
      default:
        return null;
    }
  }

  const fetchUsers = async (page, mediaType) => {
    try {
      setLoading(true);
      const response = await UserService.getUserInvitation(
        page,
        perPage,
        mediaType,
        keyword
      );
      setData(response.data.data.asset);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data);
    }
  };

  useEffect(() => {
    media();
    mediaType !== "" && fetchUsers(1, mediaType);
  }, [mediaType, keyword]);

  // Select row table
  const mediaList = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(
      state.selectedRows.map((row) => ({
        user_id: row?.blogger?.blogger_id,
        asset_id: row?.asset_id,
      }))
    );
  }, []);

  const [image, setImage] = useState("");
  const fileInput = useRef();
  const imageUrl = "";

  // Handle image preview
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Handle submit
  const onSubmit = () => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Membuat project...",
          });
          Alert.showLoading();
          return UserService.createProjectMaster(
            dataSubmit.name,
            dataSubmit.price_per_task,
            dataSubmit.type,
            dataSubmit.category,
            dataSubmit.sub_type,
            dataSubmit.media_type,
            dataSubmit.classification,
            dataSubmit.start_period,
            dataSubmit.end_period,
            dataSubmit.status,
            dataSubmit.industry,
            dataSubmit.province_id,
            dataSubmit.city_id,
            dataSubmit.quota,
            dataSubmit.description,
            dataSubmit.file_brief,
            imageUri,
            selectedRows,
            mediaList
          ).then(() => {
            localStorage.removeItem("projectPrivate");
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Project Blogger berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                goBack();
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Membuat project...",
      });
      Alert.showLoading();
      return UserService.createProjectMaster(
        dataSubmit.name,
        dataSubmit.price_per_task,
        dataSubmit.type,
        dataSubmit.category,
        dataSubmit.sub_type,
        dataSubmit.media_type,
        dataSubmit.classification,
        dataSubmit.start_period,
        dataSubmit.end_period,
        dataSubmit.status,
        dataSubmit.industry,
        dataSubmit.province_id,
        dataSubmit.city_id,
        dataSubmit.quota,
        dataSubmit.description,
        dataSubmit.file_brief,
        imageUrl,
        selectedRows,
        mediaList
      )
        .then(() => {
          localStorage.removeItem("projectPrivate");
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Project berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              goBack();
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Hyperlink = (row) => (
    <Link
      isExternal
      color="primary"
      href={row.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {row.link.length > 25 ? row.link.slice(0, 25) + "..." : row.link}
    </Link>
  );

  const Interest = (row) => {
    if (Array.isArray(row.interest)) {
      return (
        <div className="flex flex-col">
          {row.interest.map((data, id) => (
            <span key={id}>{data}</span>
          ))}
        </div>
      );
    } else {
      return <div className="flex flex-col"></div>;
    }
  };

  const columns = [
    {
      name: "Nama",
      selector: (row) => row.blogger.name,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.blogger.email,
      wrap: true,
    },
    {
      name: "Asset",
      selector: (row) => row.link,
      wrap: true,
      cell: (row) => <Hyperlink {...row} />,
    },
    {
      name: "Interest",
      selector: (row) => row.interest,
      wrap: true,
      cell: (row) => <Interest {...row} />,
    },
    {
      name: "Skor Akun",
      selector: (row) => row.score,
    },
  ];

  function MyComponent() {
    return (
      <>
        <div className="mt-8" />
        <SearchBar
          setKeyword={setKeyword}
          placeholder="Cari Nama Blogger"
        />
        <div className="mt-4" />
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          progressPending={loading}
          customStyles={customStyles}
        />
      </>
    );
  }

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-8">
        <div className="pt-6 mb-2 flex flex-row justify-between">
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">Project Image</p>
            <label htmlFor="image">
              {image && (
                <img
                  src={image}
                  alt="preview"
                  className="h-64 max-w-lg rounded-lg object-scale-down"
                />
              )}
              {!image && (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept="image/jpg, image/png"
              ref={fileInput}
              onChange={handleImage}
            />
          </div>
          {selectedRows.length === dataSubmit?.quota &&
          selectedRows.length > 0 ? (
            <Button text="Submit" onClick={onSubmit} />
          ) : null}
        </div>

        {MyComponent()}
      </div>
    </>
  );
}

export default InviteBlogger;
