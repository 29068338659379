import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, ButtonBack } from "components";
import { imageicon } from "assets";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Currency } from "utils/helper";

function InviteBlogger() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/project");
  };
  let dataSubmit = JSON.parse(localStorage.getItem("projectPrivate"));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async (page, perPage) => {
    try {
      setLoading(true);
      const response = await UserService.getUserMedia(page, perPage);
      setData(response.data.data.medias);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.medias);
    }
  };

  useEffect(() => {
    fetchUsers(1, 500);
  }, []);

  // Select row table
  const bloggerList = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(
      state.selectedRows.map((row) => ({
        user_id: row?.id,
      }))
    );
  }, []);

  const [image, setImage] = useState("");
  const fileInput = useRef();
  const imageUrl = "";

  // Handle image preview
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Handle submit
  const onSubmit = () => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Membuat project...",
          });
          Alert.showLoading();
          return UserService.createProjectMaster(
            dataSubmit.name,
            dataSubmit.price_per_task,
            dataSubmit.type,
            dataSubmit.category,
            dataSubmit.sub_type,
            dataSubmit.media_type,
            dataSubmit.classification,
            dataSubmit.start_period,
            dataSubmit.end_period,
            dataSubmit.status,
            dataSubmit.industry,
            dataSubmit.province_id,
            dataSubmit.city_id,
            dataSubmit.quota,
            dataSubmit.description,
            dataSubmit.file_brief,
            imageUri,
            bloggerList,
            selectedRows
          ).then(() => {
            localStorage.removeItem("projectPrivate");
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Project Media berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                goBack();
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Membuat project...",
      });
      Alert.showLoading();
      return UserService.createProjectMaster(
        dataSubmit.name,
        dataSubmit.price_per_task,
        dataSubmit.type,
        dataSubmit.category,
        dataSubmit.sub_type,
        dataSubmit.media_type,
        dataSubmit.classification,
        dataSubmit.start_period,
        dataSubmit.end_period,
        dataSubmit.status,
        dataSubmit.industry,
        dataSubmit.province_id,
        dataSubmit.city_id,
        dataSubmit.quota,
        dataSubmit.description,
        dataSubmit.file_brief,
        imageUrl,
        bloggerList,
        selectedRows
      )
        .then(() => {
          localStorage.removeItem("projectPrivate");
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Project Media berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              goBack();
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  const columns = [
    {
      name: "Nama Media",
      selector: (row) => row.company_name,
      wrap: true,
    },
    {
      name: "Email Perushaan",
      selector: (row) => row.company_email,
      wrap: true,
    },
    {
      name: "Email PIC",
      selector: (row) => row.pic_email,
      wrap: true,
    },
    {
      name: "Rate Media",
      selector: (row) => Currency(row.rate_publisher_brand_content),
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  function MyComponent() {
    return (
      <DataTable
        title="User Media List"
        columns={columns}
        data={data}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        progressPending={loading}
        customStyles={customStyles}
      />
    );
  }

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-8">
        <div className="pt-6 mb-2 flex flex-row justify-between">
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">Project Image</p>
            <label htmlFor="image">
              {image && (
                <img
                  src={image}
                  alt="preview"
                  className="h-64 max-w-lg rounded-lg object-scale-down"
                />
              )}
              {!image && (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept="image/jpg, image/png"
              ref={fileInput}
              onChange={handleImage}
            />
          </div>
          {selectedRows.length > 0 && (
            <Button text="Submit" onClick={onSubmit} />
          )}
        </div>

        {MyComponent()}
      </div>
    </>
  );
}

export default InviteBlogger;
