import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bglogin, passwordicon, hidepwicon, showpwicon } from "assets";
import { Alert } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthService from "services/auth.service";

function Login() {
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (e) => {
    AuthService.reset(token, e.password, e.password_confirmation).then(
      (res) => {
        if (res.data.code === 200) {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Kata sandi berhasil diubah.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        }
      },
      (error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      }
    );
  };

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      {/* Left side */}
      <div className="relative bg-gradient-to-bl from-login-from via-login-via to-login-to hidden lg:block w-full h-screen">
        <img
          src={bglogin}
          alt="background"
          className="absolute left-0 bottom-0"
        />
        <div className="absolute mx-auto top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2 text-white">
          <p className="text-4xl font-bold">Penakita Backoffice</p>
          <p className="text-lg">Platform Profesional Untuk Penulis</p>
        </div>
      </div>
      {/* Right side */}
      <div className="flex items-center justify-center w-full md:w-2/3 h-screen md:mx-auto bg-white">
        <div className="w-80 h-100 px-2">
          <h1 className="mt-12 text-xl md:text-3xl text-login-black font-bold">
            Reset Password
          </h1>
          <p className="mt-1 text-login-gray-dark">
            Anda akan mengganti kata sandi lama dengan kata sandi baru.
          </p>

          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group relative">
              <input
                name="password"
                type={isRevealPassword ? "text" : "password"}
                {...register("password")}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="Password"
              />
              <img
                src={passwordicon}
                alt="password"
                className="absolute flex inset-y-0 items-center left-0 p-4"
              />
              <img
                className="absolute flex inset-y-0 items-center justify-center pr-6 py-4 right-0"
                alt="show hide password"
                title={isRevealPassword ? "Hide password" : "Show password"}
                src={isRevealPassword ? hidepwicon : showpwicon}
                onClick={() => setIsRevealPassword((prevState) => !prevState)}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            <div className="form-group relative">
              <input
                name="password_confirmation"
                type={isRevealPassword ? "text" : "password"}
                {...register("password_confirmation")}
                className={`form-control ${
                  errors.password_confirmation ? "is-invalid" : ""
                }`}
                placeholder="Confirm Password"
              />
              <img
                src={passwordicon}
                alt="password"
                className="absolute flex inset-y-0 items-center left-0 py-4 px-4"
              />
              <img
                className="absolute flex inset-y-0 items-center justify-center pr-6 py-4 right-0"
                alt="show hide password"
                title={isRevealPassword ? "Hide password" : "Show password"}
                src={isRevealPassword ? hidepwicon : showpwicon}
                onClick={() => setIsRevealPassword((prevState) => !prevState)}
              />
              <div className="invalid-feedback">
                {errors.password_confirmation?.message}
              </div>
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="w-full h-14 bg-pk-primary hover:shadow-lg text-white text-sm rounded-full p-3 mt-8 ease-in-out duration-200 focus:outline-none"
            >
              Submit
            </button>
          </form>

          {/* Forgot link */}
          <p className="mt-8 text-center">
            <a
              href="/forgot-password"
              className="text-black text-sm underline hover:text-pk-default"
            >
              Forgot Password
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Login;
