import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { imageicon } from "assets";
import Creatable from "react-select/creatable";
import Select from "react-select";

function CreateCourse() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Judul tidak boleh kosong"),
    description: Yup.string().required("Deskripsi tidak boleh kosong"),
    price: Yup.string().required("Jumlah redeem points tidak boleh kosong"),
    rewardPoints: Yup.string().required(
      "Jumlah reward points tidak boleh kosong"
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      description: "",
      price: "",
      rewardPoints: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editCourse"));
      setImage(data?.image);
      setCategoryId(data?.categoryId);
      let defaults = {
        title: data?.title,
        description: data?.description,
        price: data?.price,
        rewardPoints: data?.rewardPoints,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  // Handle Videos
  const [disabled, setDisabled] = useState(true);
  const [tagInputValue, setTagInputValue] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [valueList, setValueList] = useState([]);

  const handleChange = (field, value) => {
    switch (field) {
      case "tags":
        setTagValue(value);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (e) => {
    if (!tagInputValue) return;
    if (tagValue.length < 10) {
      switch (e.key) {
        case "Enter":
        case "Tab":
          setTagValue([...tagValue, createOption(tagInputValue)]);
          setTagInputValue("");
          e.preventDefault();
          break;
        default:
          break;
      }
    }
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleInputChange = (value) => {
    setTagInputValue(value);
  };

  const fetchTags = async () => {
    try {
      if (tagValue.length > 0) {
        setValueList(tagValue.map((tag) => tag.value));
      }
    } catch (err) {
      setValueList([]);
    }
  };

  const fileInput = useRef();
  const [image, setImage] = useState("");
  const fetchError = async () => {
    try {
      if (tagValue.length > 0 && image !== "") {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } catch (err) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    fetchTags();
    fetchError();
  }, [tagValue, image]);

  // Get category list
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    UserService.getCourseCategory().then(
      (response) => {
        setCategoryList(response.data.data.categories);
      },
      (error) => {
        setCategoryList(error.data);
      }
    );
  }, []);

  // select dropdown category
  let categoryDropdown;
  if (categoryList.length > 0) {
    categoryDropdown = categoryList?.map((data) => {
      return { value: data.categoryId, label: data.category };
    });
  }

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  // handle change category
  const changeCategory = (e) => {
    setCategoryId(e.value);
    setCategoryName(e.label);
  };

  // Handle image preview
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      setImage(URL.createObjectURL(fileInput.current.files[0]));
    }
  };

  const onSubmit = (e) => {
    return isCreateMode ? createCourse(e) : editCourse(id, e);
  };

  // Create course
  const createCourse = (e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("flag", "project");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data;
          return UserService.createCourse(
            e.title,
            e.description,
            e.price,
            e.rewardPoints,
            categoryId,
            valueList,
            imageUri
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Course berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Gambar tidak boleh kosong",
      });
    }
  };

  const editCourse = (id, e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("flag", "project");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data;
          return UserService.updateCourse(
            id,
            e.title,
            e.description,
            e.price,
            e.rewardPoints,
            categoryId,
            valueList,
            imageUri
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit course berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("editCourse");
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.updateCourse(
        id,
        e.title,
        e.description,
        e.price,
        e.rewardPoints,
        categoryId,
        valueList,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit course berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("editCourse");
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className={`${
                disabled
                  ? "bg-pk-gray-light cursor-not-allowed"
                  : "bg-pk-primary"
              } h-10 px-5 py-2  text-black rounded-lg duration-200 ease-in-out hover:shadow-lg`}
              disabled={disabled}
            >
              Submit
            </button>
          </div>
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">Image</p>
            <label
              htmlFor="image"
              className={`${
                image ? "h-32 max-w-xs md:h-64 md:max-w-lg" : "w-60 h-32"
              }`}
            >
              {image ? (
                <img
                  src={image}
                  alt="preview"
                  className="h-32 max-w-xs md:h-64 md:max-w-lg rounded-lg object-scale-down"
                />
              ) : (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept=".jpg, .jpeg, .png"
              ref={fileInput}
              onChange={handleImage}
            />
            {image.length === 0 && (
              <div className="invalid-feedback">Gambar tidak boleh kosong</div>
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11">
            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Judul Course <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Ketik judul.."
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Kategori <span className="text-red-600">*</span>
              </p>
              {categoryName ? (
                <Select
                  defaultInputValue={categoryName}
                  defaultValue={categoryId}
                  onChange={changeCategory}
                  options={categoryDropdown}
                  isSearchable={true}
                  placeholder="Pilih kategori"
                  noOptionsMessage={() => "Tidak ada"}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: "#8f959a",
                      marginTop: "0.25rem",
                      paddingTop: "0.3125rem",
                      paddingBottom: "0.3125rem",
                      paddingLeft: "0.5rem",
                      borderRadius: "0.25rem",
                    }),
                  }}
                />
              ) : (
                <Select
                  onChange={changeCategory}
                  options={categoryDropdown}
                  isSearchable={true}
                  placeholder="Pilih kategori"
                  noOptionsMessage={() => "Tidak ada"}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: "#8f959a",
                      marginTop: "0.25rem",
                      paddingTop: "0.3125rem",
                      paddingBottom: "0.3125rem",
                      paddingLeft: "0.5rem",
                      borderRadius: "0.25rem",
                    }),
                  }}
                />
              )}
              <div className="invalid-feedback">
                {categoryName?.length > 0
                  ? null
                  : "Kategori tidak boleh kosong"}
              </div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Redeem Points <span className="text-red-600">*</span>
              </p>
              <input
                name="price"
                type="text"
                min={0}
                {...register("price")}
                className={`form-control-db ${
                  errors.price ? "is-invalid" : ""
                }`}
                placeholder="Ketik jumlah redeem points.."
              />
              <div className="invalid-feedback">{errors.price?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Reward Points <span className="text-red-600">*</span>
              </p>
              <input
                name="rewardPoints"
                type="text"
                min={0}
                {...register("rewardPoints")}
                className={`form-control-db ${
                  errors.rewardPoints ? "is-invalid" : ""
                }`}
                placeholder="Ketik jumlah reward points.."
              />
              <div className="invalid-feedback">
                {errors.rewardPoints?.message}
              </div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Deskripsi <span className="text-red-600">*</span>
              </p>
              <textarea
                id="description"
                name="description"
                {...register("description")}
                className={`form-control-db ${
                  errors.description ? "is-invalid" : ""
                } w-full h-64 focus:outline-none resize-none`}
                placeholder="Ketik deskripsi.."
              />
              <div className="invalid-feedback">
                {errors.description?.message}
              </div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Link Video <span className="text-red-600">*</span>
              </p>
              <Creatable
                isClearable
                isMulti
                components={{ DropdownIndicator: null }}
                inputValue={tagInputValue}
                menuIsOpen={false}
                onChange={(value) => handleChange("tags", value)}
                placeholder="Ketik link video.."
                onKeyDown={handleKeyDown}
                onInputChange={handleInputChange}
                value={tagValue}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: "#8f959a",
                    marginTop: "0.25rem",
                    paddingTop: "0.3125rem",
                    paddingBottom: "0.3125rem",
                    paddingLeft: "0.5rem",
                    borderRadius: "0.25rem",
                  }),
                }}
              />
              {valueList?.length === 0 && (
                <div className="invalid-feedback">
                  Link video tidak boleh kosong
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateCourse;
