import DataTable from "react-data-table-component";
import { ProjectTypeMap } from "constants";
import { ShortDateTimeFormat, ShortDateFormat } from "utils/helper";

function Table({ data, loading }) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Type = (row) => {
    return ProjectTypeMap[row.type];
  };

  const Period = (row) => (
    <div>
      <h6>{ShortDateFormat(row.start_period)}</h6>
      <h6>{ShortDateFormat(row.end_period)}</h6>
    </div>
  );

  const columns = [
    {
      name: "Project Number",
      selector: (row) => row.project_number,
      grow: 1,
      wrap: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.name,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => (
        <span className="capitalize">{row.project_category}</span>
      ),
      grow: 0.5,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      grow: 0.5,
      wrap: true,
      cell: (row) => <Type {...row} />,
    },
    {
      name: "Period",
      selector: (row) => row.start_period,
      grow: 0.5,
      wrap: true,
      cell: (row) => <Period {...row} />,
    },
    {
      name: "Quota",
      selector: (row) => `${row.current_quota} / ${row.quota}`,
      width: "75px",
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "80px",
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      grow: 0.8,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
