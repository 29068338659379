import { Dropdown } from "@nextui-org/react";
import { MediaTypeMap } from "constants";

function MediaTypeDropdown({ mediaTypeValue, mediaType, setMediaType }) {
  const MediaTypeDatas = Object.entries(MediaTypeMap);
  MediaTypeMap["ALL"] = "Semua Jenis";

  return (
    <Dropdown>
      <Dropdown.Button flat>
        {MediaTypeMap[mediaTypeValue]}
      </Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={mediaType}
        onSelectionChange={setMediaType}
      >
        {MediaTypeDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MediaTypeDropdown;
