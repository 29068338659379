const SelectStyles = {
  menu: provided => ({ ...provided, zIndex: 9999 }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#0072F5",
    "&:hover": {
      color: "#0072F5",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000000",
    border: state.isFocused ? "2px solid #0072F5" : "1.5px solid #B3B3B3",
    boxShadow: "0 !important",
    minHeight: "40px",
    height: "40px",
    minWidth: "220px",
    borderRadius: "12px",
    "&:hover": {
      border: "2px solid #0072F5",
    },
  }),
  menuList: (base) => ({
    ...base,
    paddingLeft: "6px !important",
    paddingRight: "6px !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#ECEEF0" : "white",
    color: "#000000",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "#ECEEF0",
    },
  }),
};

export default SelectStyles;
