import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { Currency } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  navigate,
  pathname,
}) {
  const handleEdit = (row) => {
    if (row) {
      navigate(`${pathname}/edit/${row.tier}/${row.type}`);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.tier}/${row.type}`)}
    >
      Detail
    </Button>
  );

  const Action = (row) => (
    <div className="flex flex-row space-x-2">
      <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
        Edit
      </Button>
      {/* <Button
        size="xs"
        color="error"
        auto
        onClick={() => handleDelete(row.tier, row.type)}
      >
        Delete
      </Button> */}
    </div>
  );

  const columns = [
    {
      name: "Tier",
      selector: (row) => row.tier,
      grow: 0.5,
      wrap: true,
      center: true,
    },
    {
      name: "Nama Tier",
      selector: (row) => row.name,
      grow: 2,
      wrap: true,
    },
    {
      name: "Kategori",
      selector: (row) => <span className="capitalize">{row.type}</span>,
      grow: 0.5,
      wrap: true,
    },
    {
      name: "Nominal",
      selector: (row) => Currency(row.price),
      wrap: true,
      right: true,
    },
    {
      name: "Status Tier",
      selector: (row) =>
        row.is_special_tier && 1 ? "Special Tier" : "Bukan Special Tier",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.tier,
      center: true,
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      center: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.is_special_tier === 1,
      style: {
        backgroundColor: "rgba(254,249,195,1.000)",
      },
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default Table;
