import { Dropdown } from "@nextui-org/react";

function TypeDropdown({ typeValue, type, setType }) {
  const TypeMap = {
    0: "Promo Diskon",
    1: "Promo Cashback",
    "": "Semua Tipe Promo",
  };

  const typeDatas = Object.entries(TypeMap);

  return (
    <Dropdown>
      <Dropdown.Button flat>{TypeMap[typeValue]}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={type}
        onSelectionChange={setType}
      >
        {typeDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TypeDropdown;
