import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { RolesMap, ROLE_SUPERADMIN, ROLE_ADMIN } from "utils/roles";
import { ShortDateTimeFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  navigate,
}) {
  const handleEdit = (row) => {
    if (row) {
      navigate(`/edit-user/${row.admin_id}`);
    }
  };
  let loggedUser = JSON.parse(localStorage.getItem("user"));
  const roleUser = loggedUser?.role;

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    subHeader: {
      style: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };

  const Role = (row) => {
    return RolesMap[row.role];
  };

  const Action = (row) =>
    (roleUser === ROLE_SUPERADMIN || roleUser === ROLE_ADMIN) && (
      <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
        {(roleUser === ROLE_SUPERADMIN ||
          (roleUser === ROLE_ADMIN && row.role !== ROLE_SUPERADMIN)) && (
          <Button
            size="xs"
            color="success"
            auto
            onClick={() => handleEdit(row)}
          >
            Edit
          </Button>
        )}

        {loggedUser.email !== row.email &&
          row.role !== ROLE_SUPERADMIN &&
          (roleUser === ROLE_SUPERADMIN || roleUser === ROLE_ADMIN) && (
            <Button
              size="xs"
              color="error"
              auto
              onClick={() => handleDelete(row.admin_id)}
            >
              Delete
            </Button>
          )}
      </div>
    );

  const columns = [
    {
      name: "Nama",
      selector: (row) => row.name,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      grow: 1.3,
      wrap: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.phone_number,
      wrap: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      grow: 1.2,
      wrap: true,
      cell: (row) => <Role {...row} />,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
