import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Link, Button, Loading } from "@nextui-org/react";
import { Alert } from "components";
import { HiOutlineDownload } from "react-icons/hi";
import { ShortDateTimeFormat } from "utils/helper";

function ReportEvidence({ projectId, category }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchEvidence = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getReportEvidence(
        page,
        perPage,
        projectId
      );
      const timer = setTimeout(() => {
        if (response.data.data.evidences.length) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
        setData(response.data.data.evidences);
        setTotalRows(response.data.data.meta.total);
        setLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.evidences);
    }
  };

  const handlePageChange = (page) => {
    fetchEvidence(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getReportEvidence(
        page,
        newPerPage,
        projectId
      );
      const timer = setTimeout(() => {
        setData(response.data.data.evidences);
        setPerPage(newPerPage);
        setLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.evidences);
    }
  };

  useEffect(() => {
    fetchEvidence(1);
  }, []);

  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const LinkEvidence = (row) => (
    <Link
      color="primary"
      href={row.link_evidence}
      target="_blank"
      rel="noopener noreferrer"
      isExternal
    >
      {row.link_evidence?.length > 25
        ? row.link_evidence.slice(0, 25) + "..."
        : row.link_evidence}
    </Link>
  );

  // custom columns
  const columns = [
    {
      name: data?.blogger_name?.length ? "Blogger" : "Media",
      selector: (row) => (row.blogger_name ? row.blogger_name : row.media_name),
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Link",
      selector: (row) => row.link_evidence,
      width: "150px",
      wrap: true,
      cell: (row) => <LinkEvidence {...row} />,
    },
    {
      name: "Likes",
      selector: (row) => row.likes,
      width: "70px",
      wrap: true,
      right: true,
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
      width: "100px",
      wrap: true,
      right: true,
    },
    {
      name: "Share",
      selector: (row) => row.share,
      width: "80px",
      wrap: true,
      right: true,
    },
    {
      name: "Impression",
      selector: (row) => row.impression,
      width: "105px",
      wrap: true,
      right: true,
    },
    {
      name: "Reach",
      selector: (row) => row.reach,
      width: "75px",
      wrap: true,
      right: true,
    },
    {
      name: "Visitor",
      selector: (row) => row.visitor,
      width: "80px",
      wrap: true,
      right: true,
    },
    {
      name: "Page View",
      selector: (row) => row.total_page_view,
      width: "105px",
      wrap: true,
      right: true,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      width: "150px",
      wrap: true,
    },
  ];

  // Table
  function TableComponent() {
    return (
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }

  const handleExport = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengexport report evidence ke excel.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getExportEvidence(projectId, category).then(
          (response) => {
            if (response) {
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Berhasil download report evidence",
              });
              window.location.href = response.data.data.url;
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <>
      <div className="flex flex-col items-end mb-3">
        <Button
          size="md"
          color="default"
          auto
          iconRight={!loading && <HiOutlineDownload />}
          onClick={() => handleExport()}
          disabled={isDisabled}
        >
          {loading && <Loading type="spinner" color="currentColor" size="sm" />}
          {!loading && "Export to excel"}
        </Button>
      </div>
      <div className="rounded-xl">{TableComponent()}</div>
    </>
  );
}
export default ReportEvidence;
