import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Button, ButtonBack, Alert } from "components";
import { FullDateTimeFormat, ShortDateTimeFormat } from "utils/helper";

function ForumDetail() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [forumDetail, setForumDetail] = useState([]);
  const [forumComment, setForumComment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const fetchForumDetail = async () => {
    try {
      setLoading(true);
      const response = await UserService.getForumDetail(id);
      setForumDetail(response.data.data.forum);
      setForumComment(response.data.data.forum.comments);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setForumDetail(err.response.data.data.forum);
      setForumComment(err.response.data.data.forum.comments);
    }
  };

  useEffect(() => {
    fetchForumDetail();
  }, [refresh]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Forum Comment List"
        columns={columns}
        data={forumComment}
        progressPending={loading}
        customStyles={customStyles}
      />
    );
  }
  // Table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Photo = (row) =>
    row.photoProfile ? (
      <img
        width="96px"
        height="96px"
        alt={row.bloggerName}
        src={row.photoProfile}
      />
    ) : (
      "-"
    );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.commentId,
      wrap: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.bloggerName,
      wrap: true,
      width: "150px",
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
      wrap: true,
      grow: 3.5,
      format: (row) =>
        `${
          row.comment.length > 100
            ? row.comment.slice(0, 100) + "..."
            : row.comment
        }`,
    },
    {
      name: "Photo",
      grow: 1,
      cell: (row) => <Photo {...row} />,
    },
    {
      name: "Created At",
      selector: (row) => FullDateTimeFormat(row.createdAt),
      wrap: true,
      grow: 1.5,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  const Action = (row) => (
    <button
      className="link text-red-600 font-bold underline focus:outline-none"
      onClick={(e) => handleDelete(e, row.commentId)}
    >
      Delete
    </button>
  );

  // Delete Comment
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Forum Comment yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteForumComment(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Forum Comment telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white mx-3 mb-8 lg:mx-7 rounded-xl px-6 pb-4 shadow-section">
        <div className="pt-4">
          {forumDetail ? (
            <>
              <div className="py-2 flex flex-wrap -mx-px overflow-hidden space-y-2 lg:space-y-0">
                <div className="overflow-hidden w-full lg:w-1/4">
                  <p className="text-xs text-login-gray-dark">Forum Id</p>
                  <p className="mt-2 text-black font-bold">
                    {forumDetail.forumId}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-1/4">
                  <p className="text-xs text-login-gray-dark">Name</p>
                  <p className="mt-2 text-black font-bold">
                    {forumDetail.bloggerName}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-1/4">
                  <p className="text-xs text-login-gray-dark">Total Comment</p>
                  <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                    {forumDetail.totalComment}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-1/4">
                  <p className="text-xs text-login-gray-dark">Created At</p>
                  <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                    {forumDetail?.createdAt
                      ? ShortDateTimeFormat(forumDetail.createdAt)
                      : null}
                  </p>
                </div>

                <div className="pt-2 flex flex-wrap -mx-px overflow-hidden space-y-2 md:space-y-0">
                  <div className="overflow-hidden w-full">
                    <p className="text-xs text-login-gray-dark">Message</p>
                    <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                      {forumDetail?.message?.length > 320
                        ? `${forumDetail.message.slice(0, 320)}...`
                        : forumDetail.message}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className="flex items-center justify-center">No Data</span>
          )}
        </div>
      </div>

      <div className="bg-white mx-3 mb-16 lg:mx-7 rounded-xl px-6 pb-4 shadow-section">
        <div className="pt-6 pb-8">
          <div className="flex flex-col items-end">
            <Link to={`${pathname}/add`}>
              <Button text="Add Forum Comment" />
            </Link>
          </div>
          <div className="rounded-xl">{TableComponent()}</div>
        </div>
      </div>
    </>
  );
}

export default ForumDetail;
