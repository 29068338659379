import { Grid, Card, Col, Text } from "@nextui-org/react";
import { Currency } from "utils/helper";

function StatusBar({
  totalBlogger,
  totalProject,
  totalTask,
  totalAccumulative,
}) {
  return (
    <Grid.Container gap={1} css={{ padding: 0 }}>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total User
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalBlogger}
                {!totalBlogger && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Project
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalProject}
                {!totalProject && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Task
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalTask}
                {!totalTask && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Akumulasi Nilai Project
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {Currency(totalAccumulative)}
                {!totalAccumulative && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
    </Grid.Container>
  );
}

export default StatusBar;
