import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, ButtonBack } from "components";
import { imageicon } from "assets";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserService from "services/user.service";
import { zonedTimeToUtc } from "date-fns-tz";
import { Card, Loading, Button } from "@nextui-org/react";

function CreateTask() {
  const { id } = useParams();
  const project_id = id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    const fetchProjectDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getProjectMasterDetail(id);
        setProjectData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        err.response.status > 200 && setIsError(true);
      }
    };
    fetchProjectDetail();
  }, [id]);

  // Handle image preview
  const fileInput = useRef();
  const [image, setImage] = useState("");
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama task is required"),
    price: Yup.number()
      .required("Harga per task is required")
      .positive("Harga per task must be positive number")
      .integer("Harga per task must be integer number"),
    description: Yup.string().required("Instruksi task is required"),
    due_date: Yup.string().required("Due date is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  // Handle submit
  const onSubmit = (e) => {
    const date = e.due_date;
    const timeZone = "Asia/Jakarta";
    const utcDate = zonedTimeToUtc(date, timeZone);

    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data.url;
          return UserService.createProjectTask(
            project_id,
            e.name,
            e.price,
            e.description,
            utcDate,
            [imageUri]
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Task berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.createProjectTask(
        project_id,
        e.name,
        e.price,
        e.description,
        utcDate
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Task berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body css={{ p: "$10" }}>
            {isLoading && (
              <div className="flex items-center justify-center h-[120px]">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!isLoading && isError && (
              <span className="flex items-center justify-center h-[120px] font-bold text-red-500">
                No Data Found
              </span>
            )}
            {projectData && !isLoading && !isError && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row justify-end">
                  <Button
                    type="submit"
                    size="md"
                    auto
                    css={{ "background-color": "#FF9D25", color: "$black" }}
                  >
                    Submit
                  </Button>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-11">
                  <div className="form-group relative">
                    <p className="mb-1 text-xs text-login-gray-dark">
                      Nama Task <span className="text-red-600">*</span>
                    </p>
                    <input
                      name="name"
                      type="text"
                      {...register("name")}
                      className={`form-control-db ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Nama task"
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div>
                  </div>

                  <div className="form-group relative">
                    <p className="mb-1 text-xs text-login-gray-dark">
                      Harga <span className="text-red-600">*</span>
                    </p>
                    <input
                      name="price"
                      type="number"
                      {...register("price")}
                      className={`form-control-db ${
                        errors.price ? "is-invalid" : ""
                      }`}
                      placeholder="Harga per task"
                      defaultValue={projectData?.price_per_task}
                      min={projectData?.price_per_task}
                    />
                    <div className="invalid-feedback">
                      {errors.price?.message}
                    </div>
                  </div>

                  <div className="form-group relative">
                    <p className="mb-1 text-xs text-login-gray-dark">
                      Due Date <span className="text-red-600">*</span>
                    </p>
                    <input
                      id="due_date"
                      name="due_date"
                      type="datetime-local"
                      min={new Date().toISOString().substring(0, 16)}
                      {...register("due_date")}
                      className={`form-control-db ${
                        errors.due_date ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.due_date?.message}
                    </div>
                  </div>

                  <div className="col-span-1 md:col-span-2 form-group relative">
                    <p className="mb-1 text-xs text-login-gray-dark">
                      Instruksi <span className="text-red-600">*</span>
                    </p>
                    <textarea
                      id="description"
                      name="description"
                      {...register("description")}
                      className={`form-control-db ${
                        errors.description ? "is-invalid" : ""
                      } mt-2 w-full h-36 focus:outline-none resize-none`}
                      placeholder="Instruksi task"
                    />
                    <div className="invalid-feedback">
                      {errors.description?.message}
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="mb-1 text-xs text-login-gray-dark">
                      Gambar Referensi (Opsional)
                    </p>
                    <div className="mt-2 w-full xl:w-max xl:h-24">
                      <div className="mt-3 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
                        <label htmlFor="photo">
                          {image ? (
                            <img
                              src={image}
                              alt="preview"
                              className="w-60 h-32 rounded-lg object-scale-down"
                            />
                          ) : (
                            <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                              <img src={imageicon} alt="icon" />
                            </div>
                          )}

                          <input
                            type="file"
                            className="hidden"
                            id="photo"
                            name="photo"
                            accept="image/jpg, image/png"
                            ref={fileInput}
                            onChange={handleImage}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default CreateTask;
