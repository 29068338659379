import { Dropdown } from "@nextui-org/react";

function TierDropdown({ tierList, tierValue, tier, setTier }) {
  const datas = Object.entries(tierList);
  tierList[""] = "Semua Tier";

  return (
    <Dropdown>
      <Dropdown.Button flat>{tierList[tierValue]}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={tier}
        onSelectionChange={setTier}
      >
        {datas &&
          datas.map((data) => (
            <Dropdown.Item
              key={data[0]}
              css={{
                whiteSpace: "nowrap",
              }}
            >
              {data[1]}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TierDropdown;
