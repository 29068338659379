import React from "react";

function Loader() {
  return (
    <div className="md:ml-64 bg-transparent h-screen">
      <div className="mx-auto my-auto flex h-screen items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 w-20 h-20"></div>
      </div>
    </div>
  );
}

export default Loader;