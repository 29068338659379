import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonAdd, Alert } from "components";
import UserService from "services/user.service";
import { Text, Card } from "@nextui-org/react";
import Table from "./components/Table";

function Faq() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);
  const navigate = useNavigate();

  const fetchFaq = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getFaq(page, perPage);
      setData(response.data.data.faqs);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.faqs);
    }
  };

  const handlePageChange = (page) => {
    fetchFaq(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getFaq(page, newPerPage);
      setData(response.data.data.faqs);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.faqs);
    }
  };

  useEffect(() => {
    fetchFaq(1);
  }, [refresh]);

  // Add Faq
  const goAdd = () => {
    navigate("/faq/add");
  };

  // Edit Faq
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editFaq", JSON.stringify(row));
      navigate(`/faq/edit/${row.faqId}`);
    }
  };

  // Delete Faq
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan FAQ yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteFaq(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "FAQ telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          FAQ List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex items-center justify-end mb-3">
            <ButtonAdd action={goAdd} text="Add New FAQ" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Faq;
