import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, ButtonBack, Alert } from "components";
import UserService from "services/user.service";

function Category() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const fetchCategory = async () => {
    try {
      setLoading(true);

      const response = await UserService.getCourseCategory();

      setData(response.data.data.categories);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.categories);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, [refresh]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Course Category List"
        columns={columns}
        data={data}
        progressPending={loading}
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>

      <span className="mx-1">-</span>

      <button
        className="link text-red-600 font-bold underline focus:outline-none"
        onClick={(e) => handleDelete(e, row.categoryId)}
      >
        Delete
      </button>
    </div>
  );

  const columns = [
    {
      name: "Category Id",
      selector: (row) => row.categoryId,
      width: "200px",
    },
    {
      name: "Category Name",
      selector: (row) => row.category,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  // Edit category
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editCourseCategory", JSON.stringify(row));
      navigate(`${pathname}/${row.categoryId}/edit`);
    }
  };

  // Delete category
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan kategori yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteCourseCategory(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Kategori telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`/pdp/category/add`}>
            <Button text="Add New Course Category" />
          </Link>
        </div>
        {TableComponent()}
      </div>
    </>
  );
}

export default Category;
