import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { HiCheckCircle } from "react-icons/hi";
import { ShortDateTimeFormat } from "utils/helper"

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleVerification,
  navigate,
  pathname,
}) {
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  // custom columns
  const Verification = (row) =>
    !row.is_verified ? (
      <Button
        size="xs"
        color="success"
        auto
        onClick={() => handleVerification(row.client_id, row.company_name)}
      >
        Confirm
      </Button>
    ) : (
      <div className="text-green-500">
        <HiCheckCircle size={24} />
      </div>
    );

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.client_id}`)}
    >
      Detail
    </Button>
  );

  const columns = [
    {
      name: "Nama",
      selector: (row) => row.company_name,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.company_email,
      grow: 1.3,
      wrap: true,
    },
    {
      name: "No Telp",
      selector: (row) => row.company_phone_number,
      grow: 1.3,
      wrap: true,
    },
    {
      name: "Nama PIC",
      selector: (row) => row.pic_name,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Email PIC",
      selector: (row) => row.pic_email,
      grow: 1.3,
      wrap: true,
    },
    {
      name: "Income",
      selector: (row) => row.annual_income,
      width: "80px",
      wrap: true,
    },
    {
      name: "Tipe",
      selector: (row) => row.company_size,
      width: "90px",
      wrap: true,
    },
    {
      name: "Dibuat",
      selector: (row) => row.created_at,
      wrap: true,
      cell: (row) => ShortDateTimeFormat(row.created_at),
    },
    {
      name: "Detail",
      selector: (row) => row.action,
      width: "75px",
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Verification",
      selector: (row) => row.verification,
      width: "110px",
      cell: (row) => <Verification {...row} />,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.special_client === 1,
      style: {
        backgroundColor: "rgba(254,249,195,1.000)",
      },
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default Table;
