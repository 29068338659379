import { Grid, Card, Col, Text } from "@nextui-org/react";
import { Currency } from "utils/helper";

function StatusBar({ totalBlogger, totalMedia, totalClient, totalBudget }) {
  return (
    <Grid.Container gap={1} css={{ padding: 0 }}>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total User Blogger
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalBlogger}
                {!totalBlogger && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total User Media
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalMedia}
                {!totalMedia && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total User Client
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalClient}
                {!totalClient && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Budget Project
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {Currency(totalBudget)}
                {!totalBudget && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
    </Grid.Container>
  );
}

export default StatusBar;
