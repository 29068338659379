/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";

// General
// const uploadImage = (formData) => {
//   return axios
//     .post(`${process.env.REACT_APP_API_URL}/admin/upload`, formData, {
//       headers: authHeader(),
//     })
//     .then((response) => {
//       return response;
//     });
// };

const uploadImage = (formData) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_CLIENT_URL}/uploads/v1/upload`,
      formData,
      {
        auth: {
          username: process.env.REACT_APP_CLIENT_USERNAME,
          password: process.env.REACT_APP_CLIENT_PASSWORD,
        },
      }
    )
    .then((response) => {
      return response;
    });
};

const getProvince = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/location/province?limit=50`,
    {
      headers: authHeader(),
    }
  );
};

const getCity = (provinceId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/location/city?province_id=${provinceId}&limit=50`,
    {
      headers: authHeader(),
    }
  );
};

const getIndustryCategory = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client/company/category`,
    {
      headers: authHeader(),
    }
  );
};

const getExportAdmin = (keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/master/export?page=1&limit=1000&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const getExportBlogger = (tier, province, city, keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/user/export?page=1&limit=1000&tier=${tier}&province=${province}&city=${city}&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const getExportMedia = (keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/media/export?page=1&limit=1000&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const getExportClient = (keyword, userType = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client/export?page=1&limit=1000&keyword=${keyword}&is_consultant=${userType}`,
    {
      headers: authHeader(),
    }
  );
};

const getExportEvidence = (id, category) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/transaction/export/${id}/${category}`,
    {
      headers: authHeader(),
    }
  );
};

// User
const getUserAdmin = (page, perPage, keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/master?page=${page}&limit=${perPage}&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const getAdminDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/master/${id}`, {
    headers: authHeader(),
  });
};

const getUserInvitation = (page, perPage, type, keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/user/invititatons?page=${page}&limit=${perPage}&type=${type}&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const createUserAdmin = (name, email, password, phone_number, type, photo) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/master`,
      {
        name,
        email,
        password,
        phone_number,
        type,
        photo,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateUserAdmin = (id, name, phone_number, type, photo) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/master/${id}`,
      {
        name,
        phone_number,
        type,
        photo,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteUserAdmin = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/master/${id}`, {
    headers: authHeader(),
  });
};

const getUserBlogger = (page, perPage, keyword, tier, province, city) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/user?page=${page}&limit=${perPage}&keyword=${keyword}&tier=${tier}&province=${province}&city=${city}`,
    {
      headers: authHeader(),
    }
  );
};

const updateBloggerTier = (id, blogger_tier) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/user/tier/${id}`,
      { blogger_tier },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getBloggerDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/user/${id}`, {
    headers: authHeader(),
  });
};

const getUserMedia = (page, perPage, keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/media?page=${page}&limit=${perPage}&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const getMediaReferral = (page, perPage, keyword, id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/media?page=${page}&limit=${perPage}&keyword=${keyword}&reference_id=${id}`,
    {
      headers: authHeader(),
    }
  );
};

const updateMediaPartner = (id, mediapartner) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/media/partner/${id}`,
      { mediapartner },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateMediaTier = (id, media_tier) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/media/tier/${id}`,
      { media_tier },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getMediaDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/media/${id}`, {
    headers: authHeader(),
  });
};

const getMediaBalance = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/balance/media/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getUserClient = (page, perPage, keyword, userType = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client?page=${page}&limit=${perPage}&keyword=${keyword}&is_consultant=${userType}`,
    {
      headers: authHeader(),
    }
  );
};

const updateClientStatus = (id, is_verified, special_client) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/client/${id}`,
      { is_verified, special_client },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getClientDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/client/${id}`, {
    headers: authHeader(),
  });
};

// Project
const getProjectMaster = (page, perPage, keyword, status, project_category) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/master?page=${page}&limit=${perPage}&keyword=${keyword}&status=${status}&project_category=${project_category}`,
    {
      headers: authHeader(),
    }
  );
};

const getProjectMasterDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/master/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const createProjectMaster = (
  name,
  price_per_task,
  type,
  category,
  sub_type,
  media_type,
  classification,
  start_period,
  end_period,
  status,
  industry,
  province_id,
  city_id,
  quota,
  description,
  brief,
  image,
  blogger,
  media
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/project/master/${category}`,
      {
        name,
        price_per_task,
        type,
        project_category: category,
        sub_type,
        media_type,
        classification,
        start_period,
        end_period,
        status,
        industry,
        province_id,
        city_id,
        quota,
        description,
        file_brief: brief,
        image,
        blogger,
        media,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateProjectMaster = (
  id,
  name,
  price_per_task,
  status,
  quota,
  description,
  brief,
  start_period,
  end_period,
  province_id,
  city_id,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/project/master/${id}`,
      {
        name,
        price_per_task,
        status,
        quota,
        description,
        brief,
        start_period,
        end_period,
        province_id,
        city_id,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteProjectMaster = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/project/master/${id}`,
    {
      headers: authHeader(),
    }
  );
};

// Task
const getProjectTask = (id, page, perPage) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/task?project_id=${id}&limit=${perPage}&page=${page}`,
    {
      headers: authHeader(),
    }
  );
};

const getTaskDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/task/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const createProjectTask = (
  project_id,
  name,
  price,
  description,
  due_date,
  refferences
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/project/task`,
      {
        project_id,
        name,
        price,
        description,
        due_date,
        refferences,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Project Assignor (Submission)
const getProjectAssignor = (id, page, perPage) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/assignor/${id}/task?page=${page}&limit=${perPage}`,
    {
      headers: authHeader(),
    }
  );
};

const getAssignorStatus = (status, id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/assignor/${id}/${status}`,
    {
      headers: authHeader(),
    }
  );
};

const getProjectAssignorDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/assignor/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const createFeedbackAssignor = (id, status, message) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/project/assignor/${id}/feedback`,
      {
        status,
        message,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getEvidence = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/assignor/evidence/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getReportEvidence = (page, limit, projectId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/assignor/evidences?page=${page}&limit=${limit}&projectId=${projectId}`,
    {
      headers: authHeader(),
    }
  );
};

// Activity
const getTransactionList = (
  page,
  perPage,
  keyword,
  mediaType,
  sortType,
  userType,
  sortBy
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/transaction?page=${page}&limit=${perPage}&keyword=${keyword}&media_type=${mediaType}&sort_type=${sortType}&userType=${userType}&sort_by=${sortBy}`,
    {
      headers: authHeader(),
    }
  );
};

const getTransactionDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/transaction/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getTransactionStatus = (status, id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/project/transaction/${id}/status/${status}`,
    {
      headers: authHeader(),
    }
  );
};

// Client Activity
const getClientTransactionList = (page, limit, status, keyword, type) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client/transaction?page=${page}&limit=${limit}&status=${status}&keyword=${keyword}&type=${type}`,
    {
      headers: authHeader(),
    }
  );
};

const getClientTransactionListPrev = (limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client/transaction?limit=${limit}&status=ALL`,
    {
      headers: authHeader(),
    }
  );
};

const getClientTransactionDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/client/transaction/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const updateClientTransactionStatus = (id, status) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/client/transaction/${id}`,
      {
        status,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Finance
const getWithdrawList = (page, limit, keyword, user_type, status) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/withdraw?page=${page}&limit=${limit}&keyword=${keyword}&user_type=${user_type}&status=${status}`,
    {
      headers: authHeader(),
    }
  );
};

const getWithdrawListPrev = (limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/withdraw?limit=${limit}&status=ALL`,
    {
      headers: authHeader(),
    }
  );
};

const getWithdrawDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/withdraw/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getWithdrawToggle = (id, status) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/withdraw/${id}/${status}`,
    {
      headers: authHeader(),
    }
  );
};

const updateWithdrawStatus = (id, status, evidence) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/finance/withdraw/${id}`,
      {
        status,
        evidence,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getSummaryWithdraw = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/withdraw/summary`,
    {
      headers: authHeader(),
    }
  );
};

const getSummaryBalance = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/balance/summary`,
    {
      headers: authHeader(),
    }
  );
};

const getBalanceList = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/balance?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const getBalanceDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/finance/balance/${id}`,
    {
      headers: authHeader(),
    }
  );
};

// Tier Price
const getTier = (page, limit, type) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/tier?page=${page}&limit=${limit}&type=${type}`,
    {
      headers: authHeader(),
    }
  );
};

const getTierDetail = (tier, type) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/tier/${tier}/${type}`,
    {
      headers: authHeader(),
    }
  );
};

const createTier = (tier, name, price, type) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/tier`,
      {
        tier,
        name,
        price,
        type,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateTier = (tier, name, type, price, is_special_tier) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/tier/${tier}/${type}`,
      {
        name,
        price,
        is_special_tier,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteTier = (tier, type) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/tier/${tier}/${type}`,
    {
      headers: authHeader(),
    }
  );
};

// Announcement
const getAnnouncement = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/announcements?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const createAnnouncement = (
  title,
  type,
  hyperlink,
  slug,
  description,
  backgroundImage
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/announcements`,
      {
        title,
        type,
        hyperlink,
        slug,
        description,
        backgroundImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateAnnouncement = (
  id,
  title,
  type,
  hyperlink,
  slug,
  description,
  backgroundImage
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/announcements/${id}`,
      {
        title,
        type,
        hyperlink,
        slug,
        description,
        backgroundImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteAnnouncement = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/announcements/${id}`,
    {
      headers: authHeader(),
    }
  );
};

// Article
const getArticles = (page, limit, type) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/articles?page=${page}&limit=${limit}&type=${type}`,
    {
      headers: authHeader(),
    }
  );
};

const createArticle = (title, view, type, group, tags, description, image) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/articles`,
      {
        title,
        view,
        type,
        group,
        tags,
        description,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateArticle = (
  id,
  title,
  view,
  type,
  group,
  tags,
  description,
  image
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/articles/${id}`,
      {
        title,
        view,
        type,
        group,
        tags,
        description,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteArticle = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/articles/${id}`, {
    headers: authHeader(),
  });
};

// FAQ
const getFaq = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/faqs?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const createFaq = (category, question, answer) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/faqs`,
      {
        category,
        question,
        answer,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateFaq = (id, category, question, answer) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/faqs/${id}`,
      {
        category,
        question,
        answer,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteFaq = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/faqs/${id}`, {
    headers: authHeader(),
  });
};

// PDP Schedule
const getSchedule = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/schedules?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const deleteSchedule = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/schedules/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const createSchedule = (title, location, dueDate) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/schedules`,
      {
        title,
        location,
        dueDate,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateSchedule = (id, title, location, dueDate) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/schedules/${id}`,
      {
        title,
        location,
        dueDate,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Voucher
const getVoucher = (page, perPage, keyword, status, type) => {
  return axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/admin/voucher?page=${page}&limit=${perPage}&status=${status}${
      keyword && `&title=${keyword}`
    }${type[0] && `&is_cashback=${type}`}`,
    {
      headers: authHeader(),
    }
  );
};

const getVoucherDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/voucher/${id}`, {
    headers: authHeader(),
  });
};

const deleteVoucher = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/voucher/${id}`, {
    headers: authHeader(),
  });
};

const createVoucher = (
  title,
  quota,
  discount_percentage,
  is_cashback,
  max_discount_amount,
  max_cashback_amount,
  start_period,
  end_period,
  description,
  type,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/voucher`,
      {
        title,
        quota,
        discount_percentage,
        is_cashback,
        max_discount_amount,
        max_cashback_amount,
        start_period,
        end_period,
        description,
        type,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateVoucher = (
  id,
  title,
  quota,
  discount_percentage,
  is_cashback,
  max_discount_amount,
  max_cashback_amount,
  start_period,
  end_period,
  description,
  image
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/voucher/${id}`,
      {
        id,
        title,
        quota,
        discount_percentage,
        is_cashback,
        max_discount_amount,
        max_cashback_amount,
        start_period,
        end_period,
        description,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// PDP Course
const getCourse = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/courses?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const deleteCourse = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/courses/${id}`, {
    headers: authHeader(),
  });
};

const createCourse = (
  title,
  description,
  price,
  rewardPoints,
  categoryId,
  videos,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/courses`,
      {
        title,
        description,
        price,
        rewardPoints,
        categoryId,
        videos,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateCourse = (
  id,
  title,
  description,
  price,
  rewardPoints,
  categoryId,
  videos,
  image
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/courses/${id}`,
      {
        title,
        description,
        price,
        rewardPoints,
        categoryId,
        videos,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// PDP Course Task
const getCourseTask = (id, page, perPage) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/course/${id}/tasks?page=${page}&limit=${perPage}`,
    {
      headers: authHeader(),
    }
  );
};

const deleteCourseTask = (id, taskId) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/course/${id}/tasks/${taskId}`,
    {
      headers: authHeader(),
    }
  );
};

const createCourseTask = (id, title, description, duration, type) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/course/${id}/tasks`,
      {
        title,
        description,
        duration,
        type,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateCourseTask = (id, taskId, title, description, duration, type) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/course/${id}/tasks/${taskId}`,
      {
        title,
        description,
        duration,
        type,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// PDP Course Material
const getCourseMaterial = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/course/${id}/materials`,
    {
      headers: authHeader(),
    }
  );
};

const deleteCourseMaterial = (id, courseMaterialIds) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/course/${id}/materials`,
    {
      data: { courseMaterialIds },
      headers: authHeader(),
    }
  );
};

const createCourseMaterial = (id, materials) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/course/${id}/materials`,
      {
        materials,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateCourseMaterial = (id, materials) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/course/${id}/materials`,
      { materials },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// PDP Course Category
const getCourseCategory = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/course/categories`, {
    headers: authHeader(),
  });
};

const createCourseCategory = (category) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/course/categories`,
      {
        category,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateCourseCategory = (id, category) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/course/categories/${id}`,
      {
        category,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteCourseCategory = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/course/categories/${id}`,
    {
      headers: authHeader(),
    }
  );
};

// PDP Survey
const getSurveyGoal = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/survey/goals`, {
    headers: authHeader(),
  });
};

const createSurveyGoal = (title) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/survey/goals`,
      {
        title,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getSurveyQuestion = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/survey/questions`, {
    headers: authHeader(),
  });
};

const createSurveyQuestion = (question, order, answerType, answerOptions) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/survey/questions`,
      {
        question,
        order,
        answerType,
        answerOptions,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateSurveyQuestion = (
  id,
  question,
  order,
  answerType,
  answerOptions
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/survey/questions/${id}`,
      {
        question,
        order,
        answerType,
        answerOptions,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// PDP Launchpad
const getLaunchpad = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/launchpads?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

const createLaunchpad = (
  title,
  description,
  type,
  dueDate,
  startTime,
  endTime,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/launchpads`,
      {
        title,
        description,
        type,
        dueDate,
        startTime,
        endTime,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateLaunchpad = (
  id,
  title,
  description,
  type,
  dueDate,
  startTime,
  endTime,
  image
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/launchpads/${id}`,
      {
        title,
        description,
        type,
        dueDate,
        startTime,
        endTime,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteLaunchpad = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/launchpads/${id}`,
    {
      headers: authHeader(),
    }
  );
};

// PDP Forum
const getForum = (page, limit, keyword) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/forums?page=${page}&limit=${limit}&keyword=${keyword}`,
    {
      headers: authHeader(),
    }
  );
};

const createForum = (message) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/forums`,
      {
        message,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateForum = (id, message) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/admin/forums/${id}`,
      {
        message,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteForum = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/admin/forums/${id}`, {
    headers: authHeader(),
  });
};

const pinForum = (id, pinned) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/admin/forums/${id}`,
      {
        pinned,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getForumDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/forums/${id}`, {
    headers: authHeader(),
  });
};

const createForumComment = (id, comment) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/admin/forums/${id}/comment`,
      {
        comment,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteForumComment = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/forums/${id}/comment`,
    {
      headers: authHeader(),
    }
  );
};

const getSummaryTrans = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/admin/summary/client/transaction`,
    {
      headers: authHeader(),
    }
  );
};

const getSummaryDashboard = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/summary/dashboard`, {
    headers: authHeader(),
  });
};

export default {
  uploadImage,
  getProvince,
  getCity,
  getIndustryCategory,
  getExportAdmin,
  getExportBlogger,
  getExportMedia,
  getExportClient,
  getExportEvidence,
  getUserAdmin,
  getAdminDetail,
  getUserInvitation,
  createUserAdmin,
  updateUserAdmin,
  deleteUserAdmin,
  getUserBlogger,
  updateBloggerTier,
  getBloggerDetail,
  getUserMedia,
  getMediaReferral,
  updateMediaPartner,
  updateMediaTier,
  getMediaDetail,
  getMediaBalance,
  getUserClient,
  updateClientStatus,
  getClientDetail,
  getProjectMaster,
  getProjectMasterDetail,
  createProjectMaster,
  updateProjectMaster,
  deleteProjectMaster,
  getProjectTask,
  getTaskDetail,
  createProjectTask,
  getProjectAssignor,
  getAssignorStatus,
  getProjectAssignorDetail,
  createFeedbackAssignor,
  getEvidence,
  getReportEvidence,
  getTransactionList,
  getTransactionDetail,
  getTransactionStatus,
  getClientTransactionList,
  getClientTransactionListPrev,
  getClientTransactionDetail,
  updateClientTransactionStatus,
  getWithdrawList,
  getWithdrawListPrev,
  getWithdrawDetail,
  getWithdrawToggle,
  updateWithdrawStatus,
  getSummaryWithdraw,
  getSummaryBalance,
  getBalanceList,
  getBalanceDetail,
  getTier,
  getTierDetail,
  createTier,
  updateTier,
  deleteTier,
  getAnnouncement,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  getArticles,
  createArticle,
  updateArticle,
  deleteArticle,
  getFaq,
  createFaq,
  updateFaq,
  deleteFaq,
  getVoucher,
  getVoucherDetail,
  deleteVoucher,
  createVoucher,
  updateVoucher,
  getSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  getCourse,
  createCourse,
  updateCourse,
  deleteCourse,
  getCourseTask,
  createCourseTask,
  updateCourseTask,
  deleteCourseTask,
  getCourseMaterial,
  createCourseMaterial,
  updateCourseMaterial,
  deleteCourseMaterial,
  getCourseCategory,
  createCourseCategory,
  updateCourseCategory,
  deleteCourseCategory,
  getSurveyGoal,
  createSurveyGoal,
  getSurveyQuestion,
  createSurveyQuestion,
  updateSurveyQuestion,
  getLaunchpad,
  createLaunchpad,
  updateLaunchpad,
  deleteLaunchpad,
  getForum,
  createForum,
  updateForum,
  deleteForum,
  pinForum,
  getForumDetail,
  createForumComment,
  deleteForumComment,
  getSummaryTrans,
  getSummaryDashboard,
};
