import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { DURATION_TASK } from "constants";

function CreateCourseTask() {
  const { id, taskId } = useParams();
  const isCreateMode = !taskId;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Judul tidak boleh kosong"),
    description: Yup.string().required("Deskripsi tidak boleh kosong"),
    duration: Yup.string().required("Durasi tidak boleh kosong"),
    type: Yup.string().required("Tipe tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      description: "",
      duration: "",
      type: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editCourseTask"));
      let defaults = {
        title: data?.title,
        description: data?.description,
        duration: data?.duration,
        type: data?.type,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const onSubmit = (e) => {
    return isCreateMode ? createCourseTask(e) : editCourseTask(id, taskId, e);
  };

  const createCourseTask = (e) => {
    return UserService.createCourseTask(
      id,
      e.title,
      e.description,
      e.duration,
      e.type
    )
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Course Task berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  const editCourseTask = (id, taskId, e) => {
    return UserService.updateCourseTask(
      id,
      taskId,
      e.title,
      e.description,
      e.duration,
      e.type
    )
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit Course Task berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("editCourseTask");
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16 round">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11">
            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Judul <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Ketik judul.."
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Tipe <span className="text-red-600">*</span>
              </p>
              <input
                name="type"
                type="text"
                {...register("type")}
                className={`form-control-db ${errors.type ? "is-invalid" : ""}`}
                placeholder="Ketik tipe.."
              />
              <div className="invalid-feedback">{errors.type?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Durasi <span className="text-red-600">*</span>
              </p>
              <select
                name="duration"
                {...register("duration")}
                className={`form-control-db ${
                  errors.duration ? "is-invalid" : ""
                }`}
              >
                <option value="">Pilih Durasi</option>
                {DURATION_TASK.map((duration) => (
                  <option value={duration.value} key={duration.value}>
                    {duration.label}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">{errors.duration?.message}</div>
            </div>

            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Deskripsi <span className="text-red-600">*</span>
              </p>
              <textarea
                id="description"
                name="description"
                {...register("description")}
                className={`form-control-db ${
                  errors.description ? "is-invalid" : ""
                } w-full h-64 text-sm focus:outline-none resize-none`}
                placeholder="Ketik deskripsi.."
              />
              <div className="invalid-feedback">
                {errors.description?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateCourseTask;
