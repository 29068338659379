import { Dropdown } from "@nextui-org/react";
import { StatusTransactionMap } from "constants";

function StatusDropdown({ statusValue, status, setStatus }) {
  const statusDatas = Object.entries(StatusTransactionMap);
  StatusTransactionMap["ALL"] = "Semua Status";

  return (
    <Dropdown>
      <Dropdown.Button flat>
        {StatusTransactionMap[statusValue]}
      </Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={status}
        onSelectionChange={setStatus}
      >
        {statusDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StatusDropdown;
