import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, SearchBar } from "components";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import Table from "./components/Table";
import StatusDropdown from "./components/StatusDropdown";
import TypeDropdown from "./components/TypeDropdown";
import StatusBar from "./components/StatusBar";

export default function ClientTransaction() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [data, setData] = useState([]);
  const [totalProjectBlogger, setTotalProjectBlogger] = useState(null);
  const [totalProjectMedia, setTotalProjectMedia] = useState(null);
  const [waiting, setWaiting] = useState(null);
  const [paid, setPaid] = useState(null);
  const [budget, setBudget] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState(new Set([""]));
  const [status, setStatus] = useState(new Set(["ALL"]));
  const statusValue = useMemo(() => Array.from(status), [status]);
  const typeValue = useMemo(() => Array.from(type), [type]);

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getClientTransactionList(
        page,
        limit,
        statusValue,
        keyword,
        typeValue
      );
      setData(response.data.data);
      setTotalRows(response.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data);
    }
  };

  const fetchWidget = async () => {
    try {
      const response = await UserService.getSummaryTrans();

      setTotalProjectBlogger(response.data.data[0].total_project_blogger);
      setTotalProjectMedia(response.data.data[0].total_project_media);
      setWaiting(response.data.data[0].total_waiting_payment);
      setPaid(response.data.data[0].total_paymeny_approved);
      setBudget(response.data.data[0].totalbudget);

    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    fetchWidget();
  }, []);

  const handlePageChange = (page) => {
    fetchTransactions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getClientTransactionList(
        page,
        newPerPage,
        statusValue,
        keyword,
        typeValue
      );
      setData(response.data.data);
      setLimit(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data);
    }
  };

  useEffect(() => {
    fetchTransactions(1);
  }, [refresh, statusValue, keyword, typeValue]);

  // toggle status
  const handleStatus = (status, id, project) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} activity ${project}`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.updateClientTransactionStatus(id, status).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} activity ${project}`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Client Transaction List
        </Text>
      </div>
      <div className="mb-3">
        <StatusBar
          totalProjectBlogger={totalProjectBlogger}
          totalProjectMedia={totalProjectMedia}
          totalWaiting={waiting}
          totalPaid={paid}
          totalBudget={budget}
        />
      </div>
      <Card>
        <Card.Body>
          <div className="mb-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <SearchBar
              setKeyword={setKeyword}
              placeholder="Cari Transaction Project"
            />
            <StatusDropdown
              statusValue={statusValue}
              status={status}
              setStatus={setStatus}
            />
            <TypeDropdown typeValue={typeValue} type={type} setType={setType} />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            navigate={navigate}
            pathname={pathname}
            handleStatus={handleStatus}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
