import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonAdd, Alert } from "components";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import Table from "./components/Table";

function Articles() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);
  const navigate = useNavigate();

  const fetchArticles = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getArticles(page, perPage, "all");
      setData(response.data.data.articles);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.articles);
    }
  };

  const handlePageChange = (page) => {
    fetchArticles(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getArticles(
        page,
        newPerPage,
        "all"
      );
      setData(response.data.data.articles);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.articles);
    }
  };

  useEffect(() => {
    fetchArticles(1);
  }, [refresh]);

  // Add article
  const goAdd = () => {
    navigate("/articles/add");
  };

  // Edit article
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editArticle", JSON.stringify(row));
      navigate(`/articles/edit/${row.articleId}`);
    }
  };

  // Delete Article
  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan artikel yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteArticle(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Artikel telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Articles List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex items-center justify-end mb-3">
            <ButtonAdd action={goAdd} text="Add New Article" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Articles;
