import { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { ButtonBack, ButtonAdd, Alert } from "components";
import ReportEvidence from "./ReportEvidence";
import { Card, Loading, Button } from "@nextui-org/react";
import { ProjectTypeMap } from "constants";
import { HiOutlineDownload } from "react-icons/hi";
import {
  Currency,
  ShortDateTimeFormat,
  ShortDateFormat,
  FullDateTimeFormat,
} from "utils/helper";

function ProjectDetail() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState("");
  const [projectTask, setProjectTask] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [changeTab, setChangeTab] = useState(true);
  const [category, setCategory] = useState("");

  useEffect(() => {
    const fetchProjectDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getProjectMasterDetail(id);
        setProjectData(response.data.data);
        setCategory(response.data.data.project_category);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        err.response.status > 200 && setIsError(true);
      }
    };
    fetchProjectDetail();
  }, [id]);

  const fetchTask = async (page) => {
    setIsLoading(true);
    try {
      const response = await UserService.getProjectTask(id, page, perPage);
      setProjectTask(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setProjectTask(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchTask(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    try {
      const response = await UserService.getProjectTask(id, page, newPerPage);
      setProjectTask(response.data.data.data);
      setPerPage(newPerPage);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setProjectTask(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchTask(1);
  }, []);

  const Detail = (row) => (
    <Link to={`${pathname}/task/${row.project_task_id}`}>
      <Button size="xs" color="default" auto>
        Lihat Detail
      </Button>
    </Link>
  );

  const columns = [
    {
      name: "Nama Task",
      selector: (row) => row.name,
      grow: 2.1,
      wrap: true,
    },
    {
      name: "Harga",
      selector: (row) => Currency(row.price),
      grow: 1.4,
      wrap: true,
      right: true,
    },
    {
      name: "Instruksi",
      selector: (row) => row.description,
      grow: 2,
      wrap: true,
      format: (row) => `${row.description.slice(0, 100)}...`,
    },
    {
      name: "Kuota",
      selector: (row) => `${row.current_quota} / ${row.quota}`,
      center: true,
      width: "85px",
    },
    {
      name: "Deadline",
      selector: (row) => ShortDateTimeFormat(row.due_date),
      width: "150px",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "Dibuat pada",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      width: "150px",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.project_task_id,
      cell: (row) => <Detail {...row} />,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  function TableComponent() {
    return (
      <DataTable
        columns={columns}
        data={projectTask}
        progressPending={isLoading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }

  const handleDownload = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mendownload file brief.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil download file brief",
        });
        window.location.href = projectData?.file_brief;
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-[120px]">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!isLoading && isError && (
              <span className="flex items-center justify-center h-[120px] font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && projectData && (
              <div className="lg:p-2 flex flex-col lg:flex-row">
                <ul className="w-full lg:w-1/2 flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Nomor Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData?.project_number}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Nama Project</span>
                      </div>
                      <div className="w-1/2 lg:w-3/4">
                        <span className="text-login-gray-dark">
                          {projectData?.name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Kategori</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark capitalize">
                          {projectData?.project_category}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">Tipe Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {ProjectTypeMap[projectData.type]}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">Brief Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData?.description}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Status</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData?.status}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">Kuota</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData.current_quota} / {projectData.quota}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Periode</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {ShortDateFormat(projectData?.start_period)}
                          {" - "}
                          {ShortDateFormat(projectData?.end_period)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">Dibuat pada</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData?.created_at
                            ? FullDateTimeFormat(projectData?.created_at)
                            : null}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">File Brief</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectData?.file_brief && (
                            <Button
                              size="xs"
                              color="default"
                              auto
                              iconRight={!isLoading && <HiOutlineDownload />}
                              onClick={() => handleDownload()}
                              disabled={isError}
                            >
                              {!isLoading && "Download"}
                            </Button>
                          )}
                          {!projectData?.file_brief && "Tidak ada"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-semibold">Gambar</span>
                      </div>
                      <div className="w-1/2">
                        {projectData?.image && (
                          <img
                            src={projectData.image}
                            alt="project"
                            className="w-auto h-40 object-cover"
                          />
                        )}
                        {!projectData?.image && (
                          <span className="text-login-gray-dark">
                            Tidak ada
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>

        <div className="mt-4 flex space-x-2">
          <button
            className={`${
              changeTab ? "bg-pk-primary" : "bg-pk-gray-light"
            } h-10 px-4 py-2 text-black rounded-t-xl duration-200 ease-in-out hover:shadow-lg focus:outline-none`}
            onClick={() => setChangeTab(true)}
          >
            Task List
          </button>
          <button
            className={`${
              changeTab ? "bg-pk-gray-light" : "bg-pk-primary"
            } h-10 px-4 py-2 text-black rounded-t-xl duration-200 ease-in-out hover:shadow-lg focus:outline-none`}
            onClick={() => setChangeTab(false)}
          >
            Report Evidence
          </button>
        </div>
        <Card
          css={{
            borderTopLeftRadius: 0,
            marginBottom: "3rem",
          }}
        >
          <Card.Body>
            {changeTab ? (
              <>
                {projectData.project_category === "blogger" &&
                  projectData.project_number.includes("PENKIT") &&
                  projectData.status === "OPEN" && (
                    <div className="mb-2 flex flex-col items-end">
                      <ButtonAdd
                        action={() => navigate(`${pathname}/create-task`)}
                        text="Tambah Project Task"
                      />
                    </div>
                  )}
                <div className="rounded-xl">{TableComponent()}</div>
              </>
            ) : (
              <ReportEvidence projectId={id} category={category} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default ProjectDetail;
