import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import { ButtonAdd, SearchBar, Alert } from "components";
import Table from "./components/Table";
import StatusDropdown from "./components/StatusDropdown";
import TypeDropdown from "./components/TypeDropdown";

function Voucher() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const goAdd = () => {
    navigate("/voucher/add");
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(new Set(["ALL"]));
  const statusValue = useMemo(() => Array.from(status), [status]);
  const [type, setType] = useState(new Set([""]));
  const typeValue = useMemo(() => Array.from(type), [type]);

  const fetchVoucher = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getVoucher(
        page,
        perPage,
        keyword,
        statusValue,
        typeValue
      );
      setData(response.data.data.promos);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response);
    }
  };

  const handlePageChange = (page) => {
    fetchVoucher(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getVoucher(
        page,
        newPerPage,
        keyword,
        statusValue,
        typeValue
      );
      setData(response.data.data.promos);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response);
    }
  };

  useEffect(() => {
    fetchVoucher(1);
  }, [refresh, keyword, statusValue, typeValue]);

  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Voucher yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteVoucher(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Voucher telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Voucher Banner
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex flex-col md:flex-row items-center justify-between mb-2 space-y-2 md:space-y-0">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <SearchBar
                setKeyword={setKeyword}
                placeholder="Cari berdasarkan judul"
              />
              <StatusDropdown
                statusValue={statusValue}
                status={status}
                setStatus={setStatus}
              />
              <TypeDropdown
                typeValue={typeValue}
                type={type}
                setType={setType}
              />
            </div>
            <ButtonAdd action={goAdd} text="Add Voucher" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            refresh={refresh}
            setRefresh={setRefresh}
            navigate={navigate}
            pathname={pathname}
            handleDelete={handleDelete}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Voucher;
