import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Alert, ButtonBack, Button } from "components";
import { searchicon } from "assets";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { ShortDateFormat } from "utils/helper";

function Forum() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchForum = async (page) => {
    try {
      setLoading(true);
      const response = await UserService.getForum(page, perPage, keyword);
      setData(response.data.data.forums);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.forums);
    }
  };

  const handlePageChange = (page) => {
    fetchForum(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);
      const response = await UserService.getForum(page, newPerPage, keyword);
      setData(response.data.data.forums);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.forums);
    }
  };

  useEffect(() => {
    fetchForum(1);
  }, [refresh, keyword]);

  // Search bar
  const [typing, setTyping] = useState("");
  const handleSearch = () => {
    setKeyword(typing);
  };

  const searchBar = (
    <div className="flex w-64 h-10 text-sm border border-login-gray-light rounded hover:border-pk-primary focus:outline-none">
      <button
        className="mr-2 p-2 bg-pk-gray-light rounded-tl rounded-bl focus:outline-none"
        onClick={handleSearch}
      >
        <img src={searchicon} alt="search icon" />
      </button>
      <input
        type="search"
        placeholder="Search by message"
        className="w-full rounded-tr rounded-br focus:outline-none"
        onChange={(e) => setTyping(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && setKeyword(e.target.value)}
      />
    </div>
  );

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Forum Post List"
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        subHeader
        subHeaderComponent={searchBar}
        persistTableHead
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  // custom columns
  const columns = [
    {
      name: "Id",
      selector: (row) => row.forumId,
      wrap: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.bloggerName,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.userType,
      wrap: true,
      width: "80px",
    },
    {
      name: "Pin Post",
      selector: (row) => row.pinned,
      wrap: true,
      width: "90px",
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Comment",
      selector: (row) => row.totalComment,
      wrap: true,
      width: "100px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      wrap: true,
      grow: 2.4,
      format: (row) =>
        `${
          row.message.length > 100
            ? row.message.slice(0, 100) + "..."
            : row.message
        }`,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateFormat(row.createdAt),
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
    {
      name: "Pin",
      selector: (row) => row.pin,
      wrap: true,
      width: "70px",
      cell: (row) => <Pin {...row} />,
    },
    {
      name: "Detail",
      selector: (row) => row.detail,
      cell: (row) => <Detail {...row} />,
    },
  ];

  const Status = (row) => {
    switch (row.pinned) {
      case true:
        return "Ya";
      case false:
        return "Tidak";
      default:
        return "-";
    }
  };

  const Detail = (row) => (
    <Link
      className="link text-pk-primary font-bold underline"
      to={`${pathname}/${row.forumId}/comment`}
    >
      Lihat Detail
    </Link>
  );

  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>

      <span className="mx-1">-</span>

      <button
        className="link text-red-600 font-bold underline focus:outline-none"
        onClick={(e) => handleDelete(e, row.forumId)}
      >
        Delete
      </button>
    </div>
  );

  const Pin = (row) => (
    <div>
      <button
        className="link text-blue-600 font-bold underline focus:outline-none"
        onClick={() => handlePin(row.forumId, row.pinned)}
      >
        {row.pinned ? "Unpin" : "Pin"}
      </button>
    </div>
  );

  // Pin Forum
  const handlePin = (id, pinned) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan melakukan ${
        pinned ? "unpin pada post ini" : "pin pada post ini"
      }!`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Pin",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.pinForum(id, !pinned).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Forum Post ini telah berhasil ${
                  pinned ? "di unpin" : "di pin"
                }.`,
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  // Edit Forum
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editForum", JSON.stringify(row));
      navigate(`${pathname}/${row.forumId}/edit`);
    }
  };

  // Delete Forum
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Forum Post yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteForum(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Forum Post telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack url="/home" />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`/pdp/forum/add`}>
            <Button text="Add New Forum Post" />
          </Link>
        </div>
        {TableComponent()}
      </div>
    </>
  );
}

export default Forum;
