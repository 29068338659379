import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";

function CreateFaq() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Kategori tidak boleh kosong"),
    question: Yup.string().required("Pertanyaan tidak boleh kosong"),
    answer: Yup.string().required("Jawaban tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      category: "",
      question: "",
      answer: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editFaq"));
      let defaults = {
        category: data?.category,
        question: data?.question,
        answer: data?.answer,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const onSubmit = (e) => {
    return isCreateMode ? createFaq(e) : editFaq(id, e);
  };

  // Create FAQ
  const createFaq = (e) => {
    return UserService.createFaq(e.category, e.question, e.answer)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "FAQ berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  // Edit FAQ
  const editFaq = (id, e) => {
    return UserService.updateFaq(id, e.category, e.question, e.answer)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit FAQ berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("editFaq");
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-11">
            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Category <span className="text-red-600">*</span>
              </p>
              <input
                name="category"
                type="text"
                {...register("category")}
                className={`form-control-db ${
                  errors.category ? "is-invalid" : ""
                }`}
                placeholder="Ketik kategori.."
              />
              <div className="invalid-feedback">{errors.category?.message}</div>
            </div>

            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Pertanyaan <span className="text-red-600">*</span>
              </p>
              <input
                name="question"
                type="text"
                {...register("question")}
                className={`form-control-db ${
                  errors.question ? "is-invalid" : ""
                }`}
                placeholder="Ketik pertanyaan.."
              />
              <div className="invalid-feedback">{errors.question?.message}</div>
            </div>

            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Jawaban <span className="text-red-600">*</span>
              </p>
              <textarea
                id="answer"
                name="answer"
                {...register("answer")}
                className={`form-control-db ${
                  errors.answer ? "is-invalid" : ""
                } w-full h-36 focus:outline-none resize-none`}
                placeholder="Ketik jawaban.."
              />
              <div className="invalid-feedback">{errors.answer?.message}</div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateFaq;
