import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading } from "@nextui-org/react";
import { noimage } from "assets";
import { DateFormat, ShortDateFormat, Currency } from "utils/helper";
import { VoucherStatusMap } from "constants";

function VoucherDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getVoucherDetail(id);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[484px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[484px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <>
              <Card.Header>
                <div className="flex lg:px-5 lg:pt-4">
                  <img
                    src={data?.image ?? noimage}
                    alt={data?.id}
                    className="w-80 h-44 object-cover rounded-xl"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = noimage;
                    }}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <div className="lg:px-5 lg:pb-5 flex flex-col lg:flex-row">
                  <ul className="w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Id</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.id}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Judul</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.title}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-semibold">Persentase</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.discount_percentage
                              ? data.discount_percentage + "%"
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kuota</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.quota ? data.quota : "Tidak terbatas"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kuota Terpakai</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.used_quota}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kode Voucher</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.voucher_code}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Status</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.status ? VoucherStatusMap[data.status] : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Deskripsi</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.description}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Tipe Promo</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.is_cashback ? "Cashback" : "Diskon"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">
                            Jumlah maks cashback
                          </span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {Currency(data?.max_cashback_amount)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Jumlah maks diskon</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {Currency(data?.max_discount_amount)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Periode Mulai</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.start_period
                              ? ShortDateFormat(data?.start_period)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Periode Berakhir</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.end_period
                              ? ShortDateFormat(data?.end_period)
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Dibuat pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.created_at
                              ? DateFormat(data?.created_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Diperbarui pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_at
                              ? DateFormat(data?.updated_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-2/5">
                          <span className="font-bold">Diperbarui oleh</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_by?.name
                              ? data?.updated_by?.name
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export default VoucherDetail;
