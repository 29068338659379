import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, ButtonBack } from "components";
import UserService from "services/user.service";
import {
  Button,
  Card,
  Grid,
  Link,
  Text,
  Loading,
  Image,
} from "@nextui-org/react";

function TaskFeedback() {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [errorFeedback, setErrorFeedback] = useState(false);
  const [evidence, setEvidence] = useState("");
  const [errorEvidence, setErrorEvidence] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const projectCategory = localStorage.getItem("projectCategory");

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status tidak boleh kosong"),
    message: Yup.string().required("Pesan feedback tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      status: "",
    },
  });

  const fetchAssignor = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getProjectAssignorDetail(id);
      setData(response.data.data);
      let defaults = {
        status: response.data.data.status,
      };
      reset(defaults);
      setFeedbackData(response.data.data.feedback);
      setErrorFeedback(false);
      if (response.data.data.feedback === null) {
        setErrorFeedback(true);
      }
      if (response.data.data.status === "DONE") {
        setDisabled(true);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorEvidence(true);
    }
  };

  useEffect(() => {
    fetchAssignor();
  }, [refresh, reset, id]);

  const fetchEvidence = async () => {
    try {
      const response = await UserService.getEvidence(id);
      setEvidence(response.data.data);
      if (!response.data.data) {
        setErrorEvidence(true);
      }
    } catch (err) {
      setErrorEvidence(true);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEvidence();
    }
  }, [id]);

  const onSubmit = (e) => {
    Alert.fire({
      title: "Mengirimkan feedback...",
    });
    Alert.showLoading();
    return UserService.createFeedbackAssignor(id, e.status, e.message)
      .then(() => {
        Alert.close();
        reset();
        setRefresh(refresh + 1);
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Feedback berhasil terkirim.",
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-96">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!isLoading && data && (
            <Grid.Container gap={1}>
              <Grid xs={12} md={8} css={{ padding: "$8" }}>
                {!isLoading && !data && (
                  <span className="flex items-center justify-center h-96 font-bold text-red-500">
                    No Data Found
                  </span>
                )}

                <div className="w-full">
                  <ul className="flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/6">
                          <span className="font-bold">Judul</span>
                        </div>
                        <div className="w-full">
                          <p className="text-justify whitespace-pre-wrap">
                            {data?.submission?.caption
                              ? data?.submission?.caption
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/6">
                          <span className="font-bold">Konten</span>
                        </div>
                        <div className="w-full">
                          <div
                            className="whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                              __html: data?.submission?.content
                                ? data?.submission?.content
                                : "-",
                            }}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-col space-y-2">
                        <div className="w-1/2 lg:w-1/6">
                          <span className="font-bold">Gambar Submission</span>
                        </div>
                        <div className="w-full">
                          {data?.submission?.images && (
                            <Image
                              showSkeleton
                              width={320}
                              height={180}
                              maxDelay={3000}
                              src={data?.submission?.images}
                              alt="Submit Image"
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid xs={12} md={4} css={{ padding: "$8", height: "100%" }}>
                <Card variant="bordered" css={{ background: "#F7F8FC" }}>
                  <Card.Body>
                    <ul>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-2/5 h-full">
                            <span className="font-bold">Id Submission</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.assignor_id}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-2/5">
                            <span className="font-bold">Nama</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.blogger?.name ? data?.blogger?.name : "-"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-2/5">
                            <span className="font-semibold">Dikirim pada</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.created_at &&
                                format(
                                  parseISO(data?.created_at),
                                  "d MMM y HH:mm"
                                )}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-2/5">
                            <span className="font-bold">Link</span>
                          </div>
                          <div className="w-1/2">
                            {data?.submission?.url ? (
                              <Link
                                isExternal
                                color="primary"
                                href={data?.submission?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="break-all">
                                  {data?.submission?.url}
                                </span>
                              </Link>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Card variant="bordered">
                      <Card.Body
                        css={{ paddingTop: "$4", paddingBottom: "$4" }}
                      >
                        <Text className="font-bold">Evidence</Text>
                        {errorEvidence && (
                          <div className="mt-4 text-sm text-center text-[#dc3545]">
                            Data evidence tidak ditemukan
                          </div>
                        )}
                        {evidence && (
                          <div className="mt-2 flex flex-col w-full">
                            <Grid.Container>
                              <Grid
                                xs={12}
                                css={{
                                  paddingTop: "$2",
                                  paddingBottom: "$2",
                                }}
                              >
                                <Grid xs={3}>
                                  <p className="text-xs text-login-gray-dark">
                                    Link
                                  </p>
                                </Grid>
                                <Grid xs={9}>
                                  <Link
                                    isExternal
                                    color="primary"
                                    href={evidence?.post_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="text-xs">
                                      {evidence?.post_link}
                                    </span>
                                  </Link>
                                </Grid>
                              </Grid>

                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Likes
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.like}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Comment
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.comment}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Share
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.share}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Reach
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.reach}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={12}>
                                <Grid xs={12} sm={6}>
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Impression
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.impression}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Visitor
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.visitor}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={12} sm={6}>
                                <div className="w-full flex">
                                  <div className="w-1/2">
                                    <span className="text-xs text-login-gray-dark">
                                      Page View
                                    </span>
                                  </div>
                                  <div className="w-1/2">
                                    <span className="text-xs break-all">
                                      {evidence?.total_page_view}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid.Container>
                          </div>
                        )}
                      </Card.Body>
                    </Card>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-2 flex justify-between items-center space-x-4">
                        <div className="flex flex-col">
                          <p className="mb-2 text-xs text-login-gray-dark">
                            Status <span className="text-red-600">*</span>
                          </p>
                          <div className="w-full">
                            <select
                              id="status"
                              name="status"
                              {...register("status")}
                              className={`${
                                disabled &&
                                "bg-pk-gray-light cursor-not-allowed"
                              } form-control-db ${
                                errors.status ? "is-invalid" : ""
                              }`}
                              disabled={disabled}
                            >
                              <option value="">Pilih status</option>
                              {projectCategory === "blogger" && (
                                <>
                                  <option value="WAITING_PUBLISH">
                                    Waiting Publish
                                  </option>
                                  <option value="REVISION">Revision</option>
                                </>
                              )}
                              <option value="DONE">Done</option>
                            </select>
                            <div className="invalid-feedback">
                              {errors.status?.message}
                            </div>
                          </div>
                        </div>
                        <Button
                          type="submit"
                          size="md"
                          auto
                          css={{
                            "background-color": "#FF9D25",
                            color: "$black",
                            marginTop: "$8",
                          }}
                          disabled={disabled}
                        >
                          Update
                        </Button>
                      </div>

                      <div className="mt-2">
                        <p className="mb-2 text-xs text-login-gray-dark">
                          Feedback <span className="text-red-600">*</span>
                        </p>
                        <textarea
                          id="message"
                          name="message"
                          {...register("message")}
                          placeholder="Tuliskan feedback anda disini.."
                          className={`${
                            disabled && "bg-pk-gray-light cursor-not-allowed"
                          } form-control-db w-full h-16 text-sm resize-none focus:outline-none ${
                            errors.message ? "is-invalid" : ""
                          }`}
                          disabled={disabled}
                          required
                        />
                      </div>
                      <div className="mb-2 invalid-feedback">
                        {errors.message?.message}
                      </div>
                    </form>

                    <Card variant="bordered">
                      <Card.Body
                        css={{ paddingTop: "$4", paddingBottom: "$4" }}
                      >
                        {!errorFeedback &&
                          feedbackData?.length > 0 &&
                          feedbackData.map((data, id) => (
                            <>
                              <div className="my-2 flex flex-col" key={id}>
                                <span className="text-xs text-login-gray-light">
                                  {data.identity}
                                </span>
                                <p className="mt-1 text-sm">{data.message}</p>
                              </div>
                              <Card.Divider />
                            </>
                          ))}
                        {errorFeedback && (
                          <div className="text-sm text-center text-[#dc3545]">
                            Data feedback tidak ditemukan
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Grid>
            </Grid.Container>
          )}
        </Card>
      </div>
    </>
  );
}

export default TaskFeedback;
