import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { ShortDateFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  navigate,
  pathname,
  handleDelete,
}) {
  const handleEdit = (row) => {
    if (row) {
      navigate(`${pathname}/edit/${row.id}`);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.id}`)}
    >
      Detail
    </Button>
  );

  const Action = (row) => (
    <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
      <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
        Edit
      </Button>
      <Button size="xs" color="error" auto onClick={() => handleDelete(row.id)}>
        Delete
      </Button>
    </div>
  );

  const columns = [
    {
      name: "Judul",
      selector: (row) => row.title,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Deskripsi",
      selector: (row) => row.description,
      grow: 1.5,
      wrap: true,
      format: (row) =>
        `${
          row.description?.length > 100
            ? row.description?.slice(0, 70) + "..."
            : row.description
        }`,
    },
    {
      name: "Kode",
      selector: (row) => row.voucher_code,
      width: "85px",
      wrap: true,
      style: {
        backgroundColor: "rgba(254,249,195,1.000)",
      },
    },
    {
      name: "Tipe",
      selector: (row) => (row.is_cashback ? "Cashback" : "Diskon"),
      width: "95px",
      wrap: true,
    },
    {
      name: "Persentase",
      selector: (row) =>
        row.discount_percentage ? row.discount_percentage + "%" : "-",
      width: 1,
      wrap: true,
      center: true,
    },
    {
      name: "Kuota",
      selector: (row) => (row.quota ? row.quota : "∞"),
      width: "75px",
      wrap: true,
      center: true,
    },
    {
      name: "Terpakai",
      selector: (row) => row.used_quota,
      width: "95px",
      wrap: true,
      center: true,
    },
    {
      name: "Mulai",
      selector: (row) => ShortDateFormat(row.start_period),
      grow: 0.75,
      width: "80px",
      wrap: true,
    },
    {
      name: "Selesai",
      selector: (row) => ShortDateFormat(row.end_period),
      grow: 0.75,
      width: "80px",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.tier,
      center: true,
      width: "75px",
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "125px",
      cell: (row) => <Action {...row} />,
    },
  ];

  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.is_cashback === 1,
  //     style: {
  //       backgroundColor: "rgba(254,249,195,1.000)",
  //     },
  //   },
  // ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
        // conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default Table;
