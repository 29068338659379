import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, ButtonBack, Alert } from "components";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";

function Launchpad() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);

  const fetchLaunchpad = async (page) => {
    try {
      setLoading(true);
      const response = await UserService.getLaunchpad(page, perPage);
      setData(response.data.data.launchpads);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.launchpads);
    }
  };

  const handlePageChange = (page) => {
    fetchLaunchpad(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);
      const response = await UserService.getLaunchpad(page, newPerPage);
      setData(response.data.data.launchpads);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.launchpads);
    }
  };

  useEffect(() => {
    fetchLaunchpad(1);
  }, [refresh]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Launchpad List"
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>

      <span className="mx-1">-</span>

      <button
        className="link text-red-600 font-bold underline focus:outline-none"
        onClick={(e) => handleDelete(e, row.launchpadId)}
      >
        Delete
      </button>
    </div>
  );

  const ImageLaunchpad = (row) =>
    row.image ? (
      <img width="96px" height="96px" alt={row.title} src={row.image} />
    ) : null;

  const columns = [
    {
      name: "Id",
      selector: (row) => row.launchpadId,
      wrap: true,
      width: "70px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      grow: 3,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 5,
      wrap: true,
      format: (row) =>
        `${
          row.description.length > 100
            ? row.description.slice(0, 100) + "..."
            : row.description
        }`,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      wrap: true,
    },
    {
      name: "Image",
      grow: 1,
      cell: (row) => <ImageLaunchpad {...row} />,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      wrap: true,
      width: "110px",
    },
    {
      name: "Start",
      selector: (row) => row.startTime,
      wrap: true,
      width: "70px",
    },
    {
      name: "End",
      selector: (row) => row.endTime,
      wrap: true,
      width: "70px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  // Edit Launchpad
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editLaunchpad", JSON.stringify(row));
      navigate(`${pathname}/${row.launchpadId}/edit`);
    }
  };

  // Delete Launchpad
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan launchpad yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteLaunchpad(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Launchpad telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack url="/home" />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`/pdp/launchpad/add`}>
            <Button text="Add New Launchpad" />
          </Link>
        </div>
        {TableComponent()}
      </div>
    </>
  );
}

export default Launchpad;
