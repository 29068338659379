import DataTable from "react-data-table-component";
import { Button, Link } from "@nextui-org/react";
import { ShortDateTimeFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  handleEdit,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Action = (row) => (
    <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
      <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
        Edit
      </Button>
      <Button size="xs" color="error" auto onClick={() => handleDelete(row.id)}>
        Delete
      </Button>
    </div>
  );

  const ImageAnnouncement = (row) =>
    row.backgroundImage ? (
      <img
        alt={row.title}
        src={row.backgroundImage}
        className="max-w-[96px] max-h-[96px] bg-cover"
      />
    ) : (
      "-"
    );

  const Hyperlink = (row) => (
    <Link
      icon
      color="primary"
      href={row.hyperlink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {row.hyperlink.length > 25
        ? row.hyperlink.slice(0, 25) + "..."
        : row.hyperlink}
    </Link>
  );

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 2,
      wrap: true,
      format: (row) =>
        `${
          row.description?.length > 100
            ? row.description?.slice(0, 100) + "..."
            : row.description
        }`,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      width: "80px",
      wrap: true,
    },
    {
      name: "Link",
      selector: (row) => row.hyperlink,
      grow: 1.2,
      wrap: true,
      cell: (row) => <Hyperlink {...row} />,
    },
    {
      name: "Image",
      grow: 1,
      cell: (row) => <ImageAnnouncement {...row} />,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.createdAt),
      width: "150px",
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
