import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { ANSWER_TYPE } from "constants";

function CreateSurveyQuestion() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Pertanyaan tidak boleh kosong"),
    order: Yup.string().required("Urutan tidak boleh kosong"),
    answerType: Yup.string().required("Tipe jawaban tidak boleh kosong"),
    answerOptions: Yup.array().of(
      Yup.object().shape({
        answer: Yup.string().required("Jawaban tidak boleh kosong"),
        goalId: Yup.string().required("Tujuan survey tidak boleh kosong"),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      question: "",
      order: "",
      answerType: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editSurveyQuestion"));
      let defaults = {
        question: data?.question,
        answerOptions: data?.answerOptions,
        order: data?.order,
        answerType: data?.answerType,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  // Get survey goal list
  const [surveyGoalList, setSurveyGoalList] = useState([]);
  useEffect(() => {
    UserService.getSurveyGoal().then(
      (response) => {
        setSurveyGoalList(response.data.data.goals);
      },
      (error) => {
        setSurveyGoalList(error.data);
      }
    );
  }, []);

  const onSubmit = (e) => {
    return isCreateMode ? createSurveyQuestion(e) : editSurveyQuestion(id, e);
  };

  const createSurveyQuestion = (e) => {
    return UserService.createSurveyQuestion(
      e.question,
      e.order,
      e.answerType,
      e.answerOptions
    )
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Survey Question berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  const editSurveyQuestion = (id, e) => {
    return UserService.updateSurveyQuestion(
      id,
      e.question,
      e.order,
      e.answerType,
      e.answerOptions
    )
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit Survey Question berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("editSurveyQuestion");
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4">
            <div className="col-span-1 lg:col-span-3 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Survey Question <span className="text-red-600">*</span>
              </p>
              <input
                name="question"
                type="text"
                {...register("question")}
                className={`form-control-db ${
                  errors.question ? "is-invalid" : ""
                }`}
                placeholder="Ketik survey question.."
              />
              <div className="invalid-feedback">{errors.question?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Urutan <span className="text-red-600">*</span>
              </p>
              <input
                name="order"
                type="number"
                min="0"
                {...register("order")}
                className={`form-control-db ${
                  errors.order ? "is-invalid" : ""
                }`}
                placeholder="Ketik urutan.."
              />
              <div className="invalid-feedback">{errors.order?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Tipe Jawaban <span className="text-red-600">*</span>
              </p>
              <select
                name="answerType"
                {...register("answerType")}
                className={`form-control-db ${
                  errors.answerType ? "is-invalid" : ""
                }`}
              >
                <option value="">Pilih Tipe Jawaban</option>
                {ANSWER_TYPE.map((type) => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors.answerType?.message}
              </div>
            </div>

            {surveyGoalList?.slice(0, 4)?.map((item, i) => (
              <div
                key={i}
                className="col-span-1 lg:col-span-2 form-group relative"
              >
                <div className="col-span-1 lg:col-span-2 form-group relative">
                  <p className="mb-2 text-sm font-bold">Answer {i + 1}</p>
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Jawaban {i + 1} <span className="text-red-600">*</span>
                  </p>
                  <input
                    name={`answerOptions[${i}]answer`}
                    {...register(`answerOptions.${i}.answer`)}
                    type="text"
                    className={`form-control-db ${
                      errors.answerOptions?.[i]?.answer ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.answerOptions?.[i]?.answer?.message}
                  </div>
                </div>

                <div className="col-span-1 lg:col-span-2 form-group relative">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Survey Goal {i + 1} <span className="text-red-600">*</span>
                  </p>
                  <select
                    name={`answerOptions[${i}]goalId`}
                    {...register(`answerOptions.${i}.goalId`)}
                    className={`form-control-db ${
                      errors.answerOptions?.[i]?.goalId ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih Survey Goal</option>
                    {surveyGoalList?.map((value) => (
                      <option value={value.goalId} key={value.goalId}>
                        {value.title}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {errors.answerOptions?.[i]?.goalId?.message}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateSurveyQuestion;
