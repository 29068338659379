import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";

function CreateCourseMaterial() {
  const { id, materialId } = useParams();
  const isCreateMode = !materialId;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    materials: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Judul tidak boleh kosong"),
        order: Yup.string().required("Urutan tidak boleh kosong"),
        link: Yup.string().required("Link tidak boleh kosong"),
        description: Yup.string().required("Deskripsi tidak boleh kosong"),
      })
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      order: "",
      link: "",
      description: "",
    },
  };
  const { register, control, handleSubmit, formState, reset } =
    useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "materials",
    control,
  });

  const [newValue, setNewValue] = useState(1);
  useEffect(() => {
    const oldValue = fields.length;
    if (newValue > oldValue) {
      for (let i = oldValue; i < newValue; i++) {
        append({ title: "", order: "", description: "" });
      }
    } else {
      for (let i = oldValue; i > newValue; i--) {
        remove(i - 1);
      }
    }
  }, [newValue]);

  // add remove button
  const addItem = () => {
    setNewValue(newValue + 1);
  };
  const removeItem = () => {
    if (newValue > 1) {
      setNewValue(newValue - 1);
    }
  };

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editCourseMaterial"));
      let defaults = {
        materials: [
          {
            title: data?.title,
            order: data?.order,
            link: data?.link,
            description: data?.description,
          },
        ],
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const onSubmit = (e) => {
    return isCreateMode
      ? createCourseMaterial(e)
      : editCourseMaterial(id, materialId, e);
  };

  function createCourseMaterial(data) {
    return UserService.createCourseMaterial(id, data.materials)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Course material berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  }

  function editCourseMaterial(id, materialId, e) {
    const body = e.materials;
    body[0]["courseMaterialId"] = materialId;
    return UserService.updateCourseMaterial(id, body)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit Course Material berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("editCourseMaterial");
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  }

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          {fields.map((item, i) => (
            <div key={i}>
              <h5 className="text-sm">Materi {i + 1}</h5>
              <div className="-mt-4 mb-2 grid grid-cols-1 lg:grid-cols-5 gap-x-2">
                <div className="lg:col-span-2 form-group relative">
                  <label className="mb-1 text-xs text-login-gray-dark">
                    Title <span className="text-red-600">*</span>
                  </label>
                  <input
                    name={`materials[${i}]title`}
                    {...register(`materials.${i}.title`)}
                    type="text"
                    className={`form-control-db ${
                      errors.materials?.[i]?.title ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.materials?.[i]?.title?.message}
                  </div>
                </div>

                <div className="form-group relative">
                  <label className="mb-1 text-xs text-login-gray-dark">
                    Urutan <span className="text-red-600">*</span>
                  </label>
                  <input
                    name={`materials[${i}]order`}
                    {...register(`materials.${i}.order`)}
                    type="text"
                    className={`form-control-db ${
                      errors.materials?.[i]?.order ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.materials?.[i]?.order?.message}
                  </div>
                </div>

                <div className="lg:col-span-2 form-group relative">
                  <label className="mb-1 text-xs text-login-gray-dark">
                    Link <span className="text-red-600">*</span>
                  </label>
                  <input
                    name={`materials[${i}]link`}
                    {...register(`materials.${i}.link`)}
                    type="text"
                    className={`form-control-db ${
                      errors.materials?.[i]?.link ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.materials?.[i]?.link?.message}
                  </div>
                </div>

                <div className="lg:col-span-5 form-group relative">
                  <label className="mb-1 text-xs text-login-gray-dark">
                    Deskripsi <span className="text-red-600">*</span>
                  </label>
                  <textarea
                    name={`materials[${i}]description`}
                    {...register(`materials.${i}.description`)}
                    type="text"
                    className={`form-control-db ${
                      errors.materials?.[i]?.description ? "is-invalid" : ""
                    } w-full h-28 text-sm focus:outline-none resize-none`}
                  />
                  <div className="invalid-feedback">
                    {errors.materials?.[i]?.description?.message}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {isCreateMode && (
            <>
              <button
                onClick={() => removeItem()}
                type="button"
                className="mr-4 mt-2 lg:mt-0 w-full lg:w-40 h-10 py-2 text-white bg-red-400 rounded-lg duration-200 ease-in-out hover:shadow-lg"
              >
                Remove
              </button>
              <button
                onClick={() => addItem()}
                type="button"
                className="mt-2 lg:mt-0 w-full lg:w-40 h-10 py-2 text-white bg-green-400 rounded-lg duration-200 ease-in-out hover:shadow-lg"
              >
                Add More
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default CreateCourseMaterial;
