import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack, Button } from "components";
import { imageicon } from "assets";

function CreateLaunchpad() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Judul tidak boleh kosong"),
    description: Yup.string().required("Deskripsi tidak boleh kosong"),
    type: Yup.string().required("Tipe tidak boleh kosong"),
    dueDate: Yup.string().required("Due date tidak boleh kosong"),
    startTime: Yup.string().required("Start time tidak boleh kosong"),
    endTime: Yup.string().required("End time tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      description: "",
      type: "",
      dueDate: "",
      startTime: "",
      endTime: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editLaunchpad"));
      setImage(data?.image);
      let defaults = {
        title: data?.title,
        description: data?.description,
        type: data?.type,
        dueDate: data?.dueDate,
        startTime: data?.startTime,
        endTime: data?.endTime,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  // Handle image preview
  const fileInput = useRef();
  const [image, setImage] = useState("");

  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      setImage(URL.createObjectURL(fileInput.current.files[0]));
    }
  };

  const onSubmit = (e) => {
    return isCreateMode ? createLaunchpad(e) : editLaunchpad(id, e);
  };

  // Create launchpad
  const createLaunchpad = (e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("flag", "project");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data;
          return UserService.createLaunchpad(
            e.title,
            e.description,
            e.type,
            e.dueDate,
            e.startTime,
            e.endTime,
            imageUri
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Launchpad berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.createLaunchpad(
        e.title,
        e.description,
        e.type,
        e.dueDate,
        e.startTime,
        e.endTime,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Launchpad berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  // Edit launchpad
  const editLaunchpad = (id, e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("flag", "project");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data;
          return UserService.updateLaunchpad(
            id,
            e.title,
            e.description,
            e.type,
            e.dueDate,
            e.startTime,
            e.endTime,
            imageUri
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit launchpad berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("editLaunchpad");
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.updateLaunchpad(
        id,
        e.title,
        e.description,
        e.type,
        e.dueDate,
        e.startTime,
        e.endTime,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit launchpad berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("editLaunchpad");
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <Button text="Submit" />
          </div>
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">Launchpad Image</p>
            <label
              htmlFor="image"
              className={`${
                image ? "max-w-xs md:max-w-lg h-32 md:h-64" : "w-60 h-32"
              }`}
            >
              {image ? (
                <img
                  src={image}
                  alt="preview"
                  className="max-w-xs md:max-w-lg h-32 md:h-64 rounded-lg object-scale-down"
                />
              ) : (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept=".jpg, .jpeg, .png"
              ref={fileInput}
              onChange={handleImage}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11">
            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Judul <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Ketik judul.."
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Tipe <span className="text-red-600">*</span>
              </p>
              <input
                name="type"
                type="text"
                {...register("type")}
                className={`form-control-db ${errors.type ? "is-invalid" : ""}`}
                placeholder="Ketik tipe.."
              />
              <div className="invalid-feedback">{errors.type?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Due Date <span className="text-red-600">*</span>
              </p>
              <input
                name="dueDate"
                type="date"
                min={new Date().toISOString().substring(0, 10)}
                {...register("dueDate")}
                className={`form-control-db ${
                  errors.dueDate ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">{errors.dueDate?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Start Time <span className="text-red-600">*</span>
              </p>
              <input
                name="startTime"
                type="time"
                {...register("startTime")}
                className={`form-control-db ${
                  errors.startTime ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.startTime?.message}
              </div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                End Time <span className="text-red-600">*</span>
              </p>
              <input
                name="endTime"
                type="time"
                {...register("endTime")}
                className={`form-control-db ${
                  errors.endTime ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">{errors.endTime?.message}</div>
            </div>

            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Deskripsi <span className="text-red-600">*</span>
              </p>
              <textarea
                id="description"
                name="description"
                {...register("description")}
                className={`form-control-db ${
                  errors.description ? "is-invalid" : ""
                } w-full h-36 text-sm focus:outline-none resize-none`}
                placeholder="Ketik deskripsi.."
              />
              <div className="invalid-feedback">
                {errors.description?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateLaunchpad;
