// Images
export { default as logo } from "assets/images/logo.png";
export { default as bglogin } from "assets/images/bg-login.png";
export { default as notfound } from "assets/images/notfound.png";
export { default as noimage } from "assets/images/no-image.png";

// Icons
export { default as deleteicon } from "assets/icons/delete.svg";
export { default as editicon } from "assets/icons/edit.svg";
export { default as imageicon } from "assets/icons/imageicon.svg";
export { default as filtericon } from "assets/icons/filtericon.svg";
export { default as searchicon } from "assets/icons/searchicon.svg";
export { default as emailicon } from "assets/icons/emailicon.svg";
export { default as passwordicon } from "assets/icons/passwordicon.svg";
export { default as menuicon } from "assets/icons/menuicon.svg";
export { default as closeicon } from "assets/icons/closeicon.svg";
export { default as notification } from "assets/icons/notification.svg";
export { default as hidepwicon } from "assets/icons/hidepwicon.svg";
export { default as showpwicon } from "assets/icons/showpwicon.svg";
export { default as profileicon } from "assets/icons/profileicon.svg";
export { default as announcement } from "assets/icons/announcement.svg";
export { default as backicon } from "assets/icons/backicon.svg";
export { default as moreicon } from "assets/icons/sidebar/moreicon.svg";
export { default as lessicon } from "assets/icons/sidebar/lessicon.svg";
export { default as usericon } from "assets/icons/sidebar/usericon.svg";
export { default as projecticon } from "assets/icons/sidebar/projecticon.svg";
export { default as activityicon } from "assets/icons/sidebar/activityicon.svg";
export { default as financeicon } from "assets/icons/sidebar/financeicon.svg";
export { default as settingicon } from "assets/icons/sidebar/settingicon.svg";
export { default as scheduleicon } from "assets/icons/sidebar/scheduleicon.svg";
export { default as courseicon } from "assets/icons/sidebar/courseicon.svg";
export { default as surveyicon } from "assets/icons/sidebar/surveyicon.svg";
export { default as launchpadicon } from "assets/icons/sidebar/launchpadicon.svg";
export { default as forumicon } from "assets/icons/sidebar/forumicon.svg";
export { default as transactionicon } from "assets/icons/sidebar/transactionicon.svg";