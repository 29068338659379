import { useNavigate } from "react-router-dom";
import { notfound } from "assets";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="mt-8 flex flex-col items-center justify-center bg-pk-bg">
      <img src={notfound} alt="not found" />
      <div className="text-center">
        <p className="text-xl md:text-3xl font-bold">Halaman tidak ditemukan</p>
        <p
          onClick={() => navigate(-1)}
          className="mt-4 text-sm md:text-base text-pk-default hover:text-pk-gray-dark hover:cursor-pointer"
        >
          Kembali ke halaman sebelumnya
        </p>
      </div>
    </div>
  );
}

export default NotFound;
