import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";

function CreateForum() {
  const { id } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Isi komentar tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      comment: "",
    },
  });

  const onSubmit = (e) => {
    return UserService.createForumComment(id, e.comment)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Forum Comment berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          <div className="grid grid-cols-1">
            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Forum Comment <span className="text-red-600">*</span>
              </p>
              <textarea
                id="comment"
                name="comment"
                {...register("comment")}
                className={`form-control-db ${
                  errors.comment ? "is-invalid" : ""
                } w-full h-36 text-sm focus:outline-none resize-none`}
                placeholder="Ketik isi komentar.."
              />
              <div className="invalid-feedback">{errors.comment?.message}</div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateForum;
