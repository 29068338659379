import { Dropdown } from "@nextui-org/react";

function UserTypeDropdown({ userTypeValue, userType, setUserType }) {
  const UserTypeMap = {
    blogger: "Blogger",
    media: "Media",
    ALL: "Semua Kategori",
  };

  const userTypeDatas = Object.entries(UserTypeMap);

  return (
    <Dropdown>
      <Dropdown.Button flat>{UserTypeMap[userTypeValue]}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={userType}
        onSelectionChange={setUserType}
      >
        {userTypeDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserTypeDropdown;
