import DataTable from "react-data-table-component";
import { ShortDateFormat, Currency } from "utils/helper";

function Table({ data, loading }) {
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.insentive_id,
      width: "70px",
      wrap: true,
    },
    {
      name: "Tanggal",
      selector: (row) => ShortDateFormat(row.created_at),
      width: "110px",
      wrap: true,
    },
    {
      name: "Nama",
      selector: (row) => row.company_name,
      width: "120px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.company_email,
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Kredit",
      selector: (row) => Currency(row.credit),
      width: "125px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Debit",
      selector: (row) => Currency(row.debit),
      width: "125px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Saldo",
      selector: (row) => Currency(row.balance),
      width: "125px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row.remarks,
      wrap: true,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
