import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, ButtonBack, Alert } from "components";
import UserService from "services/user.service";

function Course() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);

  const fetchCourse = async (page) => {
    try {
      setLoading(true);

      const response = await UserService.getCourse(page, perPage);

      setData(response.data.data.courses);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.courses);
    }
  };

  const handlePageChange = (page) => {
    fetchCourse(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);

      const response = await UserService.getCourse(page, newPerPage);

      setData(response.data.data.courses);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.courses);
    }
  };

  useEffect(() => {
    fetchCourse(1);
  }, [refresh]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Course List"
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>

      <span className="mx-1">-</span>

      <button
        className="link text-red-600 font-bold underline focus:outline-none"
        onClick={(e) => handleDelete(e, row.courseId)}
      >
        Delete
      </button>
    </div>
  );

  const Detail = (row) => (
    <button
      className="link text-pk-primary font-bold underline"
      onClick={() => goCourseTask(row)}
    >
      Lihat Detail
    </button>
  );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.courseId,
      width: "70px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      grow: 2,
      format: (row) =>
        `${
          row.title.length > 100 ? row.title.slice(0, 100) + "..." : row.title
        }`,
    },
    {
      name: "Cat Id",
      selector: (row) => row.categoryId,
      wrap: true,
      width: "75px",
    },
    {
      name: "Redeem Point",
      selector: (row) => row.price,
      wrap: true,
      grow: 0.9,
      format: (row) => row.price.toLocaleString("id"),
    },
    {
      name: "Followers",
      selector: (row) => row.followers,
      wrap: true,
      grow: 0.8,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      wrap: true,
      width: "77px",
    },
    {
      name: "Reward Point",
      selector: (row) => row.rewardPoints,
      wrap: true,
      grow: 0.9,
      format: (row) => row.rewardPoints.toLocaleString("id"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      grow: 0.8,
      cell: (row) => <Action {...row} />,
    },
    {
      name: "Detail",
      selector: (row) => row.courseId,
      wrap: true,
      grow: 0.8,
      cell: (row) => <Detail {...row} />,
    },
  ];

  // Go Course Task
  const goCourseTask = (row) => {
    if (row) {
      localStorage.setItem("courseData", JSON.stringify(row));
      navigate(`/pdp/course/${row.courseId}`);
    }
  };

  // Edit Course
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editCourse", JSON.stringify(row));
      navigate(`${pathname}/${row.courseId}/edit`);
    }
  };

  // Delete Course
  const handleDelete = (e, id) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Course yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteCourse(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Course telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack url="/home" />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`/pdp/course-add`}>
            <Button text="Add New Course" />
          </Link>
        </div>
        {/* Table */}
        {TableComponent()}
      </div>
    </>
  );
}

export default Course;
