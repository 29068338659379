import { Routes, Route, Navigate } from "react-router-dom";
import { PublicRoute, ProtectedRoute, Alert, routes } from "components";
import axios from "axios";
import LoginPage from "pages/auth/Login";
import ForgotPassword from "pages/auth/Forgot";
import ResetPassword from "pages/auth/Reset";
import NotFound from "pages/auth/NotFound";
import Home from "pages/Home";
import { getLayout } from "components/layouts";
import jwtDecode from "jwt-decode";

const App = () => {
  const responseSuccessHandler = (response) => {
    return response;
  };

  const responseErrorHandler = (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message.includes("token" || "jwt")
    ) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  };

  axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  );

  const checkAuth = () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.clear();
          window.location.href = "/login";
          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  };

  const isAuthenticated = checkAuth();
  let userData = JSON.parse(localStorage.getItem("user"));

  return (
    <Routes>
      <Route
        element={
          <PublicRoute
            redirectPath="/home"
            isAllowed={isAuthenticated && userData?.role !== undefined}
          />
        }
      >
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-reset/:token" element={<ResetPassword />} />
      </Route>
      <Route
        element={
          <ProtectedRoute isAllowed={isAuthenticated && userData?.role} />
        }
      >
        <Route path="home" element={getLayout(<Home />)} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              isAuthenticated &&
              (userData?.role === "SUPERADMIN" ||
                userData?.role === "ADMIN" ||
                userData?.role === "FINANCE" ||
                userData?.role === "PROJECT_MANAGER" ||
                userData?.role === "PROGRAM_MANAGER")
            }
          />
        }
      >
        {routes.map(({ element, path }, i) => (
          <Route path={`${path}`} element={getLayout(element)} key={i} />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
