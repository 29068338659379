import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack, Alert } from "components";
import UserService from "services/user.service";
import { Card, Loading, Avatar, Link, Switch, Button } from "@nextui-org/react";
import { logo } from "assets";
import { DateFormat, Currency } from "utils/helper";
import Table from "./components/Table";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";

function UserMediaDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [tierList, setTierList] = useState([]);
  const [mediaTier, setMediaTier] = useState();
  const [tierId, setTierId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getMediaDetail(id);
        setData(response.data.data);
        setTierId(response.data.data.media_tier);
        setMediaTier(response.data.data.media_tier);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    const fetchTier = async () => {
      setLoading(true);
      try {
        const response = await UserService.getTier(1, 10, "media");
        setTierList(response.data.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setTierList(err.response);
      }
    };
    fetchDetail();
    fetchTier();
  }, [id, refresh]);

  const [reflist, setReflist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const fetchReferralList = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getMediaReferral(page, limit, "", id);
      setReflist(response.data.data.medias);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setReflist(err.response.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchReferralList(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getMediaReferral(
        page,
        newPerPage,
        "",
        id
      );
      setReflist(response.data.data.medias);
      setLimit(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setReflist(err.response.data.data);
    }
  };

  useEffect(() => {
    fetchReferralList(1);
  }, []);

  const [isMediaPartner, setIsMediaPartner] = useState();
  useEffect(() => {
    if (data?.is_media_partner) {
      setIsMediaPartner(true);
    } else {
      setIsMediaPartner(false);
    }
  }, [data?.is_media_partner]);

  const changeTier = (e) => {
    setTierId(parseInt(e.target.value));
  };

  const handleMediaPartner = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${isMediaPartner ? "menghapus" : "menjadikan"} ${
        data?.company_name
      } sebagai partner.`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.updateMediaPartner(id, !isMediaPartner).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${
                  isMediaPartner ? "menghapus" : "menjadikan"
                } ${data?.company_name} sebagai partner.`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      } else if (result.dismiss) {
        setRefresh(refresh + 1);
      }
    });
  };

  const handleUpdateTier = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan mengubah media tier dari ${mediaTier} menjadi ${tierId}`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.updateMediaTier(id, tierId).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil mengubah media tier dari ${mediaTier} menjadi ${tierId}`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      } else if (result.dismiss) {
        setRefresh(refresh + 1);
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[604px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[604px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <>
              <Card.Header>
                <Avatar
                  src={
                    data?.company_profile_picture
                      ? data.company_profile_picture
                      : logo
                  }
                  css={{ size: "$20", mt: 16, ml: 20 }}
                  color="gradient"
                  bordered
                />
              </Card.Header>
              <Card.Body>
                <div className="lg:px-5 flex flex-col lg:flex-row">
                  <ul className="w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Id Media</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.id}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nama Media</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.company_name}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Email Media</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.company_email}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Telepon</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.company_phone_number
                              ? data?.company_phone_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Email PIC</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.pic_email ? data?.pic_email : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Telepon PIC</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.pic_phone_number
                              ? data.pic_phone_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Jenis Media</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-letter:uppercase">
                            {data?.type_media_name
                              ? data?.type_media_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Cakupan Media</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-letter:uppercase">
                            {data?.segmentation_media_name
                              ? data?.segmentation_media_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kategori Media</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-letter:uppercase">
                            {data?.category_media_name
                              ? data?.category_media_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Status Verifikasi</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.is_verified_pic_email === 1 ? (
                              <div className="text-green-500 flex items-center space-x-1">
                                <span>Sudah terverifikasi</span>
                                <HiCheckCircle size={20} />
                              </div>
                            ) : (
                              <div className="text-red-500 flex items-center space-x-1">
                                <span>Belum terverifikasi</span>
                                <HiXCircle size={20} />
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Website</span>
                        </div>
                        <div className="w-1/2">
                          {data?.company_website ? (
                            <Link
                              isExternal
                              color="primary"
                              href={data.company_website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.company_website}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Rate Korporasi</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {Currency(data?.rate_publisher_brand_content)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Rate UMKM</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {Currency(data?.rate_sme)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Provinsi</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.province_name ? data?.province_name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-semibold">Kota</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.city_name ? data?.city_name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Alamat</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.address ? data?.address : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Bank</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bank_account_name
                              ? data?.bank_account_name
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Rekening</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bank_account_number
                              ? data?.bank_account_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Total Balance</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {Currency(data?.total_balance)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Dibuat pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.created_at
                              ? DateFormat(data?.created_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Diperbarui pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_at
                              ? DateFormat(data?.updated_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Login terakhir pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.last_login
                              ? DateFormat(data?.last_login)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Media Partner</span>
                        </div>
                        <Switch
                          size="sm"
                          onChange={() => handleMediaPartner()}
                          initialChecked={isMediaPartner}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Media Tier</span>
                        </div>
                        <div className="flex space-x-4">
                          <select
                            name="media_tier"
                            className="w-full h-auto rounded-xl border-2 border-pk-border px-4 py-1 text-sm"
                            value={tierId}
                            onChange={(e) =>
                              tierId !== e.target.value && changeTier(e)
                            }
                          >
                            <option value="0">Pilih Media Tier</option>
                            {tierList?.length > 0 &&
                              tierList.map((x, i) => (
                                <option key={i} value={x.tier}>
                                  {x.tier}
                                </option>
                              ))}
                          </select>
                          {tierId !== mediaTier && tierId > 0 && (
                            <Button
                              type="submit"
                              onClick={handleUpdateTier}
                              size="sm"
                              auto
                              css={{
                                "background-color": "#FF9D25",
                                color: "$black",
                              }}
                            >
                              Ubah Tier
                            </Button>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card.Body>
              <Table
                data={reflist}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export default UserMediaDetail;
