import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, ButtonBack } from "components";
import UserService from "services/user.service";

function SurveyQuestion() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchQuestion = async () => {
    try {
      setLoading(true);

      const response = await UserService.getSurveyQuestion();

      setData(response.data.data.questions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.questions);
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Survey Question List"
        columns={columns}
        data={data}
        progressPending={loading}
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Options = (row) => (
    <div className="flex flex-col">
      {row.answerOptions.map((data, id) => (
        <span key={id}>{data.answer}</span>
      ))}
    </div>
  );

  const AnswerType = (row) => {
    switch (row.answerType) {
      case 0:
        return "Radio Button";
      case 1:
        return "Combo Box";
      default:
        return "-";
    }
  };

  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>
    </div>
  );

  const columns = [
    {
      name: "Question Id",
      selector: (row) => row.questionId,
      width: "110px",
    },
    {
      name: "Question",
      selector: (row) => row.question,
      wrap: true,
      grow: 4,
    },
    {
      name: "Answer Options",
      selector: (row) => row.answerOptions,
      wrap: true,
      grow: 1.5,
      cell: (row) => <Options {...row} />,
    },
    {
      name: "Order",
      selector: (row) => row.order,
      width: "80px",
    },
    {
      name: "Answer Type",
      selector: (row) => row.answerType,
      width: "120px",
      cell: (row) => <AnswerType {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  // Edit Survey Question
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editSurveyQuestion", JSON.stringify(row));
      navigate(`${pathname}/${row.questionId}/edit`);
    }
  };

  return (
    <>
      <ButtonBack url="/home" />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`${pathname}/add`}>
            <Button text="Add New Survey Question" />
          </Link>
        </div>
        {TableComponent()}
      </div>
    </>
  );
}

export default SurveyQuestion;
