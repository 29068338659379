/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate } from "react-router-dom";
import { logo } from "assets";
import { Button } from "@nextui-org/react";
import { StyledSidebar, StyledLi, NavLink, Collapse } from "./styled";
import { menus } from "utils/menus";
import { useHasPermissions, useRole } from "../../utils/helper";

export default function Sidebar() {
  const navigate = useNavigate();
  const role = useRole()

  return (
    <StyledSidebar>
      <div className="relative z-40">
        <ul>
          <li className="px-4 pt-6 pb-2">
            <Button
              light
              color="primary"
              auto
              icon={<img src={logo} alt="logo penakita" className="h-8" />}
              css={{ padding: "$0" }}
              onClick={() => navigate("/home")}
            >
              <h5 className="text-white text-xl font-bold">Penakita</h5>
            </Button>
          </li>
        </ul>
        <div className="pb-8">
          {menus.map((item, i) => {
            const parentHasPermission = useHasPermissions(
              item.permissions,
              role
            )

            const childrens = item.children &&
              item.children.filter(subItem => {
                const hasPermission = useHasPermissions(
                  subItem.permissions,
                  role
                )
                if (hasPermission) {
                  return subItem
                }
              })

            return childrens?.length ? (
              <Collapse
                key={i}
                role="button"
                title={<span>{item.title}</span>}
                contentLeft={<div className="menu-icon">{item.icon}</div>}
                divider={false}
              >
                <ul>
                  {childrens.map((subItem, subIndex) => {
                    return (
                      <StyledLi key={subIndex}>
                        <NavLink to={subItem.path}><p className="flex">{subItem.title}<span className="ml-2 text-[8px] text-pk-primary">{subItem?.subTitle}</span></p></NavLink>
                      </StyledLi>
                    );
                  })}
                </ul>
              </Collapse>
            ) : parentHasPermission && !item.children ? (
              <ul key={i}>
                <StyledLi>
                  <NavLink to={item.path}>
                    <div className="flex space-x-3 items-center">
                      <div className="menu-icon">{item.icon}</div>
                      <div>{item.title}</div>
                    </div>
                  </NavLink>
                </StyledLi>
              </ul>
            ) : null;
          })}
        </div>
      </div>
    </StyledSidebar>
  );
}
