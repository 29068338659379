import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { nextUiTheme } from "utils/theme";
import App from "App";
import "assets/styles/index.css";

ReactDOM.render(
  <NextUIProvider theme={nextUiTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </NextUIProvider>,
  document.getElementById("root")
);
