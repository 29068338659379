import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { Card, Loading, Button, Link } from "@nextui-org/react";
import { Currency, ShortDateFormat, ShortDateTimeFormat } from "utils/helper";

export default function TaskDetail() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [refresh, setRefresh] = useState(1);
  const [taskData, setTaskData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssignor, setIsLoadingAssignor] = useState(false);

  // Get task detail
  useEffect(() => {
    const fetchTaskDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getTaskDetail(id);
        setTaskData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setTaskData(err.response.data.data);
      }
    };
    fetchTaskDetail();
  }, [id]);

  const [assignorData, setAssignorData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  // Get project assignor
  const fetchAssignor = async (page) => {
    setIsLoadingAssignor(true);
    try {
      const response = await UserService.getProjectAssignor(id, page, perPage);
      setAssignorData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setIsLoadingAssignor(false);
    } catch (err) {
      setIsLoadingAssignor(false);
      setAssignorData(err.response.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchAssignor(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoadingAssignor(true);
    try {
      const response = await UserService.getProjectAssignor(
        id,
        page,
        newPerPage
      );
      setAssignorData(response.data.data.data);
      setPerPage(newPerPage);
      setIsLoadingAssignor(false);
    } catch (err) {
      setIsLoadingAssignor(false);
      setAssignorData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchAssignor(1);
  }, [refresh]);

  // Status task assignor
  const Status = (row) => {
    switch (row.status) {
      case "WAITING_SUBMISSION":
        return "Menunggu submission User";
      case "SUBMITTED":
        return "Submit by User";
      case "REVIEW":
        return "On review Admin";
      case "REVISION":
        return "Revisi";
      case "UPDATED":
        return "Update by User";
      case "WAITING_PUBLISH":
        return "Menunggu publish dari User";
      case "PUBLISED":
        return "Telah dipublish User";
      case "DONE":
        return "Telah selesai";
      case "DECLINE":
        return "Telah ditolak";
      default:
        return "-";
    }
  };

  // Submission column
  const Submission = (row) => {
    if (taskData?.project?.project_category === "blogger") {
      return row.submission?.caption?.length > 100
        ? row.submission.caption.slice(0, 100) + "..."
        : row.submission.caption;
    } else if (row.submission.url !== "") {
      return (
        <Link
          color="primary"
          href={row.submission.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.submission.url.length > 25
            ? row.submission.url.slice(0, 25) + "..."
            : row.submission.url}
        </Link>
      );
    } else {
      return null;
    }
  };

  const goFeedback = (row) => {
    if (row) {
      localStorage.setItem(
        "projectCategory",
        taskData.project.project_category
      );
      navigate(`${pathname}/submission/${row.assignor_id}`);
    }
  };

  // Feedback column
  const Feedback = (row) =>
    row.status !== "WAITING_SUBMISSION" &&
    row.status !== "DECLINE" && (
      <Button size="xs" color="default" auto onClick={() => goFeedback(row)}>
        {row.status === "DONE" ? "Lihat Detail" : "Add Feedback"}
      </Button>
    );

  // Approve decline column
  const Action = (row) =>
    row.status === "SUBMITTED" && (
      <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
        <Button
          size="xs"
          color="success"
          auto
          onClick={() => handleStatus("approve", row.assignor_id)}
        >
          Approve
        </Button>
        {/* {taskData?.project?.project_category === "blogger" && (
          <Button
            size="xs"
            color="error"
            auto
            onClick={() => handleStatus("decline", row.assignor_id)}
          >
            Decline
          </Button>
        )} */}
      </div>
    );

  const columns = [
    {
      name: "Nama Task",
      selector: (row) => row.task.name,
      width: "190px",
      wrap: true,
    },
    {
      name: `${
        taskData?.project?.project_category === "blogger"
          ? "Nama Blogger"
          : "Nama Media"
      }`,
      selector: (row) => (row.blogger ? row.blogger.name : row.media.name),
      width: "160px",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
      wrap: true,
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Submission",
      grow: 1.3,
      wrap: true,
      cell: (row) => <Submission {...row} />,
    },
    {
      name: "Dibuat pada",
      selector: (row) => ShortDateFormat(row.created_at),
      width: "120px",
      wrap: true,
    },
    {
      name: "Feedback",
      selector: (row) => row.feedback,
      width: "120px",
      wrap: true,
      cell: (row) => <Feedback {...row} />,
    },
    {
      selector: (row) => row.action,
      grow: 1,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  function TableComponent() {
    return (
      <DataTable
        title="Submission"
        columns={columns}
        data={assignorData}
        progressPending={isLoadingAssignor}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }

  // Toggle status
  const handleStatus = (status, id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} submission ini.`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getAssignorStatus(status, id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} submission.`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${error.response.data.message}`,
              });
            }
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-[120px]">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!isLoading && !taskData && (
              <span className="flex items-center justify-center h-[120px] font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && taskData && (
              <div className="lg:p-2 flex flex-col lg:flex-row lg:space-x-4">
                <ul className="w-full lg:w-2/3 flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-1/5">
                        <span className="font-bold">Nama Task</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark">
                          {taskData?.name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-1/5">
                        <span className="font-bold">Kuota</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark">
                          {taskData?.current_quota} / {taskData?.quota}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-1/5">
                        <span className="font-semibold">Instruksi</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark whitespace-pre-line">
                          {taskData.description}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul className="mt-4 lg:mt-0 w-full lg:w-1/3  flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-2/5">
                        <span className="font-bold">Harga</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark">
                          {Currency(taskData?.price)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-2/5">
                        <span className="font-bold">Deadline</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark">
                          {taskData?.due_date
                            ? ShortDateTimeFormat(taskData?.due_date)
                            : null}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-2/5">
                        <span className="font-semibold">Dibuat pada</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        <span className="text-login-gray-dark">
                          {taskData?.created_at
                            ? ShortDateTimeFormat(taskData?.created_at)
                            : null}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/3 lg:w-2/5">
                        <span className="font-semibold">Gambar</span>
                      </div>
                      <div className="w-3/4 lg:w-full">
                        {taskData?.refferences !== "[]" &&
                          taskData?.refferences?.length > 0 &&
                          taskData.refferences.map((data, i) => (
                            <img
                              src={data}
                              key={i}
                              alt="task"
                              className="w-auto h-40 object-cover"
                            />
                          ))}
                        {(taskData?.refferences === "[]" ||
                          taskData?.refferences?.length < 1) && (
                          <span className="text-login-gray-dark">
                            Tidak ada
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
          <Card.Divider />
          <Card.Body>
            <div className="rounded-xl">{TableComponent()}</div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
