import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/user.service";
import { Alert, ButtonAdd } from "components";
import { Card, Text } from "@nextui-org/react";
import Table from "./components/Table"

function Announcement() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const fetchAnnouncement = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getAnnouncement(page, limit);
      setData(response.data.data.announcements);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchAnnouncement(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getAnnouncement(page, newPerPage);
      setData(response.data.data.announcements);
      setLimit(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data);
    }
  };

  useEffect(() => {
    fetchAnnouncement(1);
  }, [refresh]);

  // Add announcement
  const goAdd = () => {
    navigate(`/announcement/add`);
  };

  // Edit announcement
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("announcement", JSON.stringify(row));
      navigate(`/announcement/edit/${row.id}`);
    }
  };

  // Delete announcement
  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan announcement yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteAnnouncement(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Announcement telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Announcement List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex items-center justify-end mb-3">
            <ButtonAdd action={goAdd} text="Add Announcement" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Announcement;
