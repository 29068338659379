import { useNavigate } from "react-router-dom";
import { bglogin, emailicon } from "assets";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthService from "services/auth.service";
import { Alert } from "components";

function Forgot() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (e) => {
    AuthService.forgot(e.email).then(
      (res) => {
        if (res.data.code === 200) {
          Alert.fire({
            icon: "success",
            title: "Sukses!",
            text: "Kami telah mengirim link reset password ke email Anda.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        }
      },
      (error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      }
    );
  };

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      {/* Left side */}
      <div className="relative bg-gradient-to-bl from-login-from via-login-via to-login-to hidden lg:block w-full h-screen">
        <img
          src={bglogin}
          alt="background"
          className="absolute left-0 bottom-0"
        />
        <div className="absolute mx-auto top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2 text-white">
          <p className="text-4xl font-bold">Penakita Backoffice</p>
          <p className="text-lg">Platform Profesional Untuk Penulis</p>
        </div>
      </div>
      {/* Right side */}
      <div className="flex items-center justify-center w-full md:w-2/3 h-screen md:mx-auto bg-white">
        <div className="w-80 h-100 px-2">
          <h1 className="mt-12 text-xl md:text-3xl text-login-black font-bold">
            Forgot Password
          </h1>
          <p className="mt-1 text-login-gray-dark">
            Link update password akan dikirimkan ke email yang anda input.
          </p>

          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group relative">
              <input
                name="email"
                type="text"
                {...register("email")}
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="Email"
              />
              <img
                src={emailicon}
                alt="email"
                className="absolute flex inset-y-0 items-center left-0 p-4"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="w-full h-14 bg-pk-primary hover:shadow-lg text-white text-sm rounded-full p-3 mt-8 ease-in-out duration-200 focus:outline-none"
            >
              Submit
            </button>
          </form>

          {/* Forgot link */}
          <p className="mt-8 text-center">
            <a
              href="/login"
              className="text-black text-sm underline hover:text-pk-default"
            >
              Back to Login
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Forgot;
