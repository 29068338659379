import { Link } from "@nextui-org/react";

export default function footer() {
  return (
    <footer className="flex space-x-1 px-4 py-6 border-t border-gray-200 font-light">
      <p className="text-pk-gray-dark">
        Copyright &copy; {new Date().getFullYear()}
      </p>
      <p>
        <Link
          color="primary"
          href="https://penakita.id"
          target="_blank"
          rel="noopener noreferrer"
        >
          Penakita
        </Link>
      </p>
    </footer>
  );
}
