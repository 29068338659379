import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { Card, Input, Grid, Button } from "@nextui-org/react";
import { NumericFormat } from "react-number-format";

function CreateTier() {
  const { tier, type } = useParams();
  const isCreateMode = !tier;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    tier: Yup.string().required("Tier tidak boleh kosong"),
    tier_name: Yup.string().required("Nama Tier tidak boleh kosong"),
    price: Yup.number()
      .required("Nominal tidak boleh kosong")
      .positive("Format nominal salah")
      .integer("Format nominal salah")
      .typeError("Nominal tidak boleh kosong"),
    type: Yup.string().required("Kategori tidak boleh kosong"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      tier: "",
      tier_name: "",
      price: "",
      type: "",
      is_special_tier: "",
    },
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isCreateMode) {
      UserService.getTierDetail(tier, type).then(
        (response) => {
          let res = response.data.data;
          let defaults = {
            tier: res?.tier,
            tier_name: res?.name,
            price: res?.price,
            type: res?.type,
            is_special_tier: res?.is_special_tier,
          };
          reset(defaults);
        },
        (error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        }
      );
    }
  }, [tier, type, isCreateMode, reset]);

  const onSubmit = (data) => {
    return isCreateMode
      ? createTier(data)
      : editTier(tier, data.tier_name, type, data.price, data.is_special_tier);
  };

  const createTier = ({ tier, tier_name, price, type }) => {
    return UserService.createTier(tier, tier_name, price, type)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Tier berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  const editTier = (tier, tier_name, type, price, is_special_tier) => {
    return UserService.updateTier(tier, tier_name, type, price, is_special_tier)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit tier berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body css={{ p: "$10" }}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid xs={12} md={4} css={{ mb: "$10" }}>
                  <Input
                    name="tier"
                    type="number"
                    min={1}
                    {...register("tier")}
                    bordered
                    fullWidth
                    label="Tier"
                    placeholder="Contoh: 1"
                    status={errors.tier ? "error" : "default"}
                    helperColor={errors.tier ? "error" : "default"}
                    helperText={errors.tier?.message}
                    disabled={!isCreateMode}
                  />
                </Grid>

                <Grid xs={12} md={4} css={{ mb: "$10" }}>
                  <Input
                    name="tier_name"
                    type="text"
                    {...register("tier_name")}
                    bordered
                    fullWidth
                    label="Nama Tier"
                    placeholder="Contoh: Nano (1k - 5k Followers)"
                    status={errors.tier_name ? "error" : "default"}
                    helperColor={errors.tier_name ? "error" : "default"}
                    helperText={errors.tier_name?.message}
                  />
                </Grid>

                <Grid xs={12} md={4} css={{ mb: "$10" }}>
                  <p className={`mb-1 ml-1 text-sm ${errors.price ? "text-pk-red" : ""}`}>
                    Nominal
                  </p>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field: { onChange, onBlur, name, value } }) => (
                      <NumericFormat
                        placeholder="Contoh: Rp 100.000"
                        className={`form-control-db rounded-xl h-10 border-2 border-zinc-300 ${
                          errors.price ? "is-invalid" : ""
                        }`}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        allowNegative={false}
                        onValueChange={(values) => onChange(values.floatValue)}
                        prefix="Rp "
                        name={name}
                        value={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <div className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                    {errors.price ? "Nominal tidak sesuai" : ""}
                  </div>
                </Grid>

                <Grid xs={12} md={4} css={{ mb: "$10" }}>
                  <div className="w-full">
                    <label
                      className={`text-sm pl-1 ${
                        errors.type ? "text-pk-red" : ""
                      }`}
                    >
                      Kategori
                    </label>
                    <select
                      className={`${
                        !isCreateMode && "cursor-not-allowed"
                      } w-full h-11 rounded-xl border-2 border-pk-border py-[6px] px-4 mt-1 text-sm`}
                      name="type"
                      {...register("type")}
                      disabled={!isCreateMode}
                    >
                      <option value="">Pilih Kategori</option>
                      <option value="blogger">Blogger</option>
                      <option value="media">Media</option>
                    </select>
                    <span className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                      {errors.type?.message}
                    </span>
                  </div>
                </Grid>

                {!isCreateMode && (
                  <Grid xs={12} md={4} css={{ mb: "$10" }}>
                    <div className="w-full">
                      <label
                        className={`text-sm pl-1 ${
                          errors.is_special_tier ? "text-pk-red" : ""
                        }`}
                      >
                        Special Tier
                      </label>
                      <select
                        className="w-full h-11 rounded-xl border-2 border-pk-border py-[6px] px-4 mt-1 text-sm"
                        name="is_special_tier"
                        {...register("is_special_tier")}
                      >
                        <option value="" disabled>
                          Apakah Special Tier?
                        </option>
                        <option value="1">Ya</option>
                        <option value="0">Tidak</option>
                      </select>
                      <span className="text-pk-red text-[10px] mt-[2px] ml-[10px]">
                        {errors.is_special_tier?.message}
                      </span>
                    </div>
                  </Grid>
                )}

                <Button
                  type="submit"
                  size="md"
                  auto
                  css={{ "background-color": "#FF9D25", color: "$black" }}
                >
                  Submit
                </Button>
              </form>
            </FormProvider>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default CreateTier;
