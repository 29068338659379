import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading } from "@nextui-org/react";
import Table from "./components/Table";

function UserMediaBalance() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getMediaBalance(id);
        setData(response.data.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[604px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[604px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <Card.Body>
              <p className="px-2 pb-2 text-lg font-bold">
                Balance History Media List
              </p>
              <Table data={data} loading={isLoading} />
            </Card.Body>
          )}
        </Card>
      </div>
    </>
  );
}

export default UserMediaBalance;
