import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading, Modal, useModal } from "@nextui-org/react";
import { DateFormat, Currency } from "utils/helper";
import { StatusTransactionMap } from "constants";

export default function ClientTransactionDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setVisible, bindings } = useModal();

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getClientTransactionDetail(id);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  const projectStatus = (status) => {
    return StatusTransactionMap[status];
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-96">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!data && (
              <span className="flex items-center justify-center h-96 font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && data && (
              <div className="lg:p-5 flex flex-col lg:flex-row">
                <ul className="w-full lg:w-1/2 flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Id Transaksi</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">{data?.id}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Nama Client</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.client?.company_name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Tipe</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data.type}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Status</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {projectStatus(data?.status)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Total Budget</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.total_budget)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Total Fee</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.total_fee)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Total Tax</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.total_tax)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Sub Total</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.sub_total)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold break-words">
                          Bukti Pembayaran
                        </span>
                      </div>
                      <div className="w-1/2">
                        <Card isPressable onPress={() => setVisible(true)}>
                          <Card.Image
                            src={data?.payment_image}
                            objectFit="cover"
                            width="100%"
                            height={208}
                            alt="payment"
                          />
                        </Card>
                        <Modal
                          scroll
                          noPadding
                          width="50%"
                          closeButton
                          aria-labelledby="modal-image"
                          {...bindings}
                        >
                          <Modal.Body className="pt-6">
                            {data?.payment_image && (
                              <img
                                src={data?.payment_image}
                                alt="payment"
                                className="w-full object-cover"
                              />
                            )}
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Nomor Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.projectNumber}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Nama Project</span>
                      </div>
                      <div className="w-1/2 lg:w-3/4">
                        <span className="text-login-gray-dark break-words">
                          {data?.project?.name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Tipe Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.type}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Status Project</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.status}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Kuota</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.quota}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Periode Mulai</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.startPeriod &&
                            DateFormat(data?.project?.startPeriod)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Periode Selesai</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project?.endPeriod &&
                            DateFormat(data?.project?.endPeriod)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Dibuat pada</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_at && DateFormat(data?.created_at)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/4">
                        <span className="font-bold">Diperbarui pada</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_at && DateFormat(data?.updated_at)}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
