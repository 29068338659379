import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/user.service";
import { Card, Text, Button, Loading } from "@nextui-org/react";
import { HiOutlineDownload } from "react-icons/hi";
import { Alert, ButtonAdd, SearchBar } from "components";
import Table from "./components/Table";

function UserInternal() {
  const navigate = useNavigate();
  const goAdd = () => {
    navigate("/create-user");
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchUsers = async (page) => {
    setLoading(true);
    setIsDisabled(true);
    try {
      const response = await UserService.getUserAdmin(page, perPage, keyword);
      setIsDisabled(false);
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setIsDisabled(true);
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getUserAdmin(
        page,
        newPerPage,
        keyword
      );
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchUsers(1);
  }, [keyword, refresh]);

  // Delete user
  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan user yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteUserAdmin(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "User telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  const handleExport = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengexport user internal list ke excel.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getExportAdmin(keyword).then(
          (response) => {
            if (response) {
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Berhasil download user internal list",
              });
              window.location.href = response.data.data.url;
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          User Internal List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="mb-3 flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <SearchBar
                setKeyword={setKeyword}
                placeholder="Cari Nama/Email/No Hp"
              />
              <Button
                size="md"
                color="default"
                auto
                iconRight={!loading && <HiOutlineDownload />}
                onClick={() => handleExport()}
                disabled={isDisabled}
              >
                {loading && (
                  <Loading type="spinner" color="currentColor" size="sm" />
                )}
                {!loading && "Export to excel"}
              </Button>
            </div>
            <ButtonAdd action={goAdd} text="Add New User" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            refresh={refresh}
            setRefresh={setRefresh}
            handleDelete={handleDelete}
            navigate={navigate}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default UserInternal;
