import { Grid, Card, Col, Text } from "@nextui-org/react";
import { Currency } from "utils/helper";

function StatusBar({ totalRequest, totalNotPaid, totalPaid }) {
  return (
    <Grid.Container gap={1} css={{ padding: 0 }}>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Request
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ mt: "0.25rem" }}
              >
                {totalRequest}
                {!totalRequest && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={5}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Belum Dibayar
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ mt: "0.25rem" }}
              >
                {Currency(totalNotPaid)}
                {!totalNotPaid && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={5}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Sudah Dibayar
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ mt: "0.25rem" }}
              >
                {Currency(totalPaid)}
                {!totalPaid && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
    </Grid.Container>
  );
}

export default StatusBar;
