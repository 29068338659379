export const StatusMap = {
  REQUESTED: "Request by User",
  INVITED: "Invite by Admin",
  SUBMITTED: "Submit by User",
  USER_APPROVED: "Approve by User",
  ADMIN_APPROVED: "Approve by Admin",
  DECLINE: "Telah ditolak",
  CLOSED: "Telah ditutup",
  ENDED: "Telah berakhir",
};

export const ProjectStatusMap = {
  OPEN: "OPEN",
  DRAFT: "DRAFT",
  CLOSE: "CLOSE",
  ONGOING: "ONGOING",
  REJECTED: "REJECTED",
};

export const ProjectTypeMap = {
  PRIVATE: "Tunjuk Langsung",
  PUBLIC: "Pitch",
};

export const MediaTypeMap = {
  BLOGGER: "Blog",
  INSTAGRAM: "Instagram",
  TWITTER: "Twitter",
  ONLINE: "Online",
  CETAK: "Cetak",
  MAJALAH: "Majalah",
};

export const StatusTransactionMap = {
  WAITING_FOR_PAYMENT: "Menunggu pembayaran",
  ONCHECKING: "Tahap pengecekan",
  ACCEPTED: "Telah diterima",
  REJECTED: "Telah ditolak",
};

export const WithdrawStatusMap = {
  PAID: "Sudah dibayar",
  UNPAID: "Tidak dibayar",
  REJECTED: "Telah ditolak",
  PROCESSING: "Dalam proses",
  WAITING_FOR_PAYMENT: "Menunggu pembayaran",
};

export const VoucherStatusMap = {
  ACTIVE: "Aktif",
  INACTIVE: "Tidak Aktif",
};

export const ANSWER_TYPE = [
  {
    value: 0,
    label: "Radio Button",
  },
  {
    value: 1,
    label: "Combo Box",
  },
];

export const DURATION_TASK = [
  {
    value: 86400,
    label: "1 Hari",
  },
  {
    value: 172800,
    label: "2 Hari",
  },
  {
    value: 259200,
    label: "3 Hari",
  },
  {
    value: 345600,
    label: "4 Hari",
  },
  {
    value: 432000,
    label: "5 Hari",
  },
  {
    value: 518400,
    label: "6 Hari",
  },
  {
    value: 604800,
    label: "7 Hari",
  },
];
