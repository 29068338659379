import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserService from "services/user.service";
import { Button, Alert, Loader, ButtonBack } from "components";
import { imageicon } from "assets";
import Select from "react-select";
import { parse, format, addDays } from "date-fns";
import { NumericFormat } from "react-number-format";

function CreateProject() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreateMode = !id;
  const [loading, setLoading] = useState(true);
  const projectState = JSON.parse(localStorage.getItem("projectPrivate"));
  const isNotCompleted = projectState && Object.keys(projectState).length > 0;

  const [image, setImage] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectCategory, setProjectCategory] = useState("");

  // get province list
  const [provinceList, setProvinceList] = useState("");
  useEffect(() => {
    UserService.getProvince().then(
      (response) => {
        setProvinceList(response.data.data.data);
      },
      (error) => {
        const _content = error.data;
        setProvinceList(_content);
      }
    );
  }, []);

  // select dropdown province
  let provinceDropdown;
  if (provinceList.length > 0) {
    provinceDropdown = provinceList?.map((data) => {
      return { value: data.id, label: data.name };
    });
  }

  const [provinceId, setProvinceId] = useState("");
  const [provinceName, setProvinceName] = useState(projectState?.province_name);
  // handle change province
  const changeProvince = (e) => {
    setProvinceId(e.value);
    setProvinceName(e.label);
  };

  // get city list
  const [cityList, setCityList] = useState(null);
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState(projectState?.city_name);
  useEffect(() => {
    if (provinceId) {
      UserService.getCity(provinceId).then(
        (response) => {
          setCityList(response.data.data.data);
        },
        (error) => {
          const _content = error.data;
          setCityList(_content);
        }
      );
    }
  }, [provinceId]);

  // select dropdown city
  let cityDropdown;
  if (cityList !== null) {
    cityDropdown = cityList?.map((data) => {
      return { value: data.city_id, label: data.name };
    });
  }
  // handle change city
  const changeCity = (e) => {
    setCityId(e.value);
    setCityName(e.label);
  };

  // get industry category
  const [industry, setIndustry] = useState("");
  const [industryCategories, setIndustryCategories] = useState([]);
  useEffect(() => {
    const fetchIndustry = async () => {
      setLoading(true);
      try {
        const response = await UserService.getIndustryCategory();
        setIndustryCategories(response.data.data);
      } catch (err) {
        setIndustryCategories(err.response.data);
      }
    };
    fetchIndustry();
  }, []);

  const urlRegex =
    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama project tidak boleh kosong"),
    price_per_task: Yup.number()
      .required("Harga per task tidak boleh kosong")
      .positive("Format harga per task salah")
      .integer("Format harga per task salah")
      .typeError("Harga per task tidak boleh kosong"),
    type: Yup.string().required("Tipe project tidak boleh kosong"),
    category: Yup.string().required("Kategori tidak boleh kosong"),
    sub_type: Yup.string().required("Jenis project tidak boleh kosong"),
    media_type: Yup.string().required("Jenis media tidak boleh kosong"),
    classification: Yup.string().required("Classification tidak boleh kosong"),
    start_period: Yup.string().required("Periode mulai tidak boleh kosong"),
    end_period: Yup.string().required("Periode berakhir tidak boleh kosong"),
    status: Yup.string().required("Status project tidak boleh kosong"),
    industry: Yup.string().required("Industri tidak boleh kosong"),
    quota: Yup.number()
      .required("Kuota tidak boleh kosong")
      .positive("Format kuota salah")
      .integer("Format kuota salah")
      .typeError("Kuota tidak boleh kosong"),
    description: Yup.string().required("Brief Project tidak boleh kosong"),
    file_brief: Yup.string()
      .required("File brief tidak boleh kosong")
      .matches(urlRegex, "Format link file brief tidak sesuai"),
  });

  const validationEdit = Yup.object().shape({
    name: Yup.string().required("Nama project tidak boleh kosong"),
    price_per_task: Yup.number()
      .required("Harga per task tidak boleh kosong")
      .positive("Format harga per task salah")
      .integer("Format harga per task salah")
      .typeError("Harga per task tidak boleh kosong"),
    status: Yup.string().required("Status project tidak boleh kosong"),
    quota: Yup.number()
      .required("Kuota tidak boleh kosong")
      .positive("Format kuota salah")
      .integer("Format kuota salah")
      .typeError("Kuota tidak boleh kosong"),
    description: Yup.string().required("Brief Project tidak boleh kosong"),
    file_brief: Yup.string()
      .required("File brief tidak boleh kosong")
      .matches(urlRegex, "Link file brief tidak sesuai"),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isCreateMode ? validationSchema : validationEdit),
    mode: "onBlur",
    defaultValues: {
      name: "",
      price_per_task: "",
      type: "",
      category: "",
      sub_type: "",
      media_type: "",
      classification: "",
      start_period: "",
      end_period: "",
      status: "",
      industry: "",
      quota: "",
      description: "",
      file_brief: "",
    },
  });

  useEffect(() => {
    if (isNotCompleted) {
      setProvinceId(projectState?.province_id);
      setCityId(projectState?.city_id);
      setProjectType(projectState?.type);
      setProjectCategory(projectState?.category);
      setIndustry(projectState?.industry);
      let defaults = {
        name: projectState?.name,
        price_per_task: projectState?.price_per_task,
        type: projectState?.type,
        category: projectState?.category,
        sub_type: projectState?.sub_type,
        media_type: projectState?.media_type,
        classification: projectState?.classification,
        start_period: projectState?.start_period,
        end_period: projectState?.end_period,
        status: projectState?.status,
        industry: projectState?.industry,
        quota: projectState?.quota,
        description: projectState?.description,
        file_brief: projectState?.file_brief,
      };
      reset(defaults);
      setLoading(false);
    } else if (!isCreateMode) {
      UserService.getProjectMasterDetail(id).then(
        (response) => {
          let res = response.data.data;
          setProvinceId(res?.province?.id);
          setProvinceName(res?.province?.name);
          setCityId(res?.city?.id);
          setCityName(res?.city?.name);
          setImage(res?.image);
          setProjectType(res?.type);
          let defaults = {
            name: res?.name,
            price_per_task: res?.price_per_task,
            type: res?.type,
            category: res?.category,
            sub_type: res?.sub_type,
            media_type: res?.media_type,
            classification: res?.classification,
            start_period: res?.start_period,
            end_period: res?.end_period,
            status: res?.status,
            industry: res?.industry,
            quota: res?.quota,
            description: res?.description,
            file_brief: res?.file_brief,
          };
          reset(defaults);
          setLoading(false);
        },
        (error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [reset, isCreateMode, id, isNotCompleted, projectState?.province_id, projectState?.city_id, projectState?.type, projectState?.category, projectState?.industry, projectState?.name, projectState?.price_per_task, projectState?.sub_type, projectState?.media_type, projectState?.classification, projectState?.start_period, projectState?.end_period, projectState?.status, projectState?.quota, projectState?.description, projectState?.file_brief]);

  // Handle submit
  const onSubmit = (e) => {
    return isCreateMode ? createProject(e) : editProject(id, e);
  };

  const fileInput = useRef();
  const bloggerList = [];
  const mediaList = [];

  // Handle image preview
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Periode
  const [period, setPeriod] = useState();
  const changePeriod = (e) => {
    setPeriod(
      format(addDays(parse(e.target.value, "yyyy-MM-dd", 0), 3), "yyyy-MM-dd")
    );
  };

  // Tipe project
  function projectTypeOptions() {
    switch (projectType) {
      case "PUBLIC":
        return <Button text="Submit" />;
      case "PRIVATE":
        return <Button text="Selanjutnya" />;
      default:
        return null;
    }
  }

  const createProject = (data) => {
    if (data.type === "PRIVATE") {
      if (provinceId && cityId) {
        data.province_id = provinceId;
        data.province_name = provinceName;
        data.city_id = cityId;
        data.city_name = cityName;
        localStorage.setItem("projectPrivate", JSON.stringify(data));
        if (data.category === "blogger") {
          navigate(`${pathname}/invite/blogger`);
        } else {
          navigate(`${pathname}/invite/media`);
        }
      }
    } else {
      // Create with image
      if (fileInput.current.files.length > 0) {
        const formData = new FormData();
        formData.append("bucket", "penakita");
        formData.append("file", fileInput.current.files[0]);

        Alert.fire({
          title: "Mengupload gambar...",
        });
        Alert.showLoading();
        return UserService.uploadImage(formData)
          .then((res) => {
            Alert.close();

            const imageUri = res.data.data.url;
            Alert.fire({
              title: "Membuat project...",
            });
            Alert.showLoading();
            return UserService.createProjectMaster(
              data.name,
              data.price_per_task,
              data.type,
              data.category,
              data.sub_type,
              data.media_type,
              data.classification,
              data.start_period,
              data.end_period,
              data.status,
              data.industry,
              provinceId,
              cityId,
              data.quota,
              data.description,
              data.file_brief,
              imageUri,
              bloggerList,
              mediaList
            ).then(() => {
              Alert.close();
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Project berhasil dibuat.",
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.removeItem("projectPrivate");
                  localStorage.removeItem("projectData");
                  navigate(-1);
                }
              });
            });
          })
          .catch((error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          });
      }
      // Create without image
      else {
        Alert.fire({
          title: "Membuat project...",
        });
        Alert.showLoading();
        return UserService.createProjectMaster(
          data.name,
          data.price_per_task,
          data.type,
          data.category,
          data.sub_type,
          data.media_type,
          data.classification,
          data.start_period,
          data.end_period,
          data.status,
          data.industry,
          provinceId,
          cityId,
          data.quota,
          data.description,
          data.file_brief,
          image,
          bloggerList,
          mediaList
        )
          .then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Project berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("projectPrivate");
                localStorage.removeItem("projectData");
                navigate(-1);
              }
            });
          })
          .catch((error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          });
      }
    }
  };

  const editProject = (id, data) => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Mengubah project...",
          });
          Alert.showLoading();
          return UserService.updateProjectMaster(
            id,
            data.name,
            data.price_per_task,
            data.status,
            data.quota,
            data.description,
            data.file_brief,
            data.start_period,
            data.end_period,
            provinceId,
            cityId,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit project berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("projectData");
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Mengubah project...",
      });
      Alert.showLoading();
      return UserService.updateProjectMaster(
        id,
        data.name,
        data.price_per_task,
        data.status,
        data.quota,
        data.description,
        data.file_brief,
        data.start_period,
        data.end_period,
        provinceId,
        cityId,
        image
      )
        .then(() => {
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit project berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("projectData");
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-6 mb-6 flex flex-row justify-between">
              <div className="flex flex-col">
                {projectType === "PUBLIC" ? (
                  <>
                    <p className="mb-2 text-xs text-login-gray-dark">
                      Project Image
                    </p>
                    <label htmlFor="image">
                      {image && (
                        <img
                          src={image}
                          alt="preview"
                          className="h-64 max-w-lg rounded-lg object-scale-down"
                        />
                      )}
                      {!image && (
                        <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                          <img src={imageicon} alt="icon" />
                        </div>
                      )}
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      className="hidden"
                      accept="image/jpg, image/png"
                      ref={fileInput}
                      onChange={handleImage}
                    />
                  </>
                ) : null}
              </div>
              {isCreateMode ? projectTypeOptions() : <Button text="Update" />}
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-11">
              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Nama Project <span className="text-red-600">*</span>
                </p>
                <input
                  name="name"
                  type="text"
                  {...register("name")}
                  className={`form-control-db ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  placeholder="Nama project"
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Harga Per Task <span className="text-red-600">*</span>
                </p>
                <Controller
                  name="price_per_task"
                  control={control}
                  render={({ field: { onChange, onBlur, name, value } }) => (
                    <NumericFormat
                      placeholder="Harga per task"
                      className={`form-control-db ${
                        errors.price_per_task ? "is-invalid" : ""
                      }`}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      allowNegative={false}
                      onValueChange={(values) => onChange(values.floatValue)}
                      prefix="Rp "
                      name={name}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {errors.price_per_task?.message}
                </div>
              </div>

              <div className="form-group flex flex-row justify-between space-x-2">
                <div className="w-1/2">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Tipe Project <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="type"
                    name="type"
                    {...register("type")}
                    className={`${
                      !isCreateMode &&
                      "bg-gray-300 cursor-not-allowed form-control-db"
                    } form-control-db ${errors.type ? "is-invalid" : ""}`}
                    disabled={!isCreateMode}
                    onChange={(e) => setProjectType(e.target.value)}
                  >
                    <option value="">Pilih Tipe</option>
                    <option value="PUBLIC">Public</option>
                    <option value="PRIVATE">Private</option>
                  </select>
                  <div className="invalid-feedback">{errors.type?.message}</div>
                </div>

                <div className="w-1/2">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Kategori <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="category"
                    name="category"
                    {...register("category")}
                    className={`${
                      !isCreateMode &&
                      "bg-gray-300 cursor-not-allowed form-control-db"
                    } form-control-db ${errors.category ? "is-invalid" : ""}`}
                    disabled={!isCreateMode}
                    onChange={(e) => setProjectCategory(e.target.value)}
                  >
                    <option value="">Pilih Kategori</option>
                    {/* {projectType === "PRIVATE" && (
                      <option value="media">Media</option>
                    )} */}
                    <option value="blogger">Blogger</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.category?.message}
                  </div>
                </div>
              </div>

              {isCreateMode && (
                <div className="form-group relative">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Jenis Project <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="sub_type"
                    name="sub_type"
                    {...register("sub_type")}
                    className={`form-control-db ${
                      errors.sub_type ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih Jenis Project</option>
                    <option value="RETAINER">Retainer</option>
                    <option value="ADHOC">Adhoc</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.sub_type?.message}
                  </div>
                </div>
              )}

              {isCreateMode && (
                <div className="form-group relative">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Jenis Media <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="media_type"
                    name="media_type"
                    {...register("media_type")}
                    className={`form-control-db ${
                      errors.media_type ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih Jenis Media</option>
                    {projectCategory === "blogger" && (
                      <>
                        <option value="BLOGGER">Blogger</option>
                        {/* <option value="INSTAGRAM">Instagram</option>
                        <option value="TWITTER">Twitter</option> */}
                      </>
                    )}
                    {projectCategory === "media" && (
                      <>
                        <option value="ONLINE">Online</option>
                        {/* <option value="CETAK">Cetak</option>
                        <option value="MAJALAH">Majalah</option> */}
                      </>
                    )}
                  </select>
                  <div className="invalid-feedback">
                    {errors.media_type?.message}
                  </div>
                </div>
              )}

              {isCreateMode && (
                <div className="form-group relative">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Classification <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="classification"
                    name="classification"
                    {...register("classification")}
                    className={`form-control-db ${
                      errors.classification ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih Classification</option>
                    <option value="POST">Post</option>
                    <option value="CONTENT">Content</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.classification?.message}
                  </div>
                </div>
              )}

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Periode Mulai <span className="text-red-600">*</span>
                </p>
                <input
                  id="start_period"
                  name="start_period"
                  type="date"
                  min={new Date().toISOString().substring(0, 10)}
                  {...register("start_period", {
                    onChange: (e) => changePeriod(e),
                  })}
                  className={`form-control-db ${
                    errors.start_period ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.start_period?.message}
                </div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Periode Berakhir <span className="text-red-600">*</span>
                </p>
                <input
                  id="end_period"
                  name="end_period"
                  type="date"
                  min={period}
                  {...register("end_period")}
                  className={`form-control-db ${
                    errors.end_period ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.end_period?.message}
                </div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Status Project <span className="text-red-600">*</span>
                </p>
                <select
                  id="status"
                  name="status"
                  {...register("status")}
                  className={`form-control-db ${
                    errors.status ? "is-invalid" : ""
                  }`}
                >
                  <option value="">Pilih Status Project</option>
                  <option value="OPEN">Open</option>
                  {/* <option value="DRAFT">Draft</option> */}
                  {/* <option value="CLOSE">Close</option> */}
                </select>
                <div className="invalid-feedback">{errors.status?.message}</div>
              </div>

              {isCreateMode && (
                <div className="form-group relative">
                  <p className="mb-1 text-xs text-login-gray-dark">
                    Industri <span className="text-red-600">*</span>
                  </p>
                  <select
                    id="industry"
                    name="industry"
                    value={industry}
                    {...register("industry")}
                    onChange={(e) => setIndustry(e.target.value)}
                    className={`form-control-db ${
                      errors.industry ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih Industri</option>
                    {industryCategories &&
                      industryCategories.map((x, i) => (
                        <option value={x} key={i}>
                          {x}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">
                    {errors.industry?.message}
                  </div>
                </div>
              )}

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Provinsi <span className="text-red-600">*</span>
                </p>
                {provinceName ? (
                  <Select
                    defaultInputValue={provinceName}
                    defaultValue={provinceId}
                    onChange={changeProvince}
                    options={provinceDropdown}
                    isSearchable={true}
                    placeholder="Pilih Provinsi"
                    noOptionsMessage={() => "Tidak ada"}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: "#8f959a",
                        marginTop: "0.25rem",
                        paddingTop: "0.3125rem",
                        paddingBottom: "0.3125rem",
                        paddingLeft: "0.5rem",
                        borderRadius: "0.25rem",
                      }),
                    }}
                  />
                ) : (
                  <Select
                    onChange={changeProvince}
                    options={provinceDropdown}
                    isSearchable={true}
                    placeholder="Pilih Provinsi"
                    noOptionsMessage={() => "Tidak ada"}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: "#8f959a",
                        marginTop: "0.25rem",
                        paddingTop: "0.3125rem",
                        paddingBottom: "0.3125rem",
                        paddingLeft: "0.5rem",
                        borderRadius: "0.25rem",
                      }),
                    }}
                  />
                )}
                <div className="invalid-feedback">
                  {provinceName?.length > 0
                    ? null
                    : "Provinsi tidak boleh kosong"}
                </div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Kota <span className="text-red-600">*</span>
                </p>
                {cityName ? (
                  <Select
                    defaultInputValue={cityName}
                    defaultValue={cityId}
                    onChange={changeCity}
                    options={cityDropdown}
                    isSearchable={true}
                    placeholder="Pilih Kota / Kabupaten"
                    noOptionsMessage={() => "Tidak ada"}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: "#8f959a",
                        marginTop: "0.25rem",
                        paddingTop: "0.3125rem",
                        paddingBottom: "0.3125rem",
                        paddingLeft: "0.5rem",
                        borderRadius: "0.25rem",
                      }),
                    }}
                  />
                ) : (
                  <Select
                    onChange={changeCity}
                    options={cityDropdown}
                    isSearchable={true}
                    placeholder="Pilih Kota / Kabupaten"
                    noOptionsMessage={() => "Tidak ada"}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: "#8f959a",
                        marginTop: "0.25rem",
                        paddingTop: "0.3125rem",
                        paddingBottom: "0.3125rem",
                        paddingLeft: "0.5rem",
                        borderRadius: "0.25rem",
                      }),
                    }}
                  />
                )}
                <div className="invalid-feedback">
                  {cityName?.length > 0
                    ? null
                    : "Kota / Kabupaten tidak boleh kosong"}
                </div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Kuota <span className="text-red-600">*</span>
                </p>
                <input
                  name="quota"
                  type="number"
                  {...register("quota")}
                  className={`form-control-db ${
                    errors.quota ? "is-invalid" : ""
                  }`}
                  placeholder="Kuota"
                  min="1"
                />
                <div className="invalid-feedback">{errors.quota?.message}</div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  File Brief <span className="text-red-600">*</span>
                </p>
                <input
                  name="file_brief"
                  type="text"
                  {...register("file_brief")}
                  className={`form-control-db ${
                    errors.file_brief ? "is-invalid" : ""
                  }`}
                  placeholder="https://"
                />
                <div className="invalid-feedback">
                  {errors.file_brief?.message}
                </div>
              </div>

              <div className="form-group relative">
                <p className="mb-1 text-xs text-login-gray-dark">
                  Brief Project <span className="text-red-600">*</span>
                </p>
                <textarea
                  id="description"
                  name="description"
                  maxLength="500"
                  {...register("description")}
                  className={`form-control-db ${
                    errors.description ? "is-invalid" : ""
                  } w-full h-36 text-sm focus:outline-none resize-none`}
                  placeholder="Brief project"
                />
                <div className="invalid-feedback">
                  {errors.description?.message}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default CreateProject;
