import { Dropdown } from "@nextui-org/react";

function CategoryDropdown({ categoryValue, category, setCategory }) {
  const CategoryMap = {
    media: "Media",
    blogger: "Blogger",
    ALL: "Semua Kategori",
  };

  const categoryDatas = Object.entries(CategoryMap);

  return (
    <Dropdown>
      <Dropdown.Button flat>{CategoryMap[categoryValue]}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={category}
        onSelectionChange={setCategory}
      >
        {categoryDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CategoryDropdown;
