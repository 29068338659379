import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { StatusMap, MediaTypeMap } from "constants";
import { ShortDateFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  navigate,
  pathname,
  handleStatus,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Category = (row) => {
    return <div className="capitalize">{row.project_category}</div>;
  };

  const MediaType = (row) => {
    return MediaTypeMap[row.project_media_type];
  };

  const Status = (row) => {
    return StatusMap[row.status];
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/activity-detail/${row.id}`)}
    >
      Detail
    </Button>
  );

  const Action = (row) =>
    (row.status === "INVITED" || row.status === "REQUESTED") &&
    row.project_type === "PUBLIC" && (
      <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
        <Button
          size="xs"
          color="success"
          auto
          onClick={() => handleStatus("approve", row.id)}
        >
          Approve
        </Button>
        <Button
          size="xs"
          color="error"
          auto
          onClick={() => handleStatus("decline", row.id)}
        >
          Decline
        </Button>
      </div>
    );

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      grow: 2,
      wrap: true,
    },
    {
      name: "Kategori",
      selector: (row) => row.project_category,
      width: "90px",
      wrap: true,
      cell: (row) => <Category {...row} />,
    },
    {
      name: "Jenis",
      selector: (row) => row.project_media_type,
      width: "100px",
      wrap: true,
      cell: (row) => <MediaType {...row} />,
    },
    {
      name: "User Email",
      selector: (row) =>
        row.blogger_email ? row.blogger_email : row.media_pic_email,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      grow: 1.4,
      wrap: true,
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateFormat(row.created_at),
      width: "115px",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.detail,
      width: "80px",
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      grow: 1.4,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
