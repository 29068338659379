import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonAdd, Alert } from "components";
import UserService from "services/user.service";
import { Text, Card } from "@nextui-org/react";
import Table from "./components/Table";

function Schedule() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);

  const fetchSchedule = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getSchedule(page, perPage);
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchSchedule(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getSchedule(page, newPerPage);
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchSchedule(1);
  }, [refresh]);

  // Add Schedule
  const goAdd = () => {
    navigate(`${pathname}/add`);
  };

  // Edit Schedule
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editSchedule", JSON.stringify(row));
      navigate(`${pathname}/edit/${row.scheduleId}`);
    }
  };

  // Delete Schedule
  const handleDelete = (id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Schedule yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteSchedule(id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Schedule telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Schedule List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="flex items-center justify-end mb-3">
            <ButtonAdd action={goAdd} text="Add New Schedule" />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Schedule;
