import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "services/user.service";
import { SearchBar, Alert } from "components";
import { Card, Text, Button, Loading } from "@nextui-org/react";
import { HiOutlineDownload } from "react-icons/hi";
import Table from "./components/Table";

function UserMedia() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchUsers = async (page) => {
    setLoading(true);
    setIsDisabled(true);
    try {
      const response = await UserService.getUserMedia(page, perPage, keyword);
      setIsDisabled(false);
      setData(response.data.data.medias);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setIsDisabled(true);
      setLoading(false);
      setData(err.response.data.data.medias);
    }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getUserMedia(
        page,
        newPerPage,
        keyword
      );
      setData(response.data.data.medias);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.medias);
    }
  };

  useEffect(() => {
    fetchUsers(1);
  }, [keyword]);

  const handleExport = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengexport user media list ke excel.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getExportMedia(keyword).then(
          (response) => {
            if (response) {
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Berhasil download user media list",
              });
              window.location.href = response.data.data.url;
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          User Media List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="mb-3 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <SearchBar
              setKeyword={setKeyword}
              placeholder="Cari Nama/Email Media"
            />
            <Button
              size="md"
              color="default"
              auto
              iconRight={!loading && <HiOutlineDownload />}
              onClick={() => handleExport()}
              disabled={isDisabled}
            >
              {loading && (
                <Loading type="spinner" color="currentColor" size="sm" />
              )}
              {!loading && "Export to excel"}
            </Button>
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            navigate={navigate}
            pathname={pathname}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default UserMedia;
