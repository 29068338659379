import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { noimage } from "assets";
import { ShortDateFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  handleEdit,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Action = (row) => (
    <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
      <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
        Edit
      </Button>

      <Button
        size="xs"
        color="error"
        auto
        onClick={() => handleDelete(row.articleId)}
      >
        Delete
      </Button>
    </div>
  );

  const ImageArticles = (row) =>
    row.image ? (
      <img
        src={row.image ?? noimage}
        alt={row.articleId}
        className="w-20 h-20 object-cover"
        onError={(e) => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = noimage;
        }}
      />
    ) : (
      "-"
    );

  const Tags = (row) => (
    <ul className="flex flex-col list-disc">
      {row.tags?.map((data, id) => (
        <li className="!mb-0 !leading-normal" key={id}>
          {data}
        </li>
      ))}
    </ul>
  );

  const Description = (row) => (
    <div
      className="whitespace-pre-wrap"
      dangerouslySetInnerHTML={{
        __html:
          row.description.length > 100
            ? row.description.slice(0, 100) + "..."
            : row.description,
      }}
    />
  );

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      width: "180px",
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 1.8,
      wrap: true,
      format: (row) => <Description {...row} />,
    },
    {
      name: "Type",
      selector: (row) => <span className="capitalize">{row.type}</span>,
      width: "80px",
      wrap: true,
    },
    {
      name: "Group",
      selector: (row) => <span className="capitalize">{row.group}</span>,
      width: "85px",
      wrap: true,
    },
    {
      name: "Tags",
      selector: (row) => row.tags,
      grow: 1,
      wrap: true,
      cell: (row) => <Tags {...row} />,
    },
    {
      name: "View",
      selector: (row) => row.view,
      width: "75px",
      wrap: true,
    },
    {
      name: "Image",
      width: "110px",
      cell: (row) => <ImageArticles {...row} />,
    },
    {
      name: "Created",
      selector: (row) => ShortDateFormat(row.createdAt),
      width: "110px",
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
