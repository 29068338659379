import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Text, Button, Loading } from "@nextui-org/react";
import { HiOutlineDownload } from "react-icons/hi";
import { SearchBar, Alert } from "components";
import Select from "react-select";
import UserService from "services/user.service";
import Table from "./components/Table";
import TierDropdown from "./components/TierDropdown";
import SelectStyles from "./components/SelectStyles";

function UserBlogger() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [tierList, setTierList] = useState([]);
  const [tier, setTier] = useState(new Set([""]));
  const tierValue = useMemo(() => Array.from(tier), [tier]);
  const [provinceData, setProvinceData] = useState([]);
  const [provinceValue, setProvinceValue] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [cityData, setCityData] = useState([]);
  const [cityValue, setCityValue] = useState([]);
  const [cityId, setCityId] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchUsers = async (page) => {
    setLoading(true);
    setIsDisabled(true);
    try {
      const response = await UserService.getUserBlogger(
        page,
        perPage,
        keyword,
        tierValue,
        provinceId,
        cityId
      );
      setIsDisabled(false);
      setData(response.data.data.bloggers);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setIsDisabled(true);
      setLoading(false);
      setData(err.response.data);
    }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getUserBlogger(
        page,
        newPerPage,
        keyword,
        tierValue,
        provinceId,
        cityId
      );
      setData(response.data.data.bloggers);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data);
    }
  };

  useEffect(() => {
    fetchUsers(1);
  }, [keyword, tierValue, provinceId, cityId]);

  useEffect(() => {
    const fetchTier = async () => {
      try {
        const res = await UserService.getTier(1, 25, "blogger");
        if (res.data.data.data.length > 0) {
          const obj = {};
          res.data.data.data.forEach((element) => {
            obj[element.tier] = element.name;
            return obj;
          });
          setTierList(obj);
        }
      } catch (err) {
        setTierList(err.response);
      }
    };

    fetchTier();
  }, []);

  useEffect(() => {
    const fetchProvince = async () => {
      try {
        const res = await UserService.getProvince();
        if (res.data.data.data.length > 0) {
          setProvinceData(
            res.data.data.data.map((data) => {
              return { value: data.id, label: data.name };
            })
          );
        }
      } catch (err) {
        setProvinceData(err.response);
      }
    };

    fetchProvince();
  }, []);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const res = await UserService.getCity(provinceId);
        if (res.data.data.data.length > 0) {
          setCityData(
            res.data.data.data.map((data) => {
              return { value: data.city_id, label: data.name };
            })
          );
        }
      } catch (err) {
        setCityData(err.response);
      }
    };

    if (provinceId) {
      fetchCity();
    }
  }, [provinceId]);

  const handleProvince = (data) => {
    setCityId("");
    setProvinceValue(data);
    if (data?.value) {
      setProvinceId(data.value);
    } else {
      setProvinceId("");
    }
    setCityValue([{ value: null, label: null }]);
  };

  const handleCity = (data) => {
    setCityValue(data);
    if (data?.value) {
      setCityId(data.value);
    } else {
      setCityId("");
    }
  };

  const handleExport = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengexport user blogger list ke excel.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getExportBlogger(tierValue, provinceId, cityId, keyword).then(
          (response) => {
            if (response) {
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Berhasil download user blogger list",
              });
              window.location.href = response.data.data.url;
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          User Blogger List
        </Text>
      </div>
      <Card>
        <Card.Body css={{ minHeight: "500px" }}>
          <div className="mb-3 flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
            <SearchBar
              setKeyword={setKeyword}
              placeholder="Cari Nama/Email Blogger"
            />
            <TierDropdown
              tierList={tierList}
              tierValue={tierValue}
              tier={tier}
              setTier={setTier}
            />
            <Select
              onChange={(e) => handleProvince(e)}
              value={provinceValue}
              options={provinceData}
              placeholder="Pilih Provinsi"
              styles={SelectStyles}
              noOptionsMessage={() => "Tidak ada provinsi"}
              isClearable
            />
            <Select
              onChange={(e) => handleCity(e)}
              value={cityValue}
              options={cityData}
              placeholder="Pilih Kota/Kabupaten"
              styles={SelectStyles}
              noOptionsMessage={() => "Tidak ada kota/kab"}
              isClearable
            />
            <Button
              size="md"
              color="default"
              auto
              iconRight={!loading && <HiOutlineDownload />}
              onClick={() => handleExport()}
              disabled={isDisabled}
            >
              {loading && (
                <Loading type="spinner" color="currentColor" size="sm" />
              )}
              {!loading && "Export to excel"}
            </Button>
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            navigate={navigate}
            pathname={pathname}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default UserBlogger;
