import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading } from "@nextui-org/react";
import { DateFormat } from "utils/helper";

export default function ActivityDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getTransactionDetail(id);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-96">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!data && (
              <span className="flex items-center justify-center h-96 font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && data && (
              <div className="lg:p-5 flex flex-col lg:flex-row">
                <ul className="w-full flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Id Activity</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">{data?.id}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Project Name</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.project_name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-semibold">Status</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.status}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">User</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data.blogger_name
                            ? data.blogger_name
                            : data.media_name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Email</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark break-all">
                          {data.blogger_email
                            ? data.blogger_email
                            : data.media_pic_email}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Created At</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_at && DateFormat(data?.created_at)}
                          {!data?.created_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Created By</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_by && data.created_by}
                          {!data?.created_by && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Updated At</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_at && DateFormat(data?.updated_at)}
                          {!data?.updated_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Updated By</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_by && data.updated_by}
                          {!data?.updated_by && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
