import DataTable from "react-data-table-component";
import { Button, Link } from "@nextui-org/react";
import { Currency, ShortDateTimeFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  navigate,
  pathname,
}) {
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const LinkBlog = (row) =>
    row.linkasset && (
      <Link
        isExternal
        color="primary"
        href={row.linkasset}
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.linkasset.length > 25
          ? row.linkasset.slice(0, 25) + "..."
          : row.linkasset}
      </Link>
    );

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.blogger_id}`)}
    >
      Lihat Detail
    </Button>
  );

  const columns = [
    {
      name: "Nama",
      selector: (row) => row.name,
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Tier",
      selector: (row) => row.blogger_tier,
      width: "60px",
      wrap: true,
      center: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      grow: 1.3,
      wrap: true,
    },
    {
      name: "No HP",
      selector: (row) => row.phone_number,
      grow: 1,
      wrap: true,
    },
    {
      name: "Link Blog",
      selector: (row) => row.linkasset,
      grow: 1,
      wrap: true,
      cell: (row) => <LinkBlog {...row} />,
    },
    {
      name: "Total Balance",
      selector: (row) => Currency(row.total_balance),
      width: "130px",
      wrap: true,
      right: true,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      grow: 1,
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.action,
      grow: 0.7,
      cell: (row) => <Detail {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
