import { Button } from "@nextui-org/react";

function ButtonAdd({ action, text }) {
  return (
    <Button onClick={action} size="md" auto>
      {text}
    </Button>
  );
}

export default ButtonAdd;
