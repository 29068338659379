import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, Button, ButtonBack } from "components";
import { imageicon } from "assets";
import UserService from "services/user.service";
import { Card, Loading, Image } from "@nextui-org/react";
import { FullDateTimeFormat } from "utils/helper";
import { WithdrawStatusMap } from "constants";

export default function WithdrawRequestDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      status: "",
    },
  });

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getWithdrawDetail(id);
        setData(response.data.data);
        if (response.data.data.status === "PAID") {
          let defaults = {
            status: response.data.data.status,
          };
          reset(defaults);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [reset, refresh, id]);

  // Handle image preview
  const [image, setImage] = useState("");
  const fileInput = useRef();
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  const onSubmit = (data) => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Mengubah status withdraw...",
          });
          Alert.showLoading();
          return UserService.updateWithdrawStatus(
            id,
            data.status,
            imageUri
          ).then(() => {
            Alert.close();
            setRefresh(refresh + 1);
            setImage("");
            reset();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Berhasil update status withdraw.",
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Mengubah status withdraw...",
      });
      Alert.showLoading();
      return UserService.updateWithdrawStatus(id, data.status, image)
        .then(() => {
          Alert.close();
          setRefresh(refresh + 1);
          setImage("");
          reset();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Berhasil update status withdraw.",
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  const Status = () => {
    return WithdrawStatusMap[data?.status];
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[484px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[484px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <>
              <Card.Body>
                <div className="lg:p-5 flex flex-col">
                  <div className="flex flex-col lg:flex-row">
                    <ul className="w-full lg:w-1/2 flex-col space-y-4">
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Id Penarikan</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.withdraw_id}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Nama Pengguna</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.blogger !== null && data?.blogger?.name}
                              {data?.media !== null && data?.media?.companyName}
                              {data?.consultant !== null &&
                                data?.consultant?.name}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-semibold">Status</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {Status()}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Diproses oleh</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.updated ? data?.updated?.name : "-"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Keteragan</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.remarks ? data?.remarks : "-"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Bukti</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.evidence ? (
                                <Image
                                  showSkeleton
                                  width={320}
                                  height={180}
                                  maxDelay={10000}
                                  src={data?.evidence}
                                  alt="evidence"
                                />
                              ) : (
                                <div className="">Belum ada</div>
                              )}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Bank</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data.blogger !== null &&
                                data?.blogger?.bank?.name}
                              {data.media !== null && data?.media?.bank?.name}
                              {data.consultant !== null &&
                                data?.consultant?.bank?.name}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Nomor Rekening</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data.blogger !== null &&
                                data?.blogger?.bankAccountNumber}
                              {data.media !== null &&
                                data?.media?.bankAccountNumber}
                              {data.consultant !== null &&
                                data?.consultant?.bankAccountNumber}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-semibold">Nama Rekening</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data.blogger !== null &&
                                data?.blogger?.bankAccountName}
                              {data.media !== null && data?.media?.companyName}
                              {data.consultant !== null &&
                                data?.consultant?.bankAccountName}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Diproses pada</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.created_at
                                ? FullDateTimeFormat(data?.created_at)
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div className="w-1/2 lg:w-1/3">
                            <span className="font-bold">Diubah pada</span>
                          </div>
                          <div className="w-1/2">
                            <span className="text-login-gray-dark">
                              {data?.updated_at
                                ? FullDateTimeFormat(data?.updated_at)
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
              <Card.Divider />
              <Card.Footer>
                <div className="w-full lg:px-5 lg:pb-5">
                  <h4 className="font-semibold">
                    Update Status dan Bukti Transfer
                  </h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-6 flex flex-col lg:flex-row space-y-4">
                      <div className="w-1/2 flex flex-row items-center space-x-4">
                        <input
                          type="file"
                          id="photo"
                          name="photo"
                          className="w-32 lg:w-60"
                          accept="image/jpg, image/png"
                          ref={fileInput}
                          onChange={handleImage}
                          aria-label="Pilih berkas"
                        />
                        {image && (
                          <img
                            src={image}
                            alt="preview"
                            className="w-32 h-32 rounded-2xl object-scale-down"
                          />
                        )}
                        {!image && (
                          <div className="flex flex-col items-center justify-center w-32 h-32 rounded-2xl border border-transparent bg-login-gray-light hover:border-pk-primary">
                            <img src={imageicon} alt="icon" />
                          </div>
                        )}
                      </div>

                      <div className="w-1/3 flex flex-row items-center space-x-4">
                        <div className="w-1/2">
                          <select
                            id="status"
                            name="status"
                            disabled={data?.status === "PAID"}
                            {...register("status")}
                            className={`form-control-db ${
                              errors.status ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Pilih status</option>
                            <option value="PAID">Paid</option>
                          </select>
                          <div className="invalid-feedback">
                            {errors.status?.message}
                          </div>
                        </div>
                        <Button
                          disabled={data?.status === "PAID"}
                          text="Update"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Card.Footer>
            </>
          )}
        </Card>
      </div>
    </>
  );
}
