import { useNavigate } from "react-router-dom";
import { backicon } from "assets";
import { Button } from "@nextui-org/react";

function ButtonBack({ url }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(url ? `${url}` : -1);
  };

  return (
    <div className="px-6 pb-4">
      <Button
        onClick={goBack}
        icon={<img src={backicon} alt="back icon" className="w-5 h-5" />}
        auto
        flat
        css={{ backgroundColor: "transparent", padding: "$0", height: "$9", zIndex: "$1" }}
      >
        <span className="text-black">Back</span>
      </Button>
    </div>
  );
}

export default ButtonBack;
