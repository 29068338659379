import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, Alert } from "components";
import UserService from "services/user.service";

function CourseMaterial() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const fetchMaterials = async () => {
    try {
      setLoading(true);
      const response = await UserService.getCourseMaterial(id);
      setMaterials(response.data.data.materials);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMaterials(err.response.data.data.materials);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, [refresh]);

  // Select row table
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(
      state.selectedRows.map(({ courseMaterialId }) => courseMaterialId)
    );
  }, []);

  const contextActions = useMemo(() => {
    // Delete Course Material
    const handleDelete = () => {
      Alert.fire({
        icon: "warning",
        title: "Apakah anda yakin?",
        text: "Anda tidak dapat mengembalikan Course Material yang telah dihapus!",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          UserService.deleteCourseMaterial(id, selectedRows).then(
            (response) => {
              if (response) {
                setRefresh(refresh + 1);
                setToggleCleared(!toggleCleared);
                Alert.fire({
                  icon: "success",
                  title: "Sukses",
                  text: "Course Material telah berhasil dihapus.",
                });
              }
            },
            (error) => {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${error.response.data.message}`,
              });
            }
          );
        }
      });
    };

    return (
      <button
        key="delete"
        onClick={handleDelete}
        className="h-10 px-8 py-1 text-sm bg-red-600 text-white rounded-lg duration-200 ease-in-out hover:shadow-lg"
      >
        Delete
      </button>
    );
  }, [selectedRows, toggleCleared, refresh, id]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Course Material List"
        columns={columns}
        data={materials}
        progressPending={loading}
        customStyles={customStyles}
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  // custom columns
  const LinkMaterial = (row) => (
    <a
      href={row.link}
      target="_blank"
      rel="noopener noreferrer"
      className="py-1 link text-pk-primary font-bold underline"
    >
      {row.link?.length > 25 ? row.link?.slice(0, 25) + "..." : row.link}
    </a>
  );

  const Action = (row) => (
    <button
      className="link text-green-600 font-bold underline focus:outline-none"
      onClick={() => handleEdit(row)}
    >
      Edit
    </button>
  );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.courseMaterialId,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      grow: 2,
      format: (row) =>
        `${
          row.title.length > 100 ? row.title.slice(0, 100) + "..." : row.title
        }`,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      grow: 3,
      format: (row) =>
        `${
          row.description.length > 100
            ? row.description.slice(0, 100) + "..."
            : row.description
        }`,
    },
    {
      name: "Order",
      selector: (row) => row.order,
      width: "80px",
    },
    {
      name: "Link",
      selector: (row) => row.link,
      wrap: true,
      grow: 1.5,
      cell: (row) => <LinkMaterial {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
      width: "80px",
    },
  ];

  // Edit Course Material
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editCourseMaterial", JSON.stringify(row));
      navigate(`${pathname}/material/${row.courseMaterialId}/edit`);
    }
  };

  return (
    <div className="pt-6 pb-8">
      <div className="flex flex-col items-end">
        <Link to={`${pathname}/material/add`}>
          <Button text="Add New Course Material" />
        </Link>
      </div>
      <div className="rounded-xl">{TableComponent()}</div>
    </div>
  );
}
export default CourseMaterial;
