import { Sidebar, NavbarComp, Footer } from "components";
import { StyledLayoutContent } from "./styled";

export const BaseLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <StyledLayoutContent className="bg-pk-bg relative min-h-screen">
        <NavbarComp />
        <div className="mt-4 lg:mt-8 container mx-auto">{children}</div>
        <Footer />
      </StyledLayoutContent>
    </>
  );
};

export const getLayout = (page) => {
  return <BaseLayout>{page}</BaseLayout>;
};
