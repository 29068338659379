import axios from "axios";
import authHeader from "./auth-header";

const login = (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/admin/auth/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    });
};

const logout = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/admin/auth/logout`, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response) {
        localStorage.clear();
      }
      return response.data;
    })
    .catch(() => {
      localStorage.clear();
      window.location = "/";
    });
};

const forgot = (email) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/admin/auth/forgot-password`, {
      email,
    })
    .then((response) => {
      return response;
    });
};

const reset = (token, password, password_confirmation) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/admin/auth/reset-password`, {
      token,
      password,
      password_confirmation,
    })
    .then((response) => {
      return response;
    });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  login,
  logout,
  forgot,
  reset,
  getCurrentUser,
};
