import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack, Alert } from "components";
import UserService from "services/user.service";
import { Card, Loading, Avatar, Button } from "@nextui-org/react";
import { logo } from "assets";
import { DateFormat, Currency } from "utils/helper";
import Table from "./components/Table";

function UserBloggerDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [assets, setAssets] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [bloggerTier, setBloggerTier] = useState();
  const [tierId, setTierId] = useState();

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getBloggerDetail(id);
        setData(response.data.data);
        setAssets(response.data.data.asset);
        setTierId(response.data.data.blogger_tier);
        setBloggerTier(response.data.data.blogger_tier);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    const fetchTier = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getTier(1, 10, "blogger");
        setTierList(response.data.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setTierList(err.response);
      }
    };
    fetchDetail();
    fetchTier();
  }, [id, refresh]);

  const changeTier = (e) => {
    setTierId(parseInt(e.target.value));
  };

  const handleUpdateTier = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan mengubah blogger tier dari ${bloggerTier} menjadi ${tierId}`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.updateBloggerTier(id, tierId).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil mengubah media tier dari ${bloggerTier} menjadi ${tierId}`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      } else if (result.dismiss) {
        setRefresh(refresh + 1);
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          {isLoading && (
            <div className="flex items-center justify-center h-[604px]">
              <Loading size="lg" color="warning" />
            </div>
          )}
          {!data && (
            <span className="flex items-center justify-center h-[604px] font-bold text-red-500">
              No Data Found
            </span>
          )}
          {!isLoading && data && (
            <>
              <Card.Header>
                <Avatar
                  src={data?.photo_profile ? data.photo_profile : logo}
                  css={{ size: "$20", mt: 16, ml: 20 }}
                  color="gradient"
                  bordered
                />
              </Card.Header>
              <Card.Body>
                <div className="lg:px-5 flex flex-col lg:flex-row">
                  <ul className="w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Id Blogger</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.id}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nama Lengkap</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.name}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nama Pena</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.username ? data.username : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Email</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark break-all">
                            {data?.email}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Telepon</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.phone_number ? data?.phone_number : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Role</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            User Blogger
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Tanggal Lahir</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.birth_date ? data.birth_date : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Tempat Lahir</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.birth_place ? data.birth_place : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Dibuat oleh</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.created_by ? data.created_by : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Dibuat pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.created_at
                              ? DateFormat(data?.created_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Diperbarui pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.updated_at
                              ? DateFormat(data?.updated_at)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Bio</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bio ? data?.bio : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className="mt-4 lg:mt-0 w-full lg:w-1/2 flex-col space-y-4">
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Alamat</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.address ? data?.address : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Provinsi</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.province?.name ? data?.province?.name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kota</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.city?.name ? data?.city?.name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kecamatan</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.district?.name ? data?.district?.name : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kelurahan</span>
                        </div>
                        <div className="w-1/2">
                          <p className="text-login-gray-dark lowercase first-line:capitalize">
                            {data?.sub_district?.name
                              ? data?.sub_district?.name
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Kode Pos</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.sub_district?.postalCode
                              ? data?.sub_district?.postalCode
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Bank</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bank?.name ? data?.bank?.name : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nomor Rekening</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bank_account_number
                              ? data?.bank_account_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Nama Rekening</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.bank_account_name
                              ? data.bank_account_name
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Total Balance</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.total_balance
                              ? Currency(data?.total_balance)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Login terakhir pada</span>
                        </div>
                        <div className="w-1/2">
                          <span className="text-login-gray-dark">
                            {data?.last_login
                              ? DateFormat(data?.last_login)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="w-1/2 lg:w-1/3">
                          <span className="font-bold">Blogger Tier</span>
                        </div>
                        <div className="flex space-x-4">
                          <select
                            name="blogger_tier"
                            className="w-full h-auto rounded-xl border-2 border-pk-border px-4 py-1 text-sm"
                            value={tierId}
                            onChange={(e) =>
                              tierId !== e.target.value && changeTier(e)
                            }
                          >
                            <option value="0">Pilih Blogger Tier</option>
                            {tierList?.length > 0 &&
                              tierList.map((x, i) => (
                                <option key={i} value={x.tier}>
                                  {x.tier}
                                </option>
                              ))}
                          </select>
                          {tierId !== bloggerTier && tierId > 0 && (
                            <Button
                              type="submit"
                              onClick={handleUpdateTier}
                              size="sm"
                              auto
                              css={{
                                "background-color": "#FF9D25",
                                color: "$black",
                              }}
                            >
                              Ubah Tier
                            </Button>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card.Body>
              <h4 className="mx-3 lg:mx-8 mb-4 font-bold">
                List Aset {data?.name}
              </h4>
              <Table
                data={assets}
                loading={isLoading}
                totalRows={assets?.length}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export default UserBloggerDetail;
