import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { imageicon } from "assets";
import Creatable from "react-select/creatable";
import { Button } from "@nextui-org/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function CreateArticle() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title tidak boleh kosong"),
    view: Yup.string().required("Jumlah view tidak boleh kosong"),
    type: Yup.string().required("Tipe media tidak boleh kosong"),
    group: Yup.string().required("Group tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      view: 0,
      type: "",
      group: "",
    },
  });

  // Text Editor
  const [description, setDescription] = useState("");
  const quillChange = (description) => {
    setDescription(description);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "blockquote",
    "code",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    if (!isCreateMode) {
      let edit = JSON.parse(localStorage.getItem("editArticle"));
      setDescription(edit?.description);
      setImage(edit?.image);
      setTagValue(edit?.tags?.map((data) => ({ label: data, value: data })));
      let defaults = {
        title: edit?.title,
        view: edit?.view,
        type: edit?.type,
        group: edit?.group,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const onSubmit = (e) => {
    return isCreateMode ? createArticle(e) : editArticle(id, e);
  };

  // Handle Tags
  const [disabled, setDisabled] = useState(true);
  const [tagInputValue, setTagInputValue] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [valueList, setValueList] = useState([]);

  const handleChange = (field, value) => {
    switch (field) {
      case "tags":
        setTagValue(value);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (e) => {
    if (!tagInputValue) return;
    if (tagValue.length < 10) {
      switch (e.key) {
        case "Enter":
        case "Tab":
          setTagValue([...tagValue, createOption(tagInputValue)]);
          setTagInputValue("");
          e.preventDefault();
          break;
        default:
          break;
      }
    }
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleInputChange = (value) => {
    setTagInputValue(value);
  };

  const fileInput = useRef();
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (tagValue.length > 0) {
          setValueList(tagValue.map((tag) => tag.value));
        }
      } catch (err) {
        setValueList([]);
      }
    };
    fetchTags();

    const fetchError = async () => {
      try {
        if (description.length > 8 && tagValue.length > 0 && image !== "") {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } catch (err) {
        setDisabled(true);
      }
    };
    fetchError();
  }, [tagValue, description, image]);

  // Handle image preview
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Create article
  const createArticle = (e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Menambahkan artikel baru...",
          });
          Alert.showLoading();
          return UserService.createArticle(
            e.title,
            parseInt(e.view),
            e.type,
            e.group,
            valueList,
            description,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Artikel berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        icon: "error",
        title: "Terjadi kesalahan",
        text: "Gambar tidak boleh kosong",
      });
    }
  };

  // Edit article
  const editArticle = (id, e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Mengubah informasi artikel...",
          });
          Alert.showLoading();
          return UserService.updateArticle(
            id,
            e.title,
            e.view,
            e.type,
            e.group,
            valueList,
            description,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit artikel berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("editArticle");
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      Alert.fire({
        title: "Mengubah informasi artikel...",
      });
      Alert.showLoading();
      return UserService.updateArticle(
        id,
        e.title,
        e.view,
        e.type,
        e.group,
        valueList,
        description,
        image
      )
        .then(() => {
          Alert.close();
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Edit artikel berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("editArticle");
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <Button
              type="submit"
              size="md"
              auto
              disabled={disabled}
              css={{ "background-color": "#FF9D25", color: "$black" }}
            >
              Submit
            </Button>
          </div>
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">Image</p>
            <label
              htmlFor="image"
              className={`${
                image ? "h-32 max-w-xs md:h-64 md:max-w-lg" : "w-60 h-32"
              }`}
            >
              {image && (
                <img
                  src={image}
                  alt="preview"
                  className="h-32 max-w-xs md:h-64 md:max-w-lg rounded-lg object-scale-down"
                />
              )}
              {!image && (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept=".jpg, .jpeg, .png"
              ref={fileInput}
              onChange={handleImage}
            />
            {image.length === 0 && (
              <div className="invalid-feedback">Gambar tidak boleh kosong</div>
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-11">
            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Title <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Judul artikel"
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                View <span className="text-red-600">*</span>
              </p>
              <input
                name="view"
                type="number"
                {...register("view")}
                className={`bg-gray-300 form-control-db ${
                  errors.view ? "is-invalid" : ""
                }`}
                placeholder="0"
                disabled
              />
              <div className="invalid-feedback">{errors.view?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Tipe Media <span className="text-red-600">*</span>
              </p>
              <select
                name="type"
                id="type"
                {...register("type")}
                className={`form-control-db ${errors.type ? "is-invalid" : ""}`}
              >
                <option value="">Pilih Tipe Media</option>
                <option value="mitme">Mitme</option>
                <option value="blogger">Blog</option>
                <option value="campaign">Campaign</option>
                <option value="digital-content">Digital Content</option>
                <option value="research">Research</option>
                <option value="development">IT Development</option>
              </select>
              <div className="invalid-feedback">{errors.type?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Group <span className="text-red-600">*</span>
              </p>
              <select
                name="group"
                id="group"
                {...register("group")}
                className={`form-control-db ${
                  errors.group ? "is-invalid" : ""
                }`}
              >
                <option value="">Pilih Group</option>
                <option value="all">All</option>
              </select>
              <div className="invalid-feedback">{errors.group?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Tags <span className="text-red-600">*</span>
              </p>
              <Creatable
                isClearable
                isMulti
                components={{ DropdownIndicator: null }}
                inputValue={tagInputValue}
                menuIsOpen={false}
                onChange={(value) => handleChange("tags", value)}
                placeholder="Ketik tags.."
                onKeyDown={handleKeyDown}
                onInputChange={handleInputChange}
                value={tagValue}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: "#8f959a",
                    marginTop: "0.25rem",
                    paddingTop: "0.3125rem",
                    paddingBottom: "0.3125rem",
                    paddingLeft: "0.5rem",
                    borderRadius: "0.25rem",
                  }),
                }}
              />
              {valueList?.length === 0 && (
                <div className="invalid-feedback">Tags tidak boleh kosong</div>
              )}
            </div>

            <div className="col-span-1 lg:col-span-3 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Isi Artikel <span className="text-red-600">*</span>
              </p>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={quillChange}
                modules={modules}
                formats={formats}
              />
              {description?.length < 9 && (
                <div className="invalid-feedback">
                  Isi artikel tidak boleh kosong
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateArticle;
