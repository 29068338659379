import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, SearchBar } from "components";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import Table from "./components/Table";
import StatusBar from "./components/StatusBar";
import StatusDropdown from "./components/StatusDropdown";
import TypeDropdown from "./components/TypeDropdown";

export default function WithdrawRequest() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);

  // Get summary withdraw card
  const [summary, setSummary] = useState("");
  const fetchSummaryWithdraw = async () => {
    setLoading(true);
    try {
      const response = await UserService.getSummaryWithdraw();
      setSummary(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSummary(err.response.data.data);
    }
  };

  useEffect(() => {
    fetchSummaryWithdraw();
  }, []);

  // Get withdraw request list
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(new Set(["ALL"]));
  const statusValue = useMemo(() => Array.from(status), [status]);
  const [type, setType] = useState(new Set(["ALL"]));
  const typeValue = useMemo(() => Array.from(type), [type]);

  const fetchWithdraw = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getWithdrawList(
        page,
        perPage,
        keyword,
        typeValue,
        statusValue
      );
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchWithdraw(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getWithdrawList(
        page,
        newPerPage,
        keyword,
        typeValue,
        statusValue
      );
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchWithdraw(1);
  }, [refresh, keyword, statusValue, typeValue]);

  // toggle status
  const handleStatus = (status, id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} withdraw request ini.`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getWithdrawToggle(id, status).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} withdraw request.`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${error.response.data.message}`,
              });
            }
          }
        );
      }
    });
  };

  return (
    <>
      <div className="mx-3 lg:mx-7 mb-2">
        <div className="mb-2">
          <Text h3 size={22}>
            Withdraw Request List
          </Text>
        </div>
        <StatusBar
          totalRequest={summary?.total_request}
          totalNotPaid={summary?.total_has_not_paid}
          totalPaid={summary?.total_has_paid}
        />
      </div>

      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            <div className="mb-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <SearchBar
                setKeyword={setKeyword}
                placeholder="Cari Nama/Email Request"
              />
              <StatusDropdown
                statusValue={statusValue}
                status={status}
                setStatus={setStatus}
              />
              <TypeDropdown
                typeValue={typeValue}
                type={type}
                setType={setType}
              />
            </div>
            <Table
              data={data}
              loading={loading}
              totalRows={totalRows}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              navigate={navigate}
              pathname={pathname}
              handleStatus={handleStatus}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
