import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { ProjectTypeMap } from "constants";
import { ShortDateFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleDelete,
  navigate,
  pathname,
}) {
  const handleEdit = (row) => {
    if (row) {
      navigate(`/edit-project/${row.project_id}`);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Type = (row) => {
    return ProjectTypeMap[row.type];
  };

  const Period = (row) => (
    <div>
      <h6>{ShortDateFormat(row.start_period)}</h6>
      <h6>{ShortDateFormat(row.end_period)}</h6>
    </div>
  );

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}-detail/${row.project_id}`)}
    >
      Detail
    </Button>
  );

  const Action = () => (
    <></>
    // row.project_number.includes("PENKIT") &&
    // row.project_category === "blogger" &&
    // row.is_editable && (
    //   <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
    //     <Button size="xs" color="success" auto onClick={() => handleEdit(row)}>
    //       Edit
    //     </Button>
    //     <Button
    //       size="xs"
    //       color="error"
    //       auto
    //       onClick={() => handleDelete(row.project_id)}
    //     >
    //       Delete
    //     </Button>
    //   </div>
  );

  const columns = [
    {
      name: "No Project",
      selector: (row) => row.project_number,
      grow: 0.9,
      wrap: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.name,
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => (
        <span className="capitalize">{row.project_category}</span>
      ),
      grow: 0.5,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      grow: 0.5,
      wrap: true,
      cell: (row) => <Type {...row} />,
    },
    {
      name: "Period",
      selector: (row) => row.start_period,
      grow: 0.75,
      wrap: true,
      cell: (row) => <Period {...row} />,
    },
    {
      name: "Quota",
      selector: (row) => `${row.current_quota} / ${row.quota}`,
      width: "75px",
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "80px",
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => ShortDateFormat(row.created_at),
      grow: 0.8,
    },
    {
      name: "Detail",
      selector: (row) => row.project_id,
      width: "80px",
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      grow: 1,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
