import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { Currency, ShortDateTimeFormat } from "utils/helper";
import { WithdrawStatusMap } from "constants";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  navigate,
  pathname,
  handleStatus,
}) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Status = (row) => {
    return WithdrawStatusMap[row.status];
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`${pathname}/detail/${row.withdraw_id}`)}
    >
      Detail
    </Button>
  );

  const Action = (row) =>
    row.status === "PROCESSING" && (
      <Button
        size="xs"
        color="success"
        auto
        onClick={() => handleStatus("approve", row.withdraw_id)}
      >
        Approve
      </Button>
    );

  const columns = [
    {
      name: "Nama",
      selector: (row) =>
        (row.user_type === "blogger" && row.blogger.name) ||
        (row.user_type === "media" && row.media.companyName) ||
        (row.user_type === "consultant" && row.consultant.name),
      grow: 3,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) =>
        (row.user_type === "blogger" && row.blogger.email) ||
        (row.user_type === "media" && row.media.companyEmail) ||
        (row.user_type === "consultant" && row.consultant.email),
      grow: 3,
      wrap: true,
    },
    {
      name: "Kategori",
      selector: (row) => <span className="capitalize">{row.user_type}</span>,
      grow: 0.5,
      wrap: true,
    },
    {
      name: "Nominal",
      selector: (row) => Currency(row.total_amount),
      grow: 2.2,
      wrap: true,
      right: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      grow: 3,
      wrap: true,
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Tanggal Request",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      grow: 2.4,
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.detail,
      width: "80px",
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default Table;
