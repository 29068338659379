export default function Button(props) {
  return (
    <button
      onClick={props.onClick}
      className={`${props.disabled ? "h-10 px-4 py-2 bg-pk-gray-light text-login-gray-dark rounded-lg cursor-not-allowed focus:outline-none" : "h-10 px-4 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"}`}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
}
