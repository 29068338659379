import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack, Button } from "components";
import { imageicon } from "assets";

function CreateAnnouncement() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title tidak boleh kosong"),
    type: Yup.string().required("Tipe tidak boleh kosong"),
    hyperlink: Yup.string().required("Link tidak boleh kosong"),
    description: Yup.string()
      .required("Deskripsi tidak boleh kosong")
      .typeError("Deskripsi tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      type: "",
      hyperlink: "",
      description: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("announcement"));
      setImage(data?.backgroundImage);
      let defaults = {
        title: data?.title,
        type: data?.type,
        hyperlink: data?.hyperlink,
        description: data?.description,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const onSubmit = (e) => {
    return isCreateMode ? createAnnouncement(e) : editAnnouncement(id, e);
  };

  // Handle image preview
  const fileInput = useRef();
  const [image, setImage] = useState("");

  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        fileInput.current.value = "";
        window.alert("Ukuran gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  // Create announcement
  const createAnnouncement = (e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      return UserService.uploadImage(formData)
        .then((res) => {
          const imageUri = res.data.data.url;
          return UserService.createAnnouncement(
            e.title,
            e.type,
            e.hyperlink,
            e.hyperlink,
            e.description,
            imageUri
          ).then(() => {
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Announcement berhasil dibuat.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.createAnnouncement(
        e.title,
        e.type,
        e.hyperlink,
        e.hyperlink,
        e.description,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Announcement berhasil dibuat.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  // Edit announcement
  const editAnnouncement = (id, e) => {
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();
      return UserService.uploadImage(formData)
        .then((res) => {
          Alert.close();

          const imageUri = res.data.data.url;
          Alert.fire({
            title: "Mengubah announcement...",
          });
          Alert.showLoading();
          return UserService.updateAnnouncement(
            id,
            e.title,
            e.type,
            e.hyperlink,
            e.hyperlink,
            e.description,
            imageUri
          ).then(() => {
            Alert.close();
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Ubah announcement berhasil.",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("announcement");
                navigate(-1);
              }
            });
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return UserService.updateAnnouncement(
        id,
        e.title,
        e.type,
        e.hyperlink,
        e.hyperlink,
        e.description,
        image
      )
        .then(() => {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Ubah announcement berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("announcement");
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <Button text="Submit" />
          </div>
          <div className="flex flex-col">
            <p className="mb-2 text-xs text-login-gray-dark">
              Announcement Image
            </p>
            <label
              htmlFor="image"
              className={`${
                image ? "max-w-xs md:max-w-lg h-32 md:h-64" : "w-60 h-32"
              }`}
            >
              {image && (
                <img
                  src={image}
                  alt="preview"
                  className="max-w-xs md:max-w-lg h-32 md:h-64 rounded-lg object-scale-down"
                />
              )}
              {!image && (
                <div className="flex flex-col items-center justify-center w-60 h-32 rounded border border-transparent cursor-pointer bg-login-gray-light hover:border-pk-primary">
                  <img src={imageicon} alt="icon" />
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden"
              accept=".jpg, .jpeg, .png"
              ref={fileInput}
              onChange={handleImage}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11">
            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Title <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Judul announcement"
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Type <span className="text-red-600">*</span>
              </p>
              <select
                id="type"
                name="type"
                {...register("type")}
                className={`form-control-db ${errors.type ? "is-invalid" : ""}`}
              >
                <option value="">Pilih Tipe</option>
                <option value="blogger">Blogger</option>
                <option value="media">Media</option>
              </select>
              <div className="invalid-feedback">{errors.type?.message}</div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Link <span className="text-red-600">*</span>
              </p>
              <input
                name="hyperlink"
                type="url"
                {...register("hyperlink")}
                className={`form-control-db ${
                  errors.hyperlink ? "is-invalid" : ""
                }`}
                placeholder="https://"
              />
              <div className="invalid-feedback">
                {errors.hyperlink?.message}
              </div>
            </div>

            <div className="form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Description <span className="text-red-600">*</span>
              </p>
              <textarea
                id="description"
                name="description"
                {...register("description")}
                className={`form-control-db ${
                  errors.description ? "is-invalid" : ""
                } w-full h-36 text-sm focus:outline-none resize-none`}
                placeholder="Deskripsi announcement"
              />
              <div className="invalid-feedback">
                {errors.description?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateAnnouncement;
