import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, SearchBar } from "components";
import UserService from "services/user.service";
import { Card, Text } from "@nextui-org/react";
import Table from "./components/Table";
import SortDropdown from "./components/SortDropdown";
import MediaTypeDropdown from "./components/MediaTypeDropdown";
import UserTypeDropdown from "./components/UserTypeDropdown";

export default function Activity() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const sortBy = "ID";
  const [sort, setSort] = useState(new Set(["DESC"]));
  const sortValue = useMemo(() => Array.from(sort), [sort]);
  const [mediaType, setMediaType] = useState(new Set(["ALL"]));
  const mediaTypeValue = useMemo(() => Array.from(mediaType), [mediaType]);
  const [userType, setUserType] = useState(new Set(["ALL"]));
  const userTypeValue = useMemo(() => Array.from(userType), [userType]);

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getTransactionList(
        page,
        perPage,
        encodeURIComponent(keyword),
        mediaTypeValue,
        sortValue,
        userTypeValue,
        sortBy
      );
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchTransactions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getTransactionList(
        page,
        newPerPage,
        encodeURIComponent(keyword),
        mediaTypeValue,
        sortValue,
        userTypeValue,
        sortBy
      );
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchTransactions(1);
  }, [refresh, sortValue, mediaTypeValue, keyword, userTypeValue]);

  // toggle status
  const handleStatus = (status, id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} activity ini.`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getTransactionStatus(status, id).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} activity.`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="mx-3 mb-12 lg:mx-7">
      <div className="mb-3">
        <Text h3 size={22}>
          Activity List
        </Text>
      </div>
      <Card>
        <Card.Body>
          <div className="mb-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <SearchBar
              setKeyword={setKeyword}
              placeholder="Cari Nama/No Project"
            />
            <SortDropdown sortValue={sortValue} sort={sort} setSort={setSort} />
            <MediaTypeDropdown
              mediaTypeValue={mediaTypeValue}
              mediaType={mediaType}
              setMediaType={setMediaType}
            />
            <UserTypeDropdown
              userTypeValue={userTypeValue}
              userType={userType}
              setUserType={setUserType}
            />
          </div>
          <Table
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            navigate={navigate}
            pathname={pathname}
            handleStatus={handleStatus}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
