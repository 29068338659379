import { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Button, ButtonBack, Alert } from "components";
import CourseMaterial from "./CourseMaterial";

function CourseTask() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const courseData = JSON.parse(localStorage.getItem("courseData"));
  const [courseTask, setCourseTask] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setRefresh] = useState(1);
  const [changeTab, setChangeTab] = useState(true);

  // Get task list + pagination
  const fetchTask = async (page) => {
    try {
      setLoading(true);

      const response = await UserService.getCourseTask(id, page, perPage);

      setCourseTask(response.data.data.tasks);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCourseTask(err.response.data.data.tasks);
    }
  };

  const handlePageChange = (page) => {
    fetchTask(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);

      const response = await UserService.getCourseTask(id, page, newPerPage);

      setCourseTask(response.data.data.tasks);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCourseTask(err.response.data.data.tasks);
    }
  };

  useEffect(() => {
    fetchTask(1);
  }, [refresh]);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Course Task List"
        columns={columns}
        data={courseTask}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    );
  }
  // Table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Action = (row) => (
    <div>
      <button
        className="link text-green-600 font-bold underline focus:outline-none"
        onClick={() => handleEdit(row)}
      >
        Edit
      </button>

      <span className="mx-1">-</span>

      <button
        className="link text-red-600 font-bold underline focus:outline-none"
        onClick={(e) => handleDelete(e, id, row.courseTaskId)}
      >
        Delete
      </button>
    </div>
  );

  const columns = [
    {
      name: "Task Id",
      selector: (row) => row.courseTaskId,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      grow: 2,
      format: (row) =>
        `${
          row.title.length > 100 ? row.title.slice(0, 100) + "..." : row.title
        }`,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      grow: 3.8,
      format: (row) =>
        `${
          row.description.length > 100
            ? row.description.slice(0, 100) + "..."
            : row.description
        }`,
    },
    {
      name: "Duration",
      selector: (row) => Math.floor(row.duration / (24 * 3600)) + " hari",
      center: true,
      width: "95px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => <Action {...row} />,
    },
  ];

  // Edit Course Task
  const handleEdit = (row) => {
    if (row) {
      localStorage.setItem("editCourseTask", JSON.stringify(row));
      navigate(`${pathname}/task/${row.courseTaskId}/edit`);
    }
  };

  // Delete Course Task
  const handleDelete = (e, id, taskId) => {
    e.preventDefault();
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat mengembalikan Course Task yang telah dihapus!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteCourseTask(id, taskId).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Course Task telah berhasil dihapus.",
              });
            }
          },
          (error) => {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${error.response.data.message}`,
            });
          }
        );
      }
    });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white mx-3 mb-8 lg:mx-7 rounded-xl px-6 pb-4 shadow-section">
        <div className="pt-4">
          {/* Course detail */}
          {courseData ? (
            <>
              <div className="py-2 flex flex-wrap -mx-px overflow-hidden space-y-2 lg:space-y-0">
                <div className="overflow-hidden w-full lg:w-2/12">
                  <p className="text-xs text-login-gray-dark">Category Id</p>
                  <p className="mt-2 text-black font-bold">
                    {courseData.categoryId}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-2/12">
                  <p className="text-xs text-login-gray-dark">Redeem Points</p>
                  <p className="mt-2 text-black font-bold">
                    {courseData.price.toLocaleString("id")}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-2/12">
                  <p className="text-xs text-login-gray-dark">Followers</p>
                  <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                    {courseData.followers}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-2/12">
                  <p className="text-xs text-login-gray-dark">Rating</p>
                  <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                    {courseData.rating}
                  </p>
                </div>

                <div className="overflow-hidden w-full lg:w-2/12">
                  <p className="text-xs text-login-gray-dark">Reward Points</p>
                  <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                    {courseData.rewardPoints}
                  </p>
                </div>
              </div>
              <hr />
              {/* Row 2 */}
              <div className="pt-2 flex flex-col justify-between lg:flex-row lg:space-x-4">
                <div className="w-11/12 flex flex-col">
                  <div className="flex flex-col lg:flex-row">
                    <div className="overflow-hidden w-full lg:w-1/5">
                      <p className="text-xs text-login-gray-dark">Course Id</p>
                      <p className="mt-2 text-black font-bold">
                        {courseData.courseId}
                      </p>
                    </div>

                    <div className="pt-2 lg:pl-1 lg:pt-0 overflow-hidden w-full lg:w-10/12">
                      <p className="text-xs text-login-gray-dark">Title</p>
                      <p className="mt-2 text-black font-bold">
                        {courseData.title}
                      </p>
                    </div>
                  </div>

                  <div className="pt-2">
                    <p className="text-xs text-login-gray-dark">Description</p>
                    <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                      {courseData.description.length > 320
                        ? `${courseData.description.slice(0, 320)}...`
                        : courseData.description}
                    </p>
                  </div>
                </div>

                <div className="pt-2 lg:pt-0">
                  <p className="text-xs text-login-gray-dark">Image</p>
                  <div className="w-40 h-40">
                    <img
                      src={courseData.image}
                      alt={courseData.courseId}
                      className="mt-2 w-40 h-40 object-cover"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="pt-2 flex flex-wrap -mx-px overflow-hidden space-y-2 md:space-y-0">
                  <div className="overflow-hidden w-full md:w-1/3 lg:w-1/12">
                    <p className="text-xs text-login-gray-dark">Course Id</p>
                    <p className="mt-2 text-black font-bold">
                      {courseData.courseId}
                    </p>
                  </div>

                  <div className="overflow-hidden w-full md:w-1/3 lg:w-4/12">
                    <p className="text-xs text-login-gray-dark">Title</p>
                    <p className="mt-2 text-black font-bold">
                      {courseData.title}
                    </p>
                  </div>

                  <div className="overflow-hidden w-full md:w-1/3 lg:w-5/12">
                    <p className="text-xs text-login-gray-dark">Description</p>
                    <p className="mt-2 text-black font-bold whitespace-pre-wrap">
                      {courseData.description.length > 320
                        ? `${courseData.description.slice(0, 320)}...`
                        : courseData.description}
                    </p>
                  </div>

                  <div className="overflow-hidden w-full md:w-1/3 lg:w-2/12">
                    <p className="text-xs text-login-gray-dark">Image</p>
                    <div className="w-40 h-40">
                      <img
                        src={courseData.image}
                        alt={courseData.courseId}
                        className="mt-2 w-40 h-40 object-cover"
                      />
                    </div>
                  </div>
                </div> */}
            </>
          ) : (
            <span className="flex items-center justify-center">No Data</span>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="mx-3 lg:mx-7 flex space-x-2">
        <button
          className={`${
            changeTab ? "bg-pk-primary" : "bg-pk-gray-light"
          } h-10 px-4 py-2 text-black rounded-t-xl duration-200 ease-in-out hover:shadow-lg focus:outline-none`}
          onClick={() => setChangeTab(true)}
        >
          Course Task List
        </button>
        <button
          className={`${
            changeTab ? "bg-pk-gray-light" : "bg-pk-primary"
          } h-10 px-4 py-2 text-black rounded-t-xl duration-200 ease-in-out hover:shadow-lg focus:outline-none`}
          onClick={() => setChangeTab(false)}
        >
          Course Material
        </button>
      </div>
      <div className="bg-white mx-3 mb-16 lg:mx-7 rounded-tr-xl rounded-b-xl px-6 pb-4 shadow-section">
        {changeTab ? (
          <div className="pt-6 pb-8">
            <div className="flex flex-col items-end">
              <Link to={`${pathname}/task/add`}>
                <Button text="Add New Course Task" />
              </Link>
            </div>
            <div className="rounded-xl">{TableComponent()}</div>
          </div>
        ) : (
          <CourseMaterial courseId={id} />
        )}
      </div>
    </>
  );
}

export default CourseTask;
