import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, ButtonBack } from "components";
import UserService from "services/user.service";

function SurveyGoal() {
  // Get survey goals list
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGoal = async () => {
    try {
      setLoading(true);

      const response = await UserService.getSurveyGoal();

      setData(response.data.data.goals);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.goals);
    }
  };

  useEffect(() => {
    fetchGoal();
  }, []);

  // Table
  function TableComponent() {
    return (
      <DataTable
        title="Survey Goals List"
        columns={columns}
        data={data}
        progressPending={loading}
        customStyles={customStyles}
      />
    );
  }
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "Goal Id",
      selector: (row) => row.goalId,
      width: "200px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
    },
  ];

  return (
    <>
      <ButtonBack url="/home" />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-4">
        <div className="pt-4 flex flex-row justify-end">
          <Link to={`/pdp/survey/goal/add`}>
            <Button text="Add New Survey Goal" />
          </Link>
        </div>
        {TableComponent()}
      </div>
    </>
  );
}

export default SurveyGoal;
