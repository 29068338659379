import DataTable from "react-data-table-component";
import { Button } from "@nextui-org/react";
import { Currency, ShortDateFormat } from "utils/helper";
import { StatusTransactionMap } from "constants";

function TableTrans({ data, loading, navigate, handleStatus }) {
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const Status = (row) => {
    return StatusTransactionMap[row.status];
  };

  const Detail = (row) => (
    <Button
      size="xs"
      color="default"
      auto
      onClick={() => navigate(`/client-transaction/detail/${row.id}`)}
    >
      Detail
    </Button>
  );

  const Action = (row) =>
    row.status === "ONCHECKING" && (
      <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-1">
        <Button
          size="xs"
          color="success"
          auto
          onClick={() => handleStatus("ACCEPTED", row.id, row.project.name)}
        >
          Accept
        </Button>
        <Button
          size="xs"
          color="error"
          auto
          onClick={() => handleStatus("REJECTED", row.id, row.project.name)}
        >
          Reject
        </Button>
      </div>
    );

  const columns = [
    {
      name: "Number",
      selector: (row) => row.project.projectNumber,
      grow: 1.15,
      wrap: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.project.name,
      grow: 1.5,
      wrap: true,
    },
    {
      name: "Kategori",
      selector: (row) => <div className="capitalize">{row.type}</div>,
      width: "90px",
      wrap: true,
    },
    {
      name: "Client",
      selector: (row) => row.client.company_name,
      width: "90px",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      grow: 1.2,
      wrap: true,
      cell: (row) => <Status {...row} />,
    },
    {
      name: "Total Budget",
      selector: (row) => Currency(row.total_budget),
      width: "130px",
      wrap: true,
      right: true,
    },
    {
      name: "Created",
      selector: (row) => ShortDateFormat(row.created_at),
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => row.detail,
      width: "75px",
      wrap: true,
      cell: (row) => <Detail {...row} />,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      grow: 1.2,
      wrap: true,
      cell: (row) => <Action {...row} />,
    },
  ];

  return (
    <div className="rounded-xl">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        persistTableHead
        customStyles={customStyles}
      />
    </div>
  );
}

export default TableTrans;
