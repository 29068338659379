import Dashboard from "pages/Dashboard";
import Internal from "pages/UserInternal";
import CreateUser from "pages/CreateUser";
import Blogger from "pages/UserBlogger";
import BloggerDetail from "pages/UserBloggerDetail";
import Media from "pages/UserMedia";
import MediaDetail from "pages/UserMediaDetail";
import MediaBalance from "pages/UserMediaBalance";
import Client from "pages/UserClient";
import ClientDetail from "pages/UserClientDetail";
import Konsultan from "pages/UserKonsultan";
import KonsultanDetail from "pages/UserKonsultanDetail";
import Project from "pages/Project";
import ProjectDetail from "pages/Project/ProjectDetail";
import CreateTask from "pages/Project/CreateTask";
import TaskDetail from "pages/Project/TaskDetail";
import TaskFeedback from "pages/Project/TaskFeedback";
import CreateProject from "pages/Project/CreateProject";
import InviteBlogger from "pages/Project/InviteBlogger";
import InviteMedia from "pages/Project/InviteMedia";
import Activity from "pages/Activity";
import ActivityDetail from "pages/ActivityDetail";
import ClientTransaction from "pages/ClientTransaction";
import ClientTransactionDetail from "pages/ClientTransactionDetail";
import WithdrawRequest from "pages/WithdrawRequest";
import WithdrawRequestDetail from "pages/WithdrawRequestDetail";
import BalanceHistory from "pages/BalanceHistory";
import BalanceHistoryDetail from "pages/BalanceHistoryDetail";
import Tier from "pages/Tier";
import TierDetail from "pages/TierDetail";
import CreateTier from "pages/CreateTier";
import Articles from "pages/Articles";
import CreateArticle from "pages/CreateArticle";
import Faq from "pages/Faq";
import CreateFaq from "pages/CreateFaq";
import Voucher from "pages/Voucher";
import VoucherDetail from "pages/VoucherDetail";
import CreateVoucher from "pages/CreateVoucher";
import Announcement from "pages/Announcement";
import CreateAnnouncement from "pages/CreateAnnouncement";
import Schedule from "pages/Schedule";
import CreateSchedule from "pages/CreateSchedule";
import Course from "pages/course/Course";
import CreateCourse from "pages/course/CreateCourse";
import CourseTask from "pages/course/CourseTask";
import CreateCourseTask from "pages/course/CreateCourseTask";
import CreateCourseMaterial from "pages/course/CreateCourseMaterial";
import Category from "pages/course/Category";
import CreateCategory from "pages/course/CreateCategory";
import SurveyGoal from "pages/survey/SurveyGoal";
import CreateSurveyGoal from "pages/survey/CreateSurveyGoal";
import SurveyQuestion from "pages/survey/SurveyQuestion";
import CreateSurveyQuestion from "pages/survey/CreateSurveyQuestion";
import Launchpad from "pages/launchpad/Launchpad";
import CreateLaunchpad from "pages/launchpad/CreateLaunchpad";
import Forum from "pages/forum/Forum";
import CreateForum from "pages/forum/CreateForum";
import ForumDetail from "pages/forum/ForumDetail";
import CreateForumComment from "pages/forum/CreateForumComment";

const routes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "internal",
    element: <Internal />,
  },
  {
    path: "create-user",
    element: <CreateUser />,
  },
  {
    path: "edit-user/:id",
    element: <CreateUser />,
  },
  {
    path: "blogger",
    element: <Blogger />,
  },
  {
    path: "blogger",
    element: <Blogger />,
  },
  {
    path: "blogger/detail/:id",
    element: <BloggerDetail />,
  },
  {
    path: "media",
    element: <Media />,
  },
  {
    path: "media/detail/:id",
    element: <MediaDetail />,
  },
  {
    path: "media/balance/:id",
    element: <MediaBalance />,
  },
  {
    path: "client",
    element: <Client />,
  },
  {
    path: "client/detail/:id",
    element: <ClientDetail />,
  },
  {
    path: "konsultan",
    element: <Konsultan />,
  },
  {
    path: "konsultan/detail/:id",
    element: <KonsultanDetail />,
  },
  {
    path: "project",
    element: <Project />,
  },
  {
    path: "project-detail/:id",
    element: <ProjectDetail />,
  },
  {
    path: "project-detail/:id/create-task",
    element: <CreateTask />,
  },
  {
    path: "project-detail/:id/task/:id",
    element: <TaskDetail />,
  },
  {
    path: "project-detail/:id/task/:id/submission/:id",
    element: <TaskFeedback />,
  },
  {
    path: "create-project",
    element: <CreateProject />,
  },
  {
    path: "edit-project/:id",
    element: <CreateProject />,
  },
  {
    path: "create-project/invite/blogger",
    element: <InviteBlogger />,
  },
  {
    path: "create-project/invite/media",
    element: <InviteMedia />,
  },
  {
    path: "activity",
    element: <Activity />,
  },
  {
    path: "activity/activity-detail/:id",
    element: <ActivityDetail />,
  },
  {
    path: "client-transaction",
    element: <ClientTransaction />,
  },
  {
    path: "client-transaction/detail/:id",
    element: <ClientTransactionDetail />,
  },
  {
    path: "withdraw-request",
    element: <WithdrawRequest />,
  },
  {
    path: "withdraw-request/detail/:id",
    element: <WithdrawRequestDetail />,
  },
  {
    path: "balance-history",
    element: <BalanceHistory />,
  },
  {
    path: "balance-history/detail/:id",
    element: <BalanceHistoryDetail />,
  },
  {
    path: "tier",
    element: <Tier />,
  },
  {
    path: "tier/add",
    element: <CreateTier />,
  },
  {
    path: "tier/detail/:tier/:type",
    element: <TierDetail />,
  },
  {
    path: "tier/edit/:tier/:type",
    element: <CreateTier />,
  },
  {
    path: "articles",
    element: <Articles />,
  },
  {
    path: "articles/add",
    element: <CreateArticle />,
  },
  {
    path: "articles/edit/:id",
    element: <CreateArticle />,
  },
  {
    path: "faq",
    element: <Faq />,
  },
  {
    path: "faq/add",
    element: <CreateFaq />,
  },
  {
    path: "faq/edit/:id",
    element: <CreateFaq />,
  },
  {
    path: "voucher",
    element: <Voucher />,
  },
  {
    path: "voucher/detail/:id",
    element: <VoucherDetail />,
  },
  {
    path: "voucher/add",
    element: <CreateVoucher />,
  },
  {
    path: "voucher/edit/:id",
    element: <CreateVoucher />,
  },
  {
    path: "announcement",
    element: <Announcement />,
  },
  {
    path: "announcement/add",
    element: <CreateAnnouncement />,
  },
  {
    path: "announcement/edit/:id",
    element: <CreateAnnouncement />,
  },
  {
    path: "pdp/schedule",
    element: <Schedule />,
  },
  {
    path: "pdp/schedule/add",
    element: <CreateSchedule />,
  },
  {
    path: "pdp/schedule/edit/:id",
    element: <CreateSchedule />,
  },
  {
    path: "pdp/course",
    element: <Course />,
  },
  {
    path: "pdp/course-add",
    element: <CreateCourse />,
  },
  {
    path: "pdp/course/:id",
    element: <CourseTask />,
  },
  {
    path: "pdp/course/:id/task/add",
    element: <CreateCourseTask />,
  },
  {
    path: "pdp/course/:id/task/:taskId/edit",
    element: <CreateCourseTask />,
  },
  {
    path: "pdp/course/:id/material/add",
    element: <CreateCourseMaterial />,
  },
  {
    path: "pdp/course/:id/material/:materialId/edit",
    element: <CreateCourseMaterial />,
  },
  {
    path: "pdp/category",
    element: <Category />,
  },
  {
    path: "pdp/category/add",
    element: <CreateCategory />,
  },
  {
    path: "pdp/category/:id/edit",
    element: <CreateCategory />,
  },
  {
    path: "pdp/survey/goal",
    element: <SurveyGoal />,
  },
  {
    path: "pdp/survey/goal/add",
    element: <CreateSurveyGoal />,
  },
  {
    path: "pdp/survey/question",
    element: <SurveyQuestion />,
  },
  {
    path: "pdp/survey/question/add",
    element: <CreateSurveyQuestion />,
  },
  {
    path: "pdp/survey/question/:id/edit",
    element: <CreateSurveyQuestion />,
  },
  {
    path: "pdp/launchpad",
    element: <Launchpad />,
  },
  {
    path: "pdp/launchpad/add",
    element: <CreateLaunchpad />,
  },
  {
    path: "pdp/launchpad/:id/edit/",
    element: <CreateLaunchpad />,
  },
  {
    path: "pdp/forum",
    element: <Forum />,
  },
  {
    path: "pdp/forum/add",
    element: <CreateForum />,
  },
  {
    path: "pdp/forum/:id/edit",
    element: <CreateForum />,
  },
  {
    path: "pdp/forum/:id/comment",
    element: <ForumDetail />,
  },
  {
    path: "pdp/forum/:id/comment/add",
    element: <CreateForumComment />,
  },
];

export default routes;
