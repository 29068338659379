import { Dropdown } from "@nextui-org/react";

function SortDropdown({ sortValue, sort, setSort }) {
  const SortMap = {
    DESC: "Terbaru",
    ASC: "Terlama",
  };
  const sortDatas = Object.entries(SortMap);

  return (
    <Dropdown>
      <Dropdown.Button flat>{SortMap[sortValue]}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Dropdown"
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={sort}
        onSelectionChange={setSort}
      >
        {sortDatas.map((data) => (
          <Dropdown.Item key={data[0]}>{data[1]}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SortDropdown;
