import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Text } from "@nextui-org/react";
import { Alert } from "components";
import UserService from "services/user.service";
import StatusBar from "./components/StatusBar";
import Table from "./components/Table";
import TableTrans from "./components/TableTrans";
import TableWithdraw from "./components/TableWithdraw";

function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogger, setBlogger] = useState(null);
  const [media, setMedia] = useState(null);
  const [client, setClient] = useState(null);
  const [budget, setBudget] = useState(null);

  useEffect(() => {
    const fetchProject = async (page) => {
      setLoading(true);
      try {
        const response = await UserService.getProjectMaster(
          page,
          10,
          "",
          "OPEN",
          ""
        );
        setData(response.data.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setData(err.response.data.data.data);
      }
    };

    fetchProject(1);
  }, []);

  const fetchWidget = async () => {
    try {
      const response = await UserService.getSummaryDashboard();

      setBlogger(response.data.data[0].totalblogger);
      setMedia(response.data.data[0].totalmedia);
      setClient(response.data.data[0].totalclients);
      setBudget(response.data.data[0].totalbudget);
    } catch (err) {
      console.log(err);
    }
  };

  // get transaction
  const [dataTrans, setDataTrans] = useState([]);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const fetchTransactions = async () => {
    setLoadingTrans(true);
    try {
      const response = await UserService.getClientTransactionListPrev(10);
      setDataTrans(response.data.data);
      setLoadingTrans(false);
    } catch (err) {
      setLoadingTrans(false);
      setDataTrans(err.response.data.data);
    }
  };

  const handleStatus = (status, id, project) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} activity ${project}`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.updateClientTransactionStatus(id, status).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} activity ${project}`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: "Silahkan coba kembali.",
              });
            }
          }
        );
      }
    });
  };

  // get withdraw req
  const [dataWithdraw, setDatawithdraw] = useState([]);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  const fetchWithdraw = async () => {
    setLoadingWithdraw(true);
    try {
      const response = await UserService.getWithdrawListPrev("10");
      setDatawithdraw(response.data.data.data);
      setLoadingWithdraw(false);
    } catch (err) {
      setLoadingWithdraw(false);
      setDatawithdraw(err.response.data.data.data);
    }
  };

  const handleStatusWithdraw = (status, id) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan ${status} withdraw request ini.`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getWithdrawToggle(id, status).then(
          (response) => {
            if (response) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Anda telah berhasil ${status} withdraw request.`,
              });
            }
          },
          (error) => {
            if (error) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${error.response.data.message}`,
              });
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    fetchWidget();
  }, []);

  useEffect(() => {
    fetchWithdraw();
    fetchTransactions();
  }, [refresh]);

  return (
    <>
      <div className="mx-3 lg:mx-7 mb-2">
        <div className="mb-2">
          <Text h3 size={22}>
            Dashboard
          </Text>
        </div>
        <StatusBar
          totalBlogger={blogger}
          totalMedia={media}
          totalClient={client}
          totalBudget={budget}
        />
      </div>

      <div className="mx-3 space-y-2 mb-12 lg:mx-7">
        <div className="text-lg">Projects</div>
        <Card>
          <Card.Body>
            <Table data={data} loading={loading} />
          </Card.Body>
        </Card>
      </div>
      <div className="mx-3 space-y-2 mb-12 lg:mx-7">
        <div className="text-lg">Transactions</div>
        <Card>
          <Card.Body>
            <TableTrans
              data={dataTrans}
              loading={loadingTrans}
              navigate={navigate}
              handleStatus={handleStatus}
            />
          </Card.Body>
        </Card>
      </div>
      <div className="mx-3 space-y-2 mb-12 lg:mx-7">
        <div className="text-lg">Withdraw Request</div>
        <Card>
          <Card.Body>
            <TableWithdraw
              data={dataWithdraw}
              loading={loadingWithdraw}
              navigate={navigate}
              handleStatus={handleStatusWithdraw}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default Dashboard;
