import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading } from "@nextui-org/react";
import { DateFormat, Currency } from "utils/helper";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";

export default function TierDetail() {
  const { tier, type } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getTierDetail(tier, type);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [tier, type]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-96">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!data && (
              <span className="flex items-center justify-center h-96 font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && data && (
              <div className="lg:p-5 flex flex-col lg:flex-row">
                <ul className="w-full flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Id</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">{data?.id}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Tier</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.tier}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-semibold">Kategori</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark capitalize">
                          {data?.type}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Nominal</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          <span className="text-login-gray-dark">
                            {Currency(data?.price)}
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Status Special Tier</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark break-all">
                          {data?.is_special_tier === 1 ? (
                            <div className="text-green-500 flex items-center space-x-1">
                              <span>Special Tier</span>
                              <HiCheckCircle size={20} />
                            </div>
                          ) : (
                            <div className="text-red-500 flex items-center space-x-1">
                              <span>Bukan Special Tier</span>
                              <HiXCircle size={20} />
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Dibuat pada</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_at && DateFormat(data?.created_at)}
                          {!data?.created_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Dibuat oleh</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_by && data.created_by}
                          {!data?.created_by && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Diperbarui pada</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_at && DateFormat(data?.updated_at)}
                          {!data?.updated_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Diperbarui oleh</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_by && data.updated_by}
                          {!data?.updated_by && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
