import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "services/user.service";
import { Alert, ButtonBack } from "components";
import { format } from "date-fns";

function CreateSchedule() {
  const { id } = useParams();
  const isCreateMode = !id;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Judul tidak boleh kosong"),
    location: Yup.string().required("Lokasi tidak boleh kosong"),
    dueDate: Yup.string().required("Due date tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      location: "",
      dueDate: "",
    },
  });

  useEffect(() => {
    if (!isCreateMode) {
      let data = JSON.parse(localStorage.getItem("editSchedule"));
      const today = new Date();
      const yearNum = today.getFullYear();
      const year = yearNum.toString();
      const time = data?.time;
      const date = data?.dueDate + " " + year;
      const dateConvert = date.split(" ").join("-");
      const timeConvert = time.split(".").join(":");
      const dueDate = format(new Date(dateConvert), "yyyy-MM-dd");
      const datetime = dueDate + "T" + timeConvert;
      let defaults = {
        title: data?.title,
        location: data?.location,
        dueDate: datetime,
      };
      reset(defaults);
    }
  }, [reset, isCreateMode]);

  const convertUtc = (date) => {
    let convert = new Date(date);
    return convert.toISOString().substring(0, 16);
  };

  const onSubmit = (e) => {
    return isCreateMode ? createSchedule(e) : editSchedule(id, e);
  };

  const createSchedule = (e) => {
    const utcDate = convertUtc(e.dueDate);

    return UserService.createSchedule(e.title, e.location, utcDate)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Schedule berhasil dibuat.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  const editSchedule = (id, e) => {
    const utcDate = convertUtc(e.dueDate);

    return UserService.updateSchedule(id, e.title, e.location, utcDate)
      .then(() => {
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Edit Schedule berhasil.",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("editSchedule");
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: "error",
          title: "Terjadi kesalahan",
          text: `Pesan error: ${error.response.data.message}`,
        });
      });
  };

  return (
    <>
      <ButtonBack />
      <div className="bg-white shadow-section mx-3 mb-12 lg:mx-7 rounded-xl px-6 pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-4 flex flex-row justify-end">
            <button
              type="submit"
              className="h-10 px-5 py-2 bg-pk-primary text-black rounded-lg duration-200 ease-in-out hover:shadow-lg"
            >
              Submit
            </button>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11">
            <div className="col-span-1 lg:col-span-2 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Judul <span className="text-red-600">*</span>
              </p>
              <input
                name="title"
                type="text"
                {...register("title")}
                className={`form-control-db ${
                  errors.title ? "is-invalid" : ""
                }`}
                placeholder="Ketik judul.."
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Lokasi <span className="text-red-600">*</span>
              </p>
              <input
                name="location"
                type="text"
                {...register("location")}
                className={`form-control-db ${
                  errors.location ? "is-invalid" : ""
                }`}
                placeholder="Ketik lokasi.."
              />
              <div className="invalid-feedback">{errors.location?.message}</div>
            </div>

            <div className="col-span-1 form-group relative">
              <p className="mb-1 text-xs text-login-gray-dark">
                Due Date <span className="text-red-600">*</span>
              </p>
              <input
                name="dueDate"
                type="datetime-local"
                min={new Date().toISOString().substring(0, 16)}
                {...register("dueDate")}
                className={`form-control-db ${
                  errors.dueDate ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">{errors.dueDate?.message}</div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateSchedule;
