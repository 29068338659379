export const ProvinceList = [
  {
    id: 1,
    name: "BALI",
  },
  {
    id: 2,
    name: "BANGKA BELITUNG",
  },
  {
    id: 3,
    name: "BANTEN",
  },
  {
    id: 4,
    name: "BENGKULU",
  },
  {
    id: 5,
    name: "DI YOGYAKARTA",
  },
  {
    id: 6,
    name: "DKI JAKARTA",
  },
  {
    id: 7,
    name: "GORONTALO",
  },
  {
    id: 8,
    name: "JAMBI",
  },
  {
    id: 9,
    name: "JAWA BARAT",
  },
  {
    id: 10,
    name: "JAWA TENGAH",
  },
  {
    id: 11,
    name: "JAWA TIMUR",
  },
  {
    id: 12,
    name: "KALIMANTAN BARAT",
  },
  {
    id: 13,
    name: "KALIMANTAN SELATAN",
  },
  {
    id: 14,
    name: "KALIMANTAN TENGAH",
  },
  {
    id: 15,
    name: "KALIMANTAN TIMUR",
  },
  {
    id: 16,
    name: "KALIMANTAN UTARA",
  },
  {
    id: 17,
    name: "KEPULAUAN RIAU",
  },
  {
    id: 18,
    name: "LAMPUNG",
  },
  {
    id: 19,
    name: "MALUKU",
  },
  {
    id: 20,
    name: "MALUKU UTARA",
  },
  {
    id: 21,
    name: "NANGGROE ACEH DARUSSALAM (NAD)",
  },
  {
    id: 22,
    name: "NUSA TENGGARA BARAT (NTB)",
  },
  {
    id: 23,
    name: "NUSA TENGGARA TIMUR (NTT)",
  },
  {
    id: 24,
    name: "PAPUA",
  },
  {
    id: 25,
    name: "PAPUA BARAT",
  },
  {
    id: 26,
    name: "RIAU",
  },
  {
    id: 27,
    name: "SULAWESI BARAT",
  },
  {
    id: 28,
    name: "SULAWESI SELATAN",
  },
  {
    id: 29,
    name: "SULAWESI TENGAH",
  },
  {
    id: 30,
    name: "SULAWESI TENGGARA",
  },
  {
    id: 31,
    name: "SULAWESI UTARA",
  },
  {
    id: 32,
    name: "SUMATERA BARAT",
  },
  {
    id: 33,
    name: "SUMATERA SELATAN",
  },
  {
    id: 34,
    name: "SUMATERA UTARA",
  },
];
