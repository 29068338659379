import AuthService from "services/auth.service";
import { format, parseISO } from "date-fns";
import { Card, Text, Avatar } from "@nextui-org/react";
import { logo } from "assets";

function Home() {
  const currentUser = AuthService.getCurrentUser();

  return (
    <div className="pt-4 mx-3 mb-12 lg:mx-7 rounded-xl">
      <Card
        css={{
          padding: 20,
        }}
      >
        <Text className="text-xl text-center">
          Selamat datang di Dashboard Penakita
        </Text>
        <Card.Header>
          <Avatar
            src={currentUser?.photo ? currentUser.photo : logo}
            css={{ size: "$40", mt: 16, mx: "auto" }}
            color="gradient"
            bordered
          />
        </Card.Header>
        <Card.Body>
          <div className="mt-4 flex justify-center">
            <ul className="w-full lg:w-4/12 flex flex-col space-y-4">
              <li>
                <div className="flex">
                  <div className="w-1/2">
                    <span className="font-bold">Name</span>
                  </div>
                  <div className="">
                    <span className="text-login-gray-dark">
                      {currentUser.name}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex">
                  <div className="w-1/2">
                    <span className="font-bold">Email</span>
                  </div>
                  <div className="w-1/2">
                    <span className="text-login-gray-dark">
                      {currentUser.email}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex">
                  <div className="w-1/2">
                    <span className="font-semibold">Role</span>
                  </div>
                  <div className="w-1/2">
                    <span className="text-login-gray-dark">
                      {currentUser.role}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex">
                  <div className="w-1/2">
                    <span className="font-bold">Last Login</span>
                  </div>
                  <div className="w-1/2">
                    <span className="text-login-gray-dark">
                      {currentUser.last_login &&
                        format(
                          parseISO(currentUser.last_login),
                          "d MMMM y HH:mm"
                        )}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Home;
