import DataTable from "react-data-table-component";
import { Currency, ShortDateTimeFormat } from "utils/helper";

function Table({
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
}) {
  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#FF9D25",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "Tgl Daftar",
      selector: (row) => ShortDateTimeFormat(row.created_at),
      wrap: true,
    },
    {
      name: "Media",
      selector: (row) => row.company_name,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.company_email,
      wrap: true,
    },
    {
      name: "Email PIC",
      selector: (row) => row.pic_email,
      wrap: true,
    },
    {
      name: "Rate Korporasi",
      selector: (row) => Currency(row.rate_publisher_brand_content),
      grow: 1.05,
      wrap: true,
    },
    {
      name: "Rate UMKM",
      selector: (row) => Currency(row.rate_sme),
      wrap: true,
    },
    {
      name: "Total Balance",
      selector: (row) => Currency(row.total_balance),
      wrap: true,
    },
  ];

  return (
    <>
      <p className="px-3 lg:px-8 pb-2 text-lg font-bold">Referral List</p>
      <div className="rounded-xl px-2 lg:px-8 pb-8">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          persistTableHead
          customStyles={customStyles}
        />
      </div>
    </>
  );
}

export default Table;
