import { styled } from "utils/theme";
import { Collapse as BaseCollapse } from "@nextui-org/react";
import { NavLink as BaseNavLink } from "react-router-dom";

export const StyledSidebar = styled("nav", {
  width: 256,
  height: "100vh",
  background: "#363740",
  position: "fixed",
  overflow: "auto",
});

export const StyledLi = styled("li", {
  listStyle: "none",
  display: "flex",
  padding: "2px 8px",
  margin: 0,
});

export const StyledLiMobile = styled("li", {
  listStyle: "none",
  display: "flex",
  padding: "2px 8px",
  margin: 0,
  borderRadius: "8px",
  "&:hover, &.active": {
    backgroundColor: "#C5C7CD",
  },
});

export const Collapse = styled(BaseCollapse, {
  padding: "2px 0.5rem",
  color: "#A4A6B3",
  border: "none",
  ".menu-icon": {
    svg: {
      fontSize: 24,
    },
  },
  "& > div[role=button]": {
    transition: "background .3s ease",
    borderRadius: "8px",
    padding: "0.75rem 0.5rem",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.1)",
    },
  },
  "&[data-state=closed]": {
    ".nextui-collapse-title-content-right": {
      svg: {
        transform: "rotateZ(270deg)",
      },
    },
  },
  "&[data-state=open]": {
    ".nextui-collapse-title-content-right": {
      svg: {
        transform: "rotateZ(90deg)",
      },
    },
  },
  ".nextui-collapse-content": {
    padding: "0",
  },
});

export const NavLink = styled(BaseNavLink, {
  color: "#A4A6B3",
  textDecoration: "none",
  padding: "0.75rem 0.5rem",
  width: "100%",
  transition: "background .3s ease",
  borderRadius: "8px",
  "&:hover, &.active": {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  "&.active": {
    color: "#DDE2FF",
  },
  ".menu-icon": {
    svg: {
      fontSize: 24,
    },
  },
});
