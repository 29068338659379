export const inputSearchStyles = {
  css: {
    ".nextui-input-content--right": {
      backgroundColor: "#C5C7CD",
      color: "white",
      borderTopRightRadius: "0.75rem",
      borderBottomRightRadius: "0.75rem",
      width: "32px"
    },
  }
}