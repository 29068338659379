import { Input } from "@nextui-org/react";
import { searchicon } from "assets";
import { inputSearchStyles } from "utils/styles";

function SearchBar({ setKeyword, placeholder }) {
  return (
    <Input
      aria-label="search"
      clearable
      bordered
      color="primary"
      placeholder={placeholder}
      onChange={(e) => setKeyword(e.target.value)}
      onKeyPress={(e) => e.key === "Enter" && setKeyword(e.target.value)}
      contentRight={<img src={searchicon} alt="search" />}
      {...inputSearchStyles}
    />
  );
}

export default SearchBar;
