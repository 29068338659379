export const RolesMap = {
  SUPERADMIN: "Super Admin",
  ADMIN: "Admin",
  PROJECT_MANAGER: "Project Manager",
  PROGRAM_MANAGER: "Program Manager",
  FINANCE: "Admin Finance",
}

export const ROLE_SUPERADMIN = "SUPERADMIN";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_PROJECT_M = "PROJECT_MANAGER";
export const ROLE_PROGRAM_M = "PROGRAM_MANAGER";
export const ROLE_FINANCE = "FINANCE";