import { Grid, Card, Col, Text } from "@nextui-org/react";
import { Currency } from "utils/helper";

function StatusBar({
  totalProjectBlogger,
  totalProjectMedia,
  totalWaiting,
  totalPaid,
  totalBudget,
}) {
  return (
    <Grid.Container gap={1} css={{ padding: 0 }}>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Project Blogger
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalProjectBlogger}
                {!totalProjectBlogger && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Project Media
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalProjectMedia}
                {!totalProjectMedia && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Menunggu Pembayaran
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalWaiting}
                {!totalWaiting && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={2}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Sudah Dibayar
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {totalPaid}
                {!totalPaid && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={12} md={4}>
        <Card>
          <Card.Body>
            <Col align="center">
              <Text size={14} weight="bold" color="$black">
                Total Budget
              </Text>
              <Text
                size={24}
                weight="bold"
                color="#FF9D25"
                css={{ marginTop: "0.25rem" }}
              >
                {Currency(totalBudget)}
                {!totalBudget && "-"}
              </Text>
            </Col>
          </Card.Body>
        </Card>
      </Grid>
    </Grid.Container>
  );
}

export default StatusBar;
