import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import DataTable from "react-data-table-component";
import UserService from "services/user.service";
import { Button, Card, Text } from "@nextui-org/react";
import StatusBar from "./components/StatusBar";
import Table from "./components/Table";

function BalanceHistory() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Get summary balance card
  const [summary, setSummary] = useState("");
  const fetchSummaryBalance = async () => {
    setLoading(true);
    try {
      const response = await UserService.getSummaryBalance();
      setSummary(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSummary(err.response.data.data);
    }
  };

  useEffect(() => {
    fetchSummaryBalance();
  }, []);

  // Get balance history list
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchBalance = async (page) => {
    setLoading(true);
    try {
      const response = await UserService.getBalanceList(page, perPage);
      setData(response.data.data.data);
      setTotalRows(response.data.data.meta.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  const handlePageChange = (page) => {
    fetchBalance(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await UserService.getBalanceList(page, newPerPage);
      setData(response.data.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData(err.response.data.data.data);
    }
  };

  useEffect(() => {
    fetchBalance(1);
  }, []);

  return (
    <>
      <div className="mx-3 lg:mx-7 mb-2">
        <div className="mb-2">
          <Text h3 size={22}>
            Balance History List
          </Text>
        </div>
        <StatusBar
          totalBlogger={summary?.blogger}
          totalProject={summary?.project}
          totalTask={summary?.task}
          totalAccumulative={summary?.accumulative}
        />
      </div>

      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            <Table
              data={data}
              loading={loading}
              totalRows={totalRows}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              navigate={navigate}
              pathname={pathname}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default BalanceHistory;
