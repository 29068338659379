import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonBack } from "components";
import UserService from "services/user.service";
import { Card, Loading } from "@nextui-org/react";
import { Currency, DateFormat } from "utils/helper";

export default function BalanceHistoryDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getBalanceDetail(id);
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setData(err.response.data.data);
      }
    };
    fetchDetail();
  }, [id]);

  return (
    <>
      <ButtonBack />
      <div className="mx-3 mb-12 lg:mx-7">
        <Card>
          <Card.Body>
            {isLoading && (
              <div className="flex items-center justify-center h-96">
                <Loading size="lg" color="warning" />
              </div>
            )}
            {!data && (
              <span className="flex items-center justify-center h-96 font-bold text-red-500">
                No Data Found
              </span>
            )}
            {!isLoading && data && (
              <div className="lg:p-5 flex flex-col lg:flex-row">
                <ul className="w-full flex-col space-y-4">
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Id History</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.insentive_id}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">User</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.blogger
                            ? data?.blogger?.name
                            : data?.media?.company_name}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-semibold">Request Status</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.withdraw_request?.status
                            ? data?.withdraw_request?.status
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Credit</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.credit)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Debit</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.debit)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Balance</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {Currency(data?.balance)}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Created At</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.created_at && DateFormat(data?.created_at)}
                          {!data?.created_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Updated At</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.updated_at && DateFormat(data?.updated_at)}
                          {!data?.updated_at && "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="w-1/2 lg:w-1/6">
                        <span className="font-bold">Remarks</span>
                      </div>
                      <div className="w-1/2">
                        <span className="text-login-gray-dark">
                          {data?.remarks ? data?.remarks : "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
