/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import AuthService from "services/auth.service";
import { RolesMap } from "utils/roles";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { Navbar, Collapse, Text, Avatar, Dropdown } from "@nextui-org/react";
import { menus } from "utils/menus";
import { StyledLiMobile } from "../sidebar/styled";
import { useHasPermissions } from "utils/helper";
import { useRole } from "utils/helper";

function NavbarComp() {
  const role = useRole();
  const [user, setUser] = useState("");
  const [id, setId] = useState("");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    setId(user.admin_id);
  }, []);

  const handleProfile = () => {
    window.location.href = `/edit-user/${id}`;
  };

  const handleLogOut = () => {
    AuthService.logout().then(() => {
      window.location.href = "/";
    });
  };

  const handleAction = (data) => {
    switch (data) {
      case "settings":
        handleProfile();
        break;
      case "logout":
        handleLogOut();
        break;
      default:
        break;
    }
  };

  const pathNav = (path) => {
    window.location.href = path;
  };

  return (
    <Navbar shouldHideOnScroll isBordered variant="sticky">
      <Navbar.Toggle showIn="sm" />
      <Navbar.Content
        css={{
          w: "100%",
          jc: "flex-end",
        }}
      >
        <Dropdown placement="bottom-right">
          <Dropdown.Button
            light
            css={{
              height: 60,
              paddingLeft: "0.8rem",
              paddingRight: "0.4rem",
            }}
          >
            <div className="flex items-center space-x-4">
              <div className="leading-5 text-left">
                <h3 className="font-semibold">{user?.name}</h3>
                <p className="text-sm text-login-gray-light">
                  {RolesMap[user?.role]}
                </p>
              </div>
              <div>
                <Avatar src={user?.photo && user.photo} size="lg" />
              </div>
            </div>
          </Dropdown.Button>
          <Dropdown.Menu
            color="primary"
            aria-label="User Actions"
            onAction={handleAction}
          >
            <Dropdown.Item key="profile" css={{ height: "$18" }}>
              <Text b color="inherit" css={{ d: "flex" }}>
                Signed in as
              </Text>
              <Text b color="inherit" css={{ d: "flex" }}>
                {user?.email}
              </Text>
            </Dropdown.Item>
            <Dropdown.Item icon={<FiSettings />} key="settings" withDivider>
              Profile Settings
            </Dropdown.Item>
            <Dropdown.Item
              icon={<FiLogOut />}
              key="logout"
              color="error"
              withDivider
            >
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Content>
      <Navbar.Collapse>
        {menus.map((item, i) => {
          const parentHasPermission = useHasPermissions(item.permissions, role);

          const childrens =
            item.children &&
            item.children.filter((subItem) => {
              const hasPermission = useHasPermissions(
                subItem.permissions,
                role
              );
              if (hasPermission) {
                return subItem;
              }
            });

          return childrens?.length ? (
            <Collapse
              key={i}
              role="button"
              title={<span>{item.title}</span>}
              contentLeft={<div className="menu-icon ml-2">{item.icon}</div>}
              divider={false}
            >
              <ul>
                {childrens.map((subItem, subIndex) => {
                  return (
                    <StyledLiMobile key={subIndex}>
                      <div
                        onClick={() => pathNav(subItem.path)}
                        className="text-black ml-2"
                      >
                        {subItem.title}
                      </div>
                    </StyledLiMobile>
                  );
                })}
              </ul>
            </Collapse>
          ) : parentHasPermission && !item.children ? (
            <ul key={i}>
              <StyledLiMobile className="px-0 py-4">
                <div onClick={() => pathNav(item.path)} className="text-black">
                  <div className="flex space-x-3 items-center ml-2">
                    <div className="menu-icon">{item.icon}</div>
                    <div>{item.title}</div>
                  </div>
                </div>
              </StyledLiMobile>
            </ul>
          ) : null;
        })}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComp;
